import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Button,
  Select,
  Typography,
  Space,
  Popover,
  Divider,
} from 'antd';
import _ from 'lodash';
import './drag.css';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  ArrowDownOutlined,
  MenuOutlined,
  SaveOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import arrayMove from 'array-move';
import { useMount } from 'react-use';
import { sortableCategoriesSelector } from '../../../../atoms/Selectors';
import { businessAtom } from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function DraggableTable() {
  const { Option, OptGroup } = Select;
  const sortData = useRecoilValueLoadable(sortableCategoriesSelector);
  const [reload, setReload] = useState(0);
  const [saveable, setSaveable] = useState(false);
  const [category, setCategory] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [sorting, setSorting] = useState(false);
  const [items, setItems] = useState(null);
  const store = useRecoilValue(businessAtom);
  const [itemDetails, setItemDetails] = useState(null);
  const [selectedItem, setSelectedItems] = useState(null);
  const [loadingItemDetails, setLoadingItemDetails] = useState(false);
  const [timeFrame, setTimeFrame] = useState('Update Sales');
  const { t } = useTranslation();
  const width = window.innerWidth;

  function mapData(data) {
    const map = _.map(data, (p, index) => ({
      key: index,
      id: p.id,
      index,
      brand: p.brand,
      name: p.name,
      description: p.description,
      isAvailable: p.isAvailable,
      isVisible: p.isVisible,
      ordering: p.ordering,
      upc: p.upc,
      historicPurchaseCount: p.historicPurchaseCount,
      price: p.activePrice,
    }));

    return _.sortBy(map, [(o) => o.ordering]);
  }

  function resetRanks(data) {
    let sortedData = data;
    sortedData = data;

    let sortCounter = 1000;
    for (let i = 0; i < data.length; i++) {
      sortedData[i].ordering = sortCounter;
      sortedData[i].index = i;
      sortCounter += 10;
    }
    setItems(sortedData);
    setSaveable(true);
    return null;
  }

  useEffect(() => {
    setLoading(true);
    setTimeFrame('Update Sales');
  }, [category, store]);

  useEffect(() => {
    setLoading(true);
  }, [category]);

  useMount(() => {
    setItems(null);
    setCategory(null);
  });

  useEffect(() => {
    setLoading(false);
    if (category !== null) {
      setLoading(true);
      api
        .post(
          `businesses/assigned/${store.id}/2000/0`,
          {
            CategoryId: `${category}`,
            SearchType: 2,
          }
        )
        .then((response) => {
          setLoading(false);
          setItems(mapData(response.data.data.results));
          setCount(response.data.data.results.length);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  }, [store]);

  useEffect(() => {
    setLoading(false);
    if (category !== null) {
      setLoading(true);
      api
        .post(
          `businesses/assigned/${store.id}/2000/0`,
          {
            CategoryId: `${category}`,
            SearchType: 2,
          }
        )
        .then((response) => {
          setLoading(false);
          setItems(mapData(response.data.data.results));
          setCount(response.data.data.results.length);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  }, [category]);

  useEffect(() => {
    setLoading(false);
    if (category !== null) {
      setLoading(true);
      api
        .post(
          `businesses/assigned/${store.id}/1000/0`,
          {
            CategoryId: `${category}`,
            SearchType: 2,
          }
        )
        .then((response) => {
          setLoading(false);
          setItems(mapData(response.data.data.results));
          setCount(response.data.data.results.length);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  }, [reload]);

  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
  ));

  function onSortEnd(index) {
    const ds = items;

    if (index.oldIndex !== -1) {
      const newData = arrayMove(
        [].concat(ds),
        index.oldIndex,
        index.newIndex
      ).filter((el) => !!el);
      resetRanks(newData);
      setItems(newData);
    }
  }

  const columns = [
    {
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: t('brand'),
      dataIndex: 'brand',
      className: 'drag-visible',
    },
    {
      title: t('name'),
      dataIndex: 'name',
      className: 'drag-visible',
    },
    {
      title: t('description'),
      dataIndex: 'description',
      className: 'drag-visible',
    },
    {
      title: 'Upc',
      dataIndex: 'upc',
      className: 'drag-visible',
      render: (text) => <Typography.Text copyable>{text}</Typography.Text>,
    },
    {
      title: t('price'),
      dataIndex: 'price',
      align: 'right',
      className: 'drag-visible',
      render: (text) => (
        <span>
          $
          {text
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
      ),
    },
    {
      title: t('sales_count'),
      dataIndex: 'historicPurchaseCount',
      align: 'right',
      className: 'drag-visible',
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.historicPurchaseCount - b.historicPurchaseCount,
    },

    {
      title: 'Sort Rank',
      dataIndex: 'ordering',
      align: 'right',
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.ordering - b.ordering,
    },
    {
      title: t('details'),
      align: 'center',
      className: 'hidden text-xs',
      render: (text, row) => (
        <Space>
          <Popover
            placement="left"
            content={
              itemDetails && (
                <div>
                  <div>Profitability</div>
                  <Divider className="p-0" />
                  <div className="text-xs">
                    {t('sales_count')}t:{' '}
                    <span className="font-bold">{itemDetails.count}</span>
                  </div>
                  <div className="text-xs">
                    {t('shopper_sales')}:{' '}
                    <span className="font-bold">
                      {itemDetails.shopperSales}
                    </span>
                  </div>
                  <div className="text-xs">
                    {t('avg_price')}:{' '}
                    <span className="font-bold">
                      ${itemDetails.averagePrice.toFixed(2)}
                    </span>
                  </div>
                  <div className="text-xs">
                    {t('avg_cost')}:{' '}
                    <span className="font-bold">
                      ${itemDetails.averageCost.toFixed(2)}
                    </span>
                  </div>
                  <div className="text-xs">
                    {t('avg_profit')}:{' '}
                    <span className="font-bold">
                      ${itemDetails.averageProfit.toFixed(2)}
                    </span>
                  </div>
                  <div className="text-xs">
                    {t('max_cost')}:{' '}
                    <span className="font-bold">
                      ${itemDetails.maxCost.toFixed(2)}
                    </span>
                  </div>
                  <div className="text-xs">
                    {t('max_price')}:{' '}
                    <span className="font-bold">
                      ${itemDetails.maxPrice.toFixed(2)}
                    </span>
                  </div>
                  <div className="text-xs">
                    {t('min_cost')}:{' '}
                    <span className="font-bold">
                      ${itemDetails.minCost.toFixed(2)}
                    </span>
                  </div>
                  <div className="text-xs">
                    {t('min_price')}:{' '}
                    <span className="font-bold">
                      ${itemDetails.minPrice.toFixed(2)}
                    </span>
                  </div>
                  <div className="text-xs">
                    {t('profit_margin')}:{' '}
                    <span className="font-bold">
                      ${itemDetails.profitMargin.toFixed(2)}
                    </span>
                  </div>
                  <div className="text-xs">
                    {t('total_profit')}:{' '}
                    <span className="font-bold">
                      ${itemDetails.totalProfit.toFixed(2)}
                    </span>
                  </div>
                  <div className="text-xs">
                    {t('total_sales')}:{' '}
                    <span className="font-bold">
                      ${itemDetails.totalSales.toFixed(2)}
                    </span>
                  </div>
                </div>
              )
            }
          >
            <Button
              shape="circle"
              size="small"
              icon={<SearchOutlined />}
              loading={loadingItemDetails && selectedItem === row.upc}
              onClick={() => {
                setItemDetails(null);
                setLoadingItemDetails(true);
                setSelectedItems(row.upc);
                api
                  .post(
                    `analytics/profitability/product/${store.id}`,
                    {
                      Upc: `${row.upc}`,
                    }
                  )
                  .then((response) => {
                    setItemDetails(response.data.data);
                    setLoadingItemDetails(false);
                  })
                  .catch((error) => {
                    console.error(error);
                    setLoadingItemDetails(false);
                  });
              }}
            />
          </Popover>
        </Space>
      ),
    },
  ];

  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = items;
    if (items) {
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = dataSource.findIndex(
        (x) => x.index === restProps['data-row-key']
      );

      return <SortableItem index={index} {...restProps} />;
    }
    return null;
  };

  function handleChange(value) {
    setCategory(value);
  }

  function updateSalesChanged() {
    if (timeFrame !== 'Update Sales') {
      setUpdating(true);
      api
        .put(
          `businesses/${store.id}/updatehistoric/${category}/${timeFrame}`,
          {
            items,
            CatId: category.id,
          }
        )
        .then((response) => {
          setReload(reload + 1);
          setUpdating(false);
        })
        .catch((error) => {
          console.error(error);
          setUpdating(false);
        });
    }
  }

  return (
    <div className="p-4 border bg-gray-100 rounded-lg mb-4 mt-12">
      <div className="flex flex-column justify-between">
        <div>
          {' '}
          {sortData.state === 'hasValue' && (
            <Select
              loading={!sortData}
              listHeight={500}
              defaultValue="Select Category"
              style={{ width: 200 }}
              onChange={handleChange}
            >
              {_.map(sortData.contents.data.data.groupedResult, (b) => (
                <OptGroup
                  label={b.deparment}
                  className="text-xs flex items-center"
                >
                  {b.deparment}
                  {_.map(b.sections, (s) => (
                    <Option
                      key={s.id}
                      value={s.id}
                      className="text-xs flex items-center"
                    >
                      {s.name}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          )}
        </div>

        <div className="flex justify-right mb-4 ">
          <div>
            {}
            <Select
              className="mr-6"
              disabled={!category}
              loading={updating}
              listHeight={500}
              value={timeFrame}
              defaultValue={timeFrame}
              onChange={(value) => { setTimeFrame(value); updateSalesChanged(); }}
            >
              <OptGroup label="Days Back" className="text-xs flex items-center">
                <Option
                  key="30"
                  value="30"
                  className="text-xs flex items-center"
                >
                  30 {t('days')}
                </Option>
                <Option
                  key="60"
                  value="60"
                  className="text-xs flex items-center"
                >
                  60 {t('days')}
                </Option>
                <Option
                  key="90"
                  value="90"
                  className="text-xs flex items-center"
                >
                  90 {t('days')}
                </Option>
              </OptGroup>
            </Select>
          </div>
          <div className="mr-8">
            {' '}
            <Button
              type="primary"
              disabled={!category}
              icon={<ArrowDownOutlined />}
              loading={sorting}
              onClick={() => {
                setSorting(true);
                api
                  .put(
                    `businesses/${store.id}/sortbytrend/${category}/30`,
                    {
                      items,
                      CatId: category.id,
                    }
                  )
                  .then(() => {
                    setReload(reload + 1);
                    setSorting(false);
                  })
                  .catch((error) => {
                    console.error(error);
                    setSorting(false);
                  });
              }}
            >
              {t('sort_by_sales_trends')}
            </Button>
          </div>
          <div>
            <Button
              type="primary"
              disabled={!saveable}
              icon={<SaveOutlined />}
              loading={saving}
              onClick={() => {
                setSaving(true);
                api
                  .put(
                    `businesses/${store.id}/updatesorting`,
                    {
                      items,
                      CatId: category.id,
                    }
                  )
                  .then(() => {
                    setSaving(false);
                    setReload(reload + 1);
                    setSaveable(false);
                  })
                  .catch((error) => {
                    console.error(error);
                    setSaving(false);
                  });
              }}
            >
              {t('save_sorting')}
            </Button>
          </div>
        </div>
      </div>
      <div className="mx-2">
        {' '}
        {category && <div className="mt-2 ml-2">Results: {count}</div>}
      </div>

      <div>
        <Table
          loading={loading}
          pagination={false}
          scroll={{ x: 500 }}
          dataSource={items && items}
          columns={columns}
          rowKey="index"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </div>
    </div>
  );
}
export default DraggableTable;
