import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Input,
  Card,
  List,
  Avatar,
  Tag,
  Tooltip,
  Spin,
  Typography,
  notification,
  Pagination,
  Button,
  Select,
  Image,
  Table
} from 'antd';
import { Comment } from '@ant-design/compatible';
import React, { useState, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  tokenAtom,
  setSelectedMasterItemAtom,
  refreshSearchFiltersAtom,
  selectedPickingItemAtom,
  editBusinessItemReloadAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function Search(props, { className, small }) {
  const { Option } = Select;
  const { t } = useTranslation();
  const { Text } = Typography;
  const defaultFilters = [
    { name: t('clear_filters'), tag: 'RESET' },
    { name: t('no_internal_id'), tag: 'NoInternalId' },
    { name: t('has_internal_id'), tag: 'HasInternalId' },
    { name: t('no_picutres'), tag: 'NoPictures' },
    { name: t('has_picture'), tag: 'HasPicture' },
    { name: t('has_video'), tag: 'HasVideo' },
    { name: t('packaging_sensitive'), tag: 'PackagingSensitive' },
    { name: t('temperature_sensitive'), tag: 'TemperatureSensitive' },
    { name: t('alcoholic_beverage'), tag: 'AlcoholicBeverage' },
    { name: t('id_required'), tag: 'IdRequired' },
    { name: 'EBT', tag: 'Ebt' },
    { name: t('not_ebt'), tag: 'NotEbt' },
    { name: t('perishable'), tag: 'Perishable' }
  ];

  const token = useRecoilValue(tokenAtom);
  const [refreshSearchFilters, setRefreshSearchFilters] = useRecoilState(refreshSearchFiltersAtom);
  const setSelectedMasterItem = useSetRecoilState(setSelectedMasterItemAtom);
  const [selectedPickingItem, setSelectedPickingItem] = useRecoilState(selectedPickingItemAtom);
  const [searchSize, setSearchSize] = useState('large');
  const [itemPicking, setItemPicking] = useState(false);
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState([]);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalItemsInSearch, setTotalItemsInSearch] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [suggestedDepartments, setSuggestedDepartments] = useState([]);
  const [selectedSuggestedDepartment, setSelectedSuggestedDepartment] = useState(null);
  const [loadingSuggestedDepartments, setLoadingSuggestedDepartments] = useState(false);
  const [sorting, setSorting] = useState(0);
  const [gridSizes, setGridSizes] = useState({
    gutter: 16,
    xs: 4,
    sm: 4,
    md: 4,
    lg: 4,
    xl: 4,
    xxl: 4,
  });
  const smallProp = small === undefined ? false : small;
  const [view, setView] = useState('list');
  const [reloadSearch, setReloadSearch] = useRecoilState(editBusinessItemReloadAtom);

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const getSuggestedDepartments = function () {
    setLoadingSuggestedDepartments(true);
    api
      .get('businesses/catalog/suggesteddepartments')
      .then((response) => {
        setLoadingSuggestedDepartments(false);
        if (response.data.success) {
          const temp = _.filter(response.data.data, (d) => d !== null);
          setSuggestedDepartments(temp);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingSuggestedDepartments(false);
        showMessage(error);
      });
  };

  const onFilterClick = function (filter) {
    const tag = filter.tag ? filter.tag : filter.id;
    let newFilterList = [];
    if (tag === defaultFilters[0].tag) {
      newFilterList = [];
    } else {
      const containsFilter = selectedFilters.includes(tag);
      if (containsFilter) {
        newFilterList = _.filter(selectedFilters, (z) => z !== tag);
      } else {
        newFilterList = selectedFilters.concat(tag);
      }
    }
    const tempFilters = JSON.parse(JSON.stringify(filters));
    for (let i = 0; i < tempFilters.length; i++) {
      tempFilters[i].active = false;
      for (let j = 0; j < newFilterList.length; j++) {
        if (tempFilters[i].tag === newFilterList[j] ||
          tempFilters[i].id === newFilterList[j]) {
          tempFilters[i].active = true;
        }
      }
    }
    setFilters(tempFilters);
    setSelectedFilters(newFilterList);
  };

  const searchProducts = function (query, page) {
    setIsSearching(true);
    let tempFilters = [...selectedFilters];
    if (selectedSuggestedDepartment) {
      tempFilters = [...tempFilters, selectedSuggestedDepartment];
    }
    const payload = {
      Query: query,
      Page: page,
      Size: size,
      Filters: tempFilters,
      Sort: sorting
    };
    api
      .post(
        'businesses/catalog/search',
        payload
      )
      .then((response) => {
        if (response.data.success) {
          setIsSearching(false);
          setTotalItemsInSearch(response.data.data.count);
          setProducts(response.data.data.items);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setIsSearching(false);
        showMessage(error);
      });
  };

  const onSearch = async (value) => {
    setLastSearch(value);
    setCurrentPage(0);
    searchProducts(value, 0);
  };

  const onTableChange = (page, pageSize) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      searchProducts(lastSearch, page - 1);
    } else {
      setSize(pageSize);
    }
  };

  const fetchFilters = function () {
    setIsLoadingFilters(true);
    api
      .get('businesses/catalog/filters')
      .then((response) => {
        if (response.data.success) {
          setIsLoadingFilters(false);
          const tempFilters = defaultFilters.concat(response.data.data.results);
          for (let i = 0; i < selectedFilters; i++) {
            for (let j = 0; j < tempFilters; j++) {
              if (tempFilters[j].id === selectedFilters[i] ||
                tempFilters[j].tag === selectedFilters[i]) {
                tempFilters[j].active = true;
              }
            }
          }
          setFilters(tempFilters);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setIsLoadingFilters(false);
        showMessage(error);
      });
  };

  const onItemClick = function (item) {
    if (itemPicking) {
      setSelectedPickingItem(item);
    } else {
      setSelectedMasterItem(item);
    }
  };

  useEffect(() => {
    setCurrentPage(0);
    searchProducts(lastSearch, 0);
  }, [selectedFilters]);

  useEffect(() => {
    setCurrentPage(0);
    searchProducts(lastSearch, 0);
  }, [size]);

  useEffect(() => {
    setCurrentPage(0);
    searchProducts(lastSearch, 0);
  }, [reloadSearch]);

  useEffect(() => {
    if (refreshSearchFilters) {
      fetchFilters();
      setRefreshSearchFilters(false);
    }
  }, [refreshSearchFilters]);

  useEffect(() => {
    if (props && props.size) {
      setSearchSize(props.size);
      if (props.size === 'small') {
        setGridSizes({
          gutter: 4,
          xs: 2,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2,
        });
      }
    }
    if (props && props.itemPicking) {
      setItemPicking(props.itemPicking);
    }
    fetchFilters();
    getSuggestedDepartments();
    setCurrentPage(0);
    searchProducts('', 0);
  }, []);

  return (
    <div>
      <div>
        <div>
          {isLoadingFilters && (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          )}
          <div style={{ marginBottom: 10 }}>
            {!isLoadingFilters && filters && _.map(filters, (filter, index) => (
              <Tag
                key={`${index}${filter.tag}`}
                onClick={() => onFilterClick(filter)}
                color={filter.active ? '#1890ff' : ''}
                style={{ color: filter.active ? '#333333' : '', display: 'inline-block', marginBottom: '10px' }}
              >
                {filter.name}
              </Tag>
            ))}
          </div>
        </div>
        <div className="flex">
          <Select
            className="mt-1 mr-2"
            style={{ width: 150 }}
            loading={loadingSuggestedDepartments}
            disabled={loadingSuggestedDepartments || suggestedDepartments?.length === 0}
            onChange={(value) => setSelectedSuggestedDepartment(value)}
            value={selectedSuggestedDepartment}
          >
            <Option value={null}>{t('any_department')}</Option>
            {_.map(suggestedDepartments, (s) => (
              <Option value={`SugDepart-${s}`}>
                {s !== '' && s !== null ? s : t('no_department')}
              </Option>
            ))}
          </Select>
          <Input.Search
            key="itemSearch"
            placeholder={t('item_search_placeholder')}
            allowClear
            enterButton={t('search')}
            size={searchSize}
            onSearch={onSearch}
          />
          <Select
            className="mt-1 ml-2"
            style={{ width: 150 }}
            onChange={(value) => setSorting(value)}
            value={sorting}
          >
            <Option value={0}>{t('default_sort')}</Option>
            <Option value={1}>A-Z</Option>
            <Option value={2}>Z-A</Option>
            <Option value={7}>{t('rank')} &darr;</Option>
            <Option value={10}>{t('rank')} &uarr;</Option>
            <Option value={6}>{t('edited_at')}</Option>
            <Option value={11}>{t('top_selling')} &darr;</Option>
            <Option value={12}>{t('top_selling')} &uarr;</Option>
          </Select>
        </div>
        <br />
        <br />
        <div className="mx-2">{t('results')}: {totalItemsInSearch.toLocaleString()}</div>
        {itemPicking && selectedPickingItem && (
          <Button
            type="primary"
            size={searchSize}
            onClick={() => setSelectedPickingItem(null)}
          >
            {t('clear_selected_item')}
          </Button>
        )}
        <br />
        <br />
        <div className={className} id="first-step">
          <Button
            size="small"
            type={view === 'list' ? 'primary' : 'default'}
            onClick={() => setView('list')}
            className="w-36 rounded-l-lg border-none text-xs"
          >
            {t('table')}
          </Button>
          <Button
            size="small"
            style={{ marginLeft: '-1px' }}
            type={view === 'grid' ? 'primary' : 'default'}
            onClick={() => setView('grid')}
            className="w-36 rounded-r-lg border-none text-xs"
          >
            {t('grid')}
          </Button>
        </div>
        <br />
        {view === 'list' ? (
          <Table
            size="small"
            loading={isSearching}
            bordered
            pagination={{
              pageSize: size,
              showSizeChanger: true,
              defaultCurrent: 0,
              current: currentPage + 1,
              total: totalItemsInSearch,
              onChange: onTableChange
            }}
            columns={[
              {
                title: t('image'),
                key: 'image',
                align: 'center',
                width: 80,
                className: 'text-xs',
                render: (text) =>
                  <Image
                    width={50}
                    src={text.itemImage}
                    alt={`${text.brand} ${text.name} ${text.description}`}
                  />,
              },
              {
                title: t('product'),
                key: 'product',
                align: 'left',
                width: 150,
                className: 'text-xs',
                render: (text) =>
                  <div className="flex flex-col">
                    <span>{text.gtin}</span>
                    <span>{text.brand}</span>
                    <span>{text.name}</span>
                    <span>{text.description}</span>
                    <span>{text.internalId}</span>
                  </div>,
              },
              {
                title: t('search_tags'),
                dataIndex: 'searchTags',
                key: 'searchTags',
                align: 'left',
                className: 'text-xs',
                render: (text) =>
                  <div className={text === '' || null ? 'hidden' : ''}>
                    <Text copyable>{text}</Text>
                  </div>,
              },
              {
                title: t('actions'),
                key: 'update',
                align: 'center',
                width: 30,
                className: 'text-xs',
                render: (text, item) => (
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => { onItemClick(item); }}
                  >
                    {t('edit')}
                  </Button>
                ),
              },
            ]}
            dataSource={products}
          />
        ) : (
          <div>
            <List
              pagination={false}
              loading={isSearching}
              dataSource={products}
              grid={gridSizes}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                >
                  {searchSize === 'small' && (
                    <Card
                      key={item.id}
                      className={selectedPickingItem?.id === item.id ? 'selected-card' : ''}
                      onClick={() => { onItemClick(item); }}
                    >
                      <small>
                        <strong>{item.gtin}</strong>
                      </small>
                      <img
                        style={{ maxHeight: 75 }}
                        src={item.itemImage}
                        alt={item.completeName}
                      />
                      <div className="ellipsis-container">
                        {item.brand && item.brand !== '' && (
                          <small>{item.brand}</small>
                        )}
                        {(!item.brand || item.brand === '') && (
                          <small style={{ opacity: 0 }}>.</small>
                        )}
                        <br />
                        {item.name && item.name !== '' && (
                          <small>{item.name}</small>
                        )}
                        {(!item.name || item.name === '') && (
                          <small style={{ opacity: 0 }}>.</small>
                        )}
                        <br />
                        {item.description && item.description !== '' && (
                          <small>{item.description}</small>
                        )}
                        {(!item.description || item.description === '') && (
                          <small style={{ opacity: 0 }}>.</small>
                        )}
                      </div>
                    </Card>
                  )}
                  {searchSize !== 'small' && (
                    <Card
                      key={item.id}
                      onClick={() => { onItemClick(item); }}
                    >
                      <strong>{item.gtin}</strong>
                      <img
                        style={{ maxHeight: 125 }}
                        src={item.itemImage}
                        alt={item.completeName}
                      />
                      <div className="ellipsis-container">
                        {item.brand && item.brand !== '' && (
                          <p>{item.brand}</p>
                        )}
                        {(!item.brand || item.brand === '') && (
                          <p style={{ opacity: 0 }}>.</p>
                        )}
                        {item.name && item.name !== '' && (
                          <p>{item.name}</p>
                        )}
                        {(!item.name || item.name === '') && (
                          <p style={{ opacity: 0 }}>.</p>
                        )}
                        {item.description && item.description !== '' && (
                          <p>{item.description}</p>
                        )}
                        {(!item.description || item.description === '') && (
                          <p style={{ opacity: 0 }}>.</p>
                        )}
                      </div>
                    </Card>
                  )}
                </List.Item>
              )}
            />
            <Pagination
              pageSize={size}
              showSizeChanger
              defaultCurrent={0}
              current={currentPage + 1}
              total={totalItemsInSearch}
              onChange={onTableChange}
            />
            <br />
            <br />
          </div>
        )}
      </div>
    </div>
  );
}

export default Search;
