import {
  Button,
  Table,
  Modal,
  Input,
  InputNumber,
  Tooltip,
  notification,
  Avatar,
  Image,
  Switch,
  Select
} from 'antd';
import { Comment } from '@ant-design/compatible';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import { selectedInventoryItemAtom } from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';

function ItemHistoryModal() {
  const { Option } = Select;
  const { t } = useTranslation();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [data, setData]
    = useState(null);
  const [qoh, setQoh] = useState(0);
  const [selectedInventoryItem, setSelectedInventoryItem]
    = useRecoilState(selectedInventoryItemAtom);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalHistory, setTotalHistory] = useState(0);
  const [widthScreen, setWidthScreen] = useState(750);
  const [showAdjustment, setShowAdjustment] = useState(false);
  const [selectedType, setSelectedType] = useState(3);
  const [selectedReason, setSelectedReason] = useState(0);
  const [comments, setComments] = useState('');
  const [amount, setAmount] = useState(0);
  const [boxes, setBoxes] = useState(0);
  const [boxUnits, setBoxUnits] = useState(0);
  const [totalUnits, setTotalUnits] = useState(0);
  const [helperAmount, setHelperAmount] = useState(0);

  function convertTypeToString(type) {
    if (type === 1) {
      return t('adjustment_up');
    }
    if (type === 3) {
      return t('receiving');
    }
    if (type === 2) {
      return t('adjustment_down');
    }
    if (type === 0) {
      return t('sale');
    }
    return '';
  }

  function convertReasonToString(type) {
    if (type === 0) {
      return t('none');
    }
    if (type === 1) {
      return t('damage');
    }
    if (type === 2) {
      return t('lost');
    }
    if (type === 3) {
      return t('found');
    }
    if (type === 4) {
      return t('replenishment');
    }
    return '';
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getItemHistory(page) {
    setLoading(true);
    api
      .get(`inventory/history/${data.id}/${page}/${size}`)
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          setTotalHistory(response.data.data.total);
          setHistory(response.data.data.result);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  function updateIsVisible(val) {
    setLoading(true);
    api
      .post('inventory/update/item', {
        BusinessItemId: data.id,
        IsVisible: val,
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          setData({ ...data,
            isVisible: !val });
        } else {
          showMessage(t('updated_successfully'));
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  function updateIsAvailable(val) {
    setLoading(true);
    api
      .post('inventory/update/item', {
        BusinessItemId: data.id,
        IsAvailable: val
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          setData({ ...data,
            isAvailable: !val });
        } else {
          showMessage(t('updated_successfully'));
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  function updateAffectsInventory(val) {
    setLoading(true);
    api
      .post('inventory/update/item', {
        BusinessItemId: data.id,
        AffectsInventory: val,
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          setData({ ...data,
            affectsInventory: !val });
        } else {
          showMessage(t('updated_successfully'));
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  function onTableChange(page, pageSize) {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      getItemHistory(page - 1);
    } else {
      setSize(pageSize);
    }
  }

  function resetAdjustmentModal() {
    setComments('');
    setSelectedType(3);
    setAmount(0);
    setBoxes(0);
    setBoxUnits(0);
    setHelperAmount(0);
    setSelectedReason(0);
  }

  function applyHelperAmount() {
    if (selectedInventoryItem.quantityOnHand > helperAmount) {
      setSelectedType(2);
      setAmount(selectedInventoryItem.quantityOnHand - helperAmount);
    } else {
      setSelectedType(1);
      setAmount(helperAmount - selectedInventoryItem.quantityOnHand);
    }
  }

  function applyBoxesHelper() {
    setAmount(totalUnits);
  }

  function getQoh() {
    api
      .get(`inventory/qoh/${data.id}`)
      .then((response) => {
        if (!response.data.success) {
          showMessage(response.data.error);
          setLoading(false);
        } else {
          setQoh(response.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  function submit() {
    setLoading(true);
    api
      .post('inventory/register', {
        BusinessItemId: data.id,
        Amount: amount,
        Comments: comments,
        Type: selectedType
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          showMessage(t('updated_successfully'));
          getQoh();
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  useEffect(() => {
    const width = windowSize.current[0];
    setWidthScreen(4 * (width / 5));
  }, [windowSize]);

  useEffect(() => {
    if (data) {
      setCurrentPage(0);
      getItemHistory(0);
      setQoh(data.quantityOnHand);
      getQoh();
    } else {
      setHistory([]);
    }
  }, [data]);

  useEffect(() => {
    setData(selectedInventoryItem);
  }, [selectedInventoryItem]);

  useEffect(() => {
    setTotalUnits(boxes * boxUnits);
  }, [boxUnits, boxes]);

  return (
    <div>
      <Modal
        width={widthScreen}
        title={t('item_history')}
        open={data}
        onCancel={() => {
          setSelectedInventoryItem(null);
        }}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            loading={loading}
            onClick={() => {
              setSelectedInventoryItem(null);
            }}
          >
            {t('close')}
          </Button>,
          <Button
            key="adjust"
            type="primary"
            loading={loading}
            onClick={() => {
              resetAdjustmentModal();
              setShowAdjustment(true);
            }}
          >
            {t('adjust')}
          </Button>
        ]}
      >
        <div className="flex">
          <div className="w-1/3">
            <Image
              className="mx-auto"
              width={150}
              src={data?.itemImage}
              alt={data?.completeName}
            />
            <div className="text-center">
              <p>{data?.upc}</p>
              <p className="text-lg">{data?.brand}</p>
              <p>{data?.name}</p>
              <p>{data?.description}</p>
            </div>
            <div>
              <span className="font-bold">{t('is_visible')}: </span>
              <Switch
                disabled={loading}
                style={{ float: 'right' }}
                checked={data?.isVisible}
                onChange={(checked) => {
                  updateIsVisible(!data?.isVisible);
                  setData({ ...data,
                    isVisible: !data.isVisible });
                }}
              />
            </div>
            <div className="mt-2">
              <span className="font-bold">{t('is_available')}: </span>
              <Switch
                disabled={loading}
                style={{ float: 'right' }}
                checked={data?.isAvailable}
                onChange={(checked) => {
                  updateIsAvailable(!data?.isAvailable);
                  setData({ ...data,
                    isVisible: !data.isAvailable });
                }}
              />
            </div>
            <div className="mt-2">
              <span className="font-bold">{t('affects_inventory')}: </span>
              <Switch
                disabled={loading}
                style={{ float: 'right' }}
                checked={data?.affectsInventory}
                onChange={(checked) => {
                  updateAffectsInventory(!data?.affectsInventory);
                  setData({ ...data,
                    isVisible: !data.affectsInventory });
                }}
              />
            </div>
            <div>
              <span className="font-bold">{t('quantity_on_hand')}: </span>
              <span className="float-right">{qoh}</span>
            </div>
            <div>
              <span className="font-bold">{t('department')}: </span>
              <span className="float-right">{data?.superSectionName}</span>
            </div>
            <div>
              <span className="font-bold">{t('section')}: </span>
              <span className="float-right">{data?.sectionName}</span>
            </div>
            {data?.department && (
              <div>
                <span className="font-bold">{t('internal_department')}: </span>
                <span className="float-right">{data?.department}</span>
              </div>
            )}
          </div>
          <div className="ml-2 w-full">
            <Table
              size="small"
              className="mt-2"
              loading={loading}
              bordered
              pagination={{
                pageSize: size,
                showSizeChanger: true,
                defaultCurrent: 0,
                current: currentPage + 1,
                total: totalHistory,
                onChange: onTableChange
              }}
              columns={[
                {
                  title: t('created_on'),
                  key: 'name',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.createdAt}</span>,
                },
                {
                  title: t('type'),
                  key: 'type',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{convertTypeToString(text.type)}</span>,
                },
                {
                  title: t('reason'),
                  key: 'reason',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{convertReasonToString(text.reason)}</span>,
                },
                {
                  title: t('amount'),
                  key: 'amount',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.amount}</span>,
                },
                {
                  title: t('comments'),
                  key: 'comments',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.comments}</span>,
                },
                {
                  title: t('message'),
                  key: 'message',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.message}</span>,
                },
                {
                  title: t('registered_by'),
                  key: 'registered_by',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.registeredBy.completeName}</span>,
                },
              ]}
              dataSource={history}
            />
          </div>
        </div>
      </Modal>
      <Modal
        width={widthScreen / 1.55}
        title={t('inventory_adjustment')}
        open={showAdjustment}
        onCancel={() => {
          setShowAdjustment(false);
        }}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            loading={loading}
            onClick={() => {
              setShowAdjustment(false);
            }}
          >
            {t('close')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              submit();
            }}
          >
            {t('submit')}
          </Button>
        ]}
      >
        <div className="flex divide-x">
          <div className="divide-y pr-2">
            <div>
              <p className="text-bold text-lg">{t('amount_calculator_helper')}</p>
              <div className="flex mb-2">
                <InputNumber
                  disabled={loading}
                  min={0}
                  step="1"
                  onChange={(obj) => {
                    setBoxes(obj);
                  }}
                  value={boxes}
                />
                <span className="text-bold text-lg mx-2"> x </span>
                <InputNumber
                  disabled={loading}
                  min={0}
                  step="1"
                  onChange={(obj) => {
                    setBoxUnits(obj);
                  }}
                  value={boxUnits}
                />
                <span className="text-bold text-lg mx-2"> = {totalUnits}</span>
                <Button
                  type="primary"
                  onClick={() => applyBoxesHelper()}
                >
                  {t('apply')}
                </Button>
              </div>
            </div>
            <div>
              <p className="text-bold text-lg">{t('set_amount_helper')}</p>
              <div className="flex">
                <InputNumber
                  disabled={loading}
                  min={0}
                  step="1"
                  onChange={(obj) => {
                    setHelperAmount(obj);
                  }}
                  value={helperAmount}
                />
                <Button
                  type="primary"
                  onClick={() => applyHelperAmount()}
                >
                  {t('apply')}
                </Button>
              </div>
            </div>
          </div>
          <div className="pl-2">
            <div className="mb-2">
              <strong>{t('type')}</strong>
              <Select
                className="w-full"
                onChange={(value) => setSelectedType(value)}
                value={selectedType}
              >
                <Option value={0}>{t('sale')}</Option>
                <Option value={1}>{t('adjustment_up')}</Option>
                <Option value={2}>{t('adjustment_down')}</Option>
                <Option value={3}>{t('receiving')}</Option>
              </Select>
            </div>
            <div className="mb-2">
              <strong>{t('reason')}</strong>
              <Select
                className="w-full"
                onChange={(value) => setSelectedReason(value)}
                value={selectedReason}
              >
                <Option value={0}>{t('none')}</Option>
                <Option value={1}>{t('damage')}</Option>
                <Option value={2}>{t('lost')}</Option>
                <Option value={3}>{t('found')}</Option>
                <Option value={4}>{t('replenishment')}</Option>
              </Select>
            </div>
            <div className="mb-2">
              <strong>{t('enter_amount')}</strong>
              <InputNumber
                className="w-full"
                disabled={loading}
                min={0}
                step="1"
                onChange={(obj) => {
                  setAmount(obj);
                }}
                value={amount}
              />
            </div>
            <div className="mb-2">
              <strong>{t('comments')}</strong>
              <Input
                disabled={loading}
                className="w-full"
                value={comments}
                onChange={(obj) => {
                  setComments(obj.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ItemHistoryModal;
