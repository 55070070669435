import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Typography,
  Switch,
} from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { createDefaultPaymentOptionsAtom, businessAtom, reloadPaymentOptionsAtom } from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function CreateDefaultPaymentOptions() {
  const { t } = useTranslation();
  const [createDefaults, setCreateDefaults] = useRecoilState(createDefaultPaymentOptionsAtom);
  const business = useRecoilValue(businessAtom);
  const [reload, setReload] = useRecoilState(reloadPaymentOptionsAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  function create() {
    setLoading(true);
    api
      .post('admin/create/paymentoptions/default', {
        ...data,
        businessId: business?.id
      })
      .then((response) => {
        setLoading(false);
        if (response && response.data.error) {
          console.error('Error Message: ', response.data.error);
        } else {
          setCreateDefaults(false);
        }
        setReload(!reload);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  useEffect(() => {
    if (createDefaults) {
      setData({});
    }
  }, [createDefaults]);

  return (
    <Drawer
      title={t('create_defaults')}
      placement="right"
      closable={false}
      onClose={() => setCreateDefaults(false)}
      open={createDefaults}
      width={300}
    >
      <>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.cash}
            onChange={(checked) => {
              setData({
                ...data,
                cash: checked
              });
            }}
          />
          <Typography.Text> {t('cash')} </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.payPal}
            onChange={(checked) => {
              setData({
                ...data,
                payPal: checked
              });
            }}
          />
          <Typography.Text> PayPal </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.payPalBillingAgreement}
            onChange={(checked) => {
              setData({
                ...data,
                payPalBillingAgreement: checked
              });
            }}
          />
          <Typography.Text> {t('paypal_billing')} </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.ath}
            onChange={(checked) => {
              setData({
                ...data,
                ath: checked
              });
            }}
          />
          <Typography.Text> {t('ath')} </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.ebt}
            onChange={(checked) => {
              setData({
                ...data,
                ebt: checked
              });
            }}
          />
          <Typography.Text> EBT </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.tripleS}
            onChange={(checked) => {
              setData({
                ...data,
                tripleS: checked
              });
            }}
          />
          <Typography.Text> Triple S </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.humana}
            onChange={(checked) => {
              setData({
                ...data,
                humana: checked
              });
            }}
          />
          <Typography.Text> Humana </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.mcs}
            onChange={(checked) => {
              setData({
                ...data,
                mcs: checked
              });
            }}
          />
          <Typography.Text> MCS </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.mmm}
            onChange={(checked) => {
              setData({
                ...data,
                mmm: checked
              });
            }}
          />
          <Typography.Text> MMM </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.terminalCash}
            onChange={(checked) => {
              setData({
                ...data,
                terminalCash: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_cash')} </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.terminalCreditDebit}
            onChange={(checked) => {
              setData({
                ...data,
                terminalCreditDebit: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_credit_debit')} </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.terminalEbt}
            onChange={(checked) => {
              setData({
                ...data,
                terminalEbt: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_ebt')} </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.terminalEbtCash}
            onChange={(checked) => {
              setData({
                ...data,
                terminalEbtCash: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_ebtcash')} </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.terminalFondo}
            onChange={(checked) => {
              setData({
                ...data,
                terminalFondo: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_fondo')} </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.terminalUnica}
            onChange={(checked) => {
              setData({
                ...data,
                terminalUnica: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_unica')} </Typography.Text>
        </div>
        <button
          className="w-full md:w-1/2 flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out bg-blue-500 hover:bg-blue-600 text-white font-semibold px-6 py-3 rounded-full"
          type="submit"
          size="small"
          onClick={(e) => {
            create();
          }}
          disabled={loading}
          loading={loading}
        >
          {' '}
          {loading && (
            <div>
              <svg
                className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            </div>
          )}
          {t('create')}
        </button>
      </>
    </Drawer>
  );
}

export default CreateDefaultPaymentOptions;
