import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Select } from 'antd';
import api from '../../../../api/api';

function AdoptionChart() {
  const { Option } = Select;
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [xData, setXData] = useState([1, 2, 3]);
  const [yData, setYData] = useState([2, 3, 4]);
  const [timeSpan, setTimeSpan] = useState(7);
  const [loading, setLoading] = useState(false);
  // const datum = [1, 2, 3, 4, 5, 6];
  const { t } = useTranslation();

  function getAdoption() {
    setLoading(true);
    api
      .get(`analytics/ordersequences/${timeSpan}`)
      .then((response) => {
        setLoading(false);
        setData(response.data.data.results);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useMount(() => {
    getAdoption();
  });

  useEffect(() => {
    getAdoption();
  }, [timeSpan]);

  useEffect(() => {
    if (data) {
      setLoaded(false);
      const tempX = [];
      const tempY = [];
      data.forEach((element) => {
        tempX.push(element.sequence);
        tempY.push(element.count);
      });
      setXData(tempX);
      setYData(tempY);
      setLoaded(true);
    }
  }, [data]);

  const legend = {
    display: true,
    position: 'bottom',
    labels: {
      fontColor: '#424A61',
      fontSize: 14
    }
  };
  const options = {
    title: {
      display: true,
      text: t('customers_order_number')
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        color: 'white',
        backgroundColor: '#a78bfa',
        borderRadius: '5',
        labels: {
          value: {}
        }
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
          }
        }
      ]
    }
  };
  return (
    <div key="adoption" className="h-68 mb-24">
      <div className="font-semibold text-lg">{t('purchase_patterns')} </div>
      <div className="flex justify-end">
        <Select
          loading={loading}
          className="border rounded-lg border-blue mb-12 px-0"
          defaultValue={7}
          style={{ width: 140 }}
          onChange={(obj) => setTimeSpan(obj)}
        >
          <Option value={7}>{t('last_week')}</Option>
          <Option value={28}>{t('last_month')}</Option>
          <Option value={365}>{t('last_year')}</Option>
          <Option value={1365}>{t('lifetime')}</Option>
        </Select>
      </div>
      <div>
        {data && <Bar
          data={
            {
              labels: xData,
              datasets: [
                {
                  label: t('order_sequence'),
                  data: yData,
                  fill: true,
                  backgroundColor: 'rgba(61,86,161,.7)',
                  borderColor: 'rgba(75,192,192,1)'
                },
              ]
            }
          }
          plugins={ChartDataLabels}
          legend={legend}
          options={options}
        />}
      </div>
    </div>
  );
}

export default AdoptionChart;
