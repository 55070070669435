import React, { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import {
  Table,
  Avatar,
  Tooltip,
  notification,
  Row,
  Col
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { customerInsightsTabAtom, timezoneAtom } from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';

function NewCustomers() {
  const { t } = useTranslation();
  const timezone = useRecoilValue(timezoneAtom);
  const customerInsightsTab = useRecoilValue(customerInsightsTabAtom);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [summary, setSummary] = useState([]);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function mapData(data) {
    const mapped = _.map(data, (p, index) => ({
      key: index,
      count: p.count,
      createdAt: moment(`${p.createdAt}+0000`)
        .tz(timezone)
        .format('MMM Do, YYYY'),
    }));
    return mapped;
  }

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: 'New Customers by Day'
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        color: 'white',
        backgroundColor: '#a78bfa',
        borderRadius: '5',
        labels: {
          value: {}
        }
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
          }
        }
      ]
    }
  };

  function getData() {
    setLoading(true);
    api.get(`analytics/insights/newcustomers/${page}/${size}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setTotal(response.data.data.total);
          setUsers(response.data.data.users);
        } else {
          showMessage(response.data.message);
        }
      }).catch((err) => {
        setLoading(false);
        showMessage(err.message);
      });
  }

  function getSummary() {
    setLoading(true);
    api.get('analytics/insights/newcustomers')
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setSummary(mapData(response.data.data.users));
        } else {
          showMessage(response.data.message);
        }
      }).catch((err) => {
        setLoading(false);
        showMessage(err.message);
      });
  }

  async function reload() {
    await setPage(0);
    await setSize(20);
    getData();
  }

  useMount(() => {
    getSummary();
  });

  useEffect(async () => {
    if (customerInsightsTab === 3) {
      reload();
    }
  }, [customerInsightsTab]);

  useEffect(async () => {
    getData();
  }, [page, size]);

  return (
    <div>
      <div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {users && <Bar
              height={250}
              data={
                {
                  labels: _.map(summary, (d) => d.createdAt),
                  datasets: [
                    {
                      label: t('count'),
                      data: _.map(summary, (d) => d.count),
                      fill: true,
                      backgroundColor: 'rgba(61,86,161,.7)',
                      borderColor: 'rgba(75,192,192,1)'
                    },
                  ]
                }
              }
              plugins={ChartDataLabels}
              options={options}
            />}
          </Col>
        </Row>
      </div>
      <br />
      <br />
      <Table
        loading={loading}
        bordered
        scroll={{ x: 1000 }}
        columns={[
          {
            title: t('customer'),
            dataIndex: 'fullName',
            key: 'fullName',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('email'),
            dataIndex: 'email',
            key: 'email',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('phone'),
            dataIndex: 'phone',
            key: 'phone',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('order_count'),
            dataIndex: 'orders',
            key: 'orders',
            align: 'center',
            className: 'text-xs',
            render: (text) => <span>{text.toLocaleString()}</span>,
          },
          {
            title: t('total_spend'),
            dataIndex: 'totalSpend',
            key: 'totalSpend',
            align: 'center',
            className: 'text-xs',
            render: (text) => <span>${text.toLocaleString()}</span>,
          },
          {
            title: t('created_at'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'left',
            className: 'text-xs',
            render: (text) =>
              <span>
                {moment(`${text}+0000`)
                  .tz(timezone)
                  .format('MMM Do, YYYY')}
              </span>,
          },
        ]}
        dataSource={users}
        pagination={{
          pageSize: size,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: page + 1,
          total,
          onChange: async (p, pageSize) => {
            if (p - 1 !== page) {
              await setPage(p - 1);
              getData();
            } else {
              setSize(pageSize);
            }
          }
        }}
      />
    </div>
  );
}

export default NewCustomers;
