import React from 'react';
import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import {
  Drawer,
  Table
} from 'antd';
import {
  setSelectedBusinessItemAtom,
  showItemUpcsAtom,
} from '../../../../atoms/Atoms';

function ItemGtinsDrawer() {
  const selectedBusinessItem = useRecoilValue(setSelectedBusinessItemAtom);
  const [showItemUpcs, setShowItemUpcs] = useRecoilState(showItemUpcsAtom);

  return (
    <Drawer
      title="Upcs"
      placement="right"
      closable
      onClose={() => setShowItemUpcs(null)}
      open={showItemUpcs}
      width={400}
    >
      <>
        {selectedBusinessItem && (
          <Table
            size="small"
            bordered
            pagination={false}
            dataSource={selectedBusinessItem.upcs}
            columns={[
              {
                title: 'UPC',
                align: 'center',
                className: 'text-xs',
                render: (text) => (
                  <span>{text}</span>
                ),
              }
            ]}
          />
        )}
      </>
    </Drawer>
  );
}
export default ItemGtinsDrawer;
