import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import dayjs from 'dayjs';
import { useJsonToCsv } from 'react-json-csv';
import { useRecoilValue, useRecoilState, useRecoilValueLoadable } from 'recoil';
import {
  Input,
  Table,
  Select,
  DatePicker,
  Popover,
  Drawer,
  Collapse,
  Spin,
  Tag,
  Switch,
  Menu,
  Dropdown,
  Avatar,
  notification,
  Tooltip,
  Button,
  Card
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { CheckCircleOutlined, CloseCircleOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { useMount } from 'react-use';
import bookingApi from '../../../../api/bookingApi';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';
import {
  mapSubscriptionBenefitType,
  toCurrency,
  mapSubscriptionRecurrence,
  formatPhoneNumber,
  thousand,
  getRecurranceEndDate,
  getDateExtensionEndDate,
  durationBetweenTwoDates
} from '../../../utils/functions';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import { timezoneAtom } from '../../../../atoms/Atoms';
import { configSelector } from '../../../../atoms/Selectors';

function UserSubscriptions() {
  const defaultUserSubscription = {
    subscriptionId: null,
    userId: null,
    countForSubscriptionLimit: true,
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
    allowPromo: false,
    wasSponsored: false
  };
  const config = useRecoilValueLoadable(configSelector);
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { t, i18n } = useTranslation();
  const [dates, setDates] = useState(null);
  const [locationFilter, setLocationFilter] = useState(null);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const { saveAsCsv } = useJsonToCsv();
  const timezone = useRecoilValue(timezoneAtom);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [sort, setSort] = useState(0);

  const [userSearchText, setUserSearchText] = useState('');
  const [selectedUserSubscription, setSelectedUserSubscription] = useState(null);
  const [data, setData] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const [loadingUsers, setLoadingUsers] = useState(false);
  const [updatingSubscription, setUpdatingSubscription] = useState(false);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
  const [loadingPayments, setLoadingPayments] = useState(false);
  const [loadingRemaining, setLoadingRemaining] = useState(false);
  const [loadingRedemptions, setLoadingRedemptions] = useState(false);
  const [users, setUsers] = useState([]);
  const bottomRowGridStyle = { width: '50%', textAlign: 'center' };
  const topRowGridStyle = { width: '100%', textAlign: 'center' };

  const [subscriptionLength, setSubscriptionLength] = useState('');

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function searchUsers() {
    setLoadingUsers(true);
    api
      .post('users/search', { SearchText: userSearchText })
      .then((response) => {
        setLoadingUsers(false);
        if (response?.data?.success) {
          setUsers(response.data.data.results);
        } else {
          showMessage(response?.data?.error);
        }
      })
      .catch((error) => {
        setLoadingUsers(false);
        showMessage(error.message);
      });
  }

  function getEndDate(subscriptionId) {
    const filter = _.filter(subscriptions, (s) => s.id === subscriptionId);
    if (filter.length > 0) {
      return getRecurranceEndDate(data?.startDate, filter[0].recurrence);
    }
    return data?.endDate;
  }

  function getSubscriptions() {
    setLoadingSubscriptions(true);
    bookingApi
      .get('subscriptions')
      .then((response) => {
        setLoadingSubscriptions(false);
        setSubscriptions(response.data.data);
      })
      .catch((error) => {
        setLoadingSubscriptions(false);
        console.error(error);
      });
  }

  function getUserSubscriptions() {
    setLoadingSubscriptions(true);
    bookingApi
      .get(`subscriptions/${data?.userId}/active`)
      .then((response) => {
        setLoadingSubscriptions(false);
        setUserSubscriptions(response.data.data);
      })
      .catch((error) => {
        setLoadingSubscriptions(false);
        console.error(error);
      });
  }

  function getLocations() {
    setLoadingLocations(true);
    bookingApi
      .get('locations')
      .then((response) => {
        setLoadingLocations(false);
        setLocations(response.data.data);
      })
      .catch((error) => {
        setLoadingLocations(false);
        console.error(error);
      });
  }

  function getData(force, page, text) {
    if (!loading || force) {
      setSearchText(text);
      setLoading(true);
      const payload = {
        Query: text,
        Page: page,
        Size: size,
        LocationId: locationFilter,
        StartDate: moment(dates?.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('YYYY-MM-DDTHH:mm:00.000'),
        EndDate: moment(dates?.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').add(1, 'days').format('YYYY-MM-DDTHH:mm:00.000'),
        Sort: sort
      };
      bookingApi.post('admin/subscription/report', payload)
        .then((response) => {
          setLoading(false);
          if (!response.data.success) {
            showMessage(response.data.error);
          } else {
            setReportData(response.data?.data);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    }
  }

  function loadPayments(id) {
    const index = _.findIndex(reportData.userSubscriptions, (u) => u.id === id);
    setLoadingPayments(true);
    bookingApi
      .get(`subscriptions/payments/${id}`)
      .then((response) => {
        setLoadingPayments(false);
        if (response.data.success) {
          if (response.data.message) {
            showMessage(response.data.message);
          }
          const temp = [...reportData?.userSubscriptions];
          temp[index].payments = response.data.data;
          setReportData({
            ...reportData,
            userSubscriptions: temp
          });
        }
      })
      .catch((error) => {
        setLoadingPayments(false);
        showMessage(error.message);
      });
  }

  function loadBenefits(id) {
    const index = _.findIndex(reportData.userSubscriptions, (u) => u.id === id);
    setLoadingRemaining(true);
    setLoadingRedemptions(true);
    bookingApi
      .get(`subscriptions/benefits/remaining/${id}`)
      .then((response) => {
        setLoadingRemaining(false);
        if (response.data.success) {
          if (response.data.message) {
            showMessage(response.data.message);
          }
          const temp = [...reportData?.userSubscriptions];
          temp[index].remainingBenefits = response.data.data;
          setReportData({
            ...reportData,
            userSubscriptions: temp
          });
        }
      })
      .catch((error) => {
        setLoadingRemaining(false);
        showMessage(error.message);
      });
    bookingApi
      .get(`subscriptions/benefits/redemptions/${id}`)
      .then((response) => {
        setLoadingRedemptions(false);
        if (response.data.success) {
          if (response.data.message) {
            showMessage(response.data.message);
          }
          const temp = [...reportData?.userSubscriptions];
          temp[index].redemptions = response.data.data;
          setReportData({
            ...reportData,
            userSubscriptions: temp
          });
        }
      })
      .catch((error) => {
        setLoadingRedemptions(false);
        showMessage(error.message);
      });
  }

  function createUserSub() {
    setUpdatingSubscription(true);
    bookingApi
      .post('subscriptions/onbehalf/subscribe', {
        ...data,
        subscriptionId: data.subscriptionId,
        userId: data.userId,
        countForSubscriptionLimit: data.countForSubscriptionLimit,
        allowPromo: data.allowPromo,
        wasSponsored: data.wasSponsored,
        startDate: moment(data.startDate.toString()).startOf('day'),
        endDate: moment(data.endDate.toString()).endOf('day')
      })
      .then((response) => {
        setUpdatingSubscription(false);
        if (response?.data?.success) {
          if (response?.data?.message) {
            showMessage(response?.data?.message);
          }
          getData(false, currentPage, searchText);
          setSelectedUserSubscription(null);
        } else {
          showMessage(response?.data?.error);
        }
      })
      .catch((error) => {
        setUpdatingSubscription(false);
        showMessage(error.message);
      });
  }

  function updateUserSub() {
    setUpdatingSubscription(true);
    bookingApi
      .put('subscriptions/onbehalf/subscription/update', {
        userSubscriptionId: data.id,
        startDate: moment(data.startDate.toString()).startOf('day'),
        endDate: moment(data.endDate.toString()).endOf('day'),
        countForSubscriptionLimit: data.countForLimit,
        isActive: data.isActive,
        wasSponsored: data.wasSponsored
      })
      .then((response) => {
        setUpdatingSubscription(false);
        if (response?.data?.success) {
          if (response?.data?.message) {
            showMessage(response?.data?.message);
          }
          getData(false, currentPage, searchText);
          setSelectedUserSubscription(null);
        } else {
          showMessage(response?.data?.error);
        }
      })
      .catch((error) => {
        setUpdatingSubscription(false);
        showMessage(error.message);
      });
  }

  function filterSubs(subs) {
    const temp = _.filter(subs, (s) => !_.includes(_.map(userSubscriptions, (us) => us.subscriptionId), s.id));
    setFilteredSubscriptions(temp);
  }

  useEffect(() => {
    if (firstLoad && dates) {
      setFirstLoad(false);
      getData(true, 0, '');
    }
  }, dates);

  useEffect(() => {
    setCurrentPage(0);
    getData(false, 0, searchText);
  }, [size, sort]);

  useEffect(async () => {
    setData(selectedUserSubscription);
    if (selectedUserSubscription && !selectedUserSubscription?.id) {
      setSubscriptions([]);
      getSubscriptions();
    }
  }, [selectedUserSubscription]);

  useEffect(() => {
    if (selectedUser) {
      getUserSubscriptions();
      setData({
        ...data,
        subscriptionId: null
      });
    } else {
      setUserSubscriptions([]);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (userSubscriptions && subscriptions) {
      filterSubs(subscriptions);
    } else if (subscriptions) {
      setFilteredSubscriptions(subscriptions);
    }
  }, [userSubscriptions, subscriptions]);

  useEffect(() => {
    if (data?.startDate && data?.endDate) {
      let tempDate = data?.endDate;
      if (data?.subscriptionId) {
        const sub = _.filter(subscriptions, (s) => s.id === data?.subscriptionId);
        if (sub.length > 0 && sub[0]?.isOnPromo && data?.allowPromo) {
          tempDate = getDateExtensionEndDate(data?.endDate, sub[0]?.dateExtensionType, sub[0]?.dateExtensionValue);
        }
      }
      setSubscriptionLength(durationBetweenTwoDates(data?.startDate, tempDate));
    }
  }, [data]);

  useMount(() => {
    setDates({
      start: moment().startOf('month'),
      end: moment().endOf('month'),
    });
    getLocations();
  });

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('user_subscriptions')}
        extra={[
          <Button
            type="primary"
            key="create"
            size="small"
            onClick={() => {
              setSelectedUserSubscription(defaultUserSubscription);
            }}
          >
            {t('create_new')}
          </Button>
        ]}
      />
      <div className="flex space-x-2">
        <div>
          <strong>{t('location')}:</strong>
          <br />
          <Select
            loading={loadingLocations}
            disabled={loadingLocations || loading}
            style={{ width: '140px' }}
            value={locationFilter}
            onChange={(value) => setLocationFilter(value)}
          >
            <Option
              key="any"
              value={null}
              className="text-xs flex items-center"
            >
              {t('any')}
            </Option>
            {_.map(locations, (location) => (
              <Option
                key={location.id}
                value={location.id}
                className="text-xs flex items-center"
              >
                {i18n.language === 'en' ? location.nameEn : location.nameEs}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <strong>{t('dates')}:</strong>
          <RangePicker
            disabled={loading}
            format="MMM, DD YYYY"
            className="w-full"
            allowClear={false}
            presets={[
              {
                label: t('current_month'),
                value: () => [moment().startOf('month'), moment().endOf('month')]
              },
              {
                label: t('last_month'),
                value: () => [moment().add(-1, 'month').startOf('month'), moment().add(-1, 'month').endOf('month')]
              },
              {
                label: t('current_and_last_month'),
                value: () => [moment().add(-1, 'month').startOf('month'), moment().endOf('month')]
              },
              {
                label: t('last_2_month'),
                value: () => [moment().add(-2, 'month').startOf('month'), moment().add(-1, 'month').endOf('month')]
              },
            ]}
            value={[
              dayjs(dates?.start),
              dayjs(dates?.end)
            ]}
            onChange={(date, dateString) => {
              if (dateString[0].length > 0 && dateString[0].length > 0) {
                setDates({
                  start: dateString[0],
                  end: dateString[1]
                });
              }
            }}
          />
        </div>
        <div>
          <strong>{t('filter')}:</strong>
          <br />
          <Select
            disabled={loading}
            style={{ width: '140px' }}
            value={sort}
            onChange={(value) => setSort(value)}
          >
            <Option
              key="any"
              value={0}
              className="text-xs flex items-center"
            >
              {t('any')}
            </Option>
            <Option
              key="paid"
              value={1}
              className="text-xs flex items-center"
            >
              {t('paid')}
            </Option>
            <Option
              key="sponsored"
              value={2}
              className="text-xs flex items-center"
            >
              {t('sponsored')}
            </Option>
          </Select>
        </div>
        <div>
          <strong>{t('sort')}:</strong>
          <br />
          <Select
            disabled={loading}
            style={{ width: '140px' }}
            value={sort}
            onChange={(value) => setSort(value)}
          >
            <Option
              key="any"
              value={0}
              className="text-xs flex items-center"
            >
              {t('default_sort')}
            </Option>
            <Option
              key="created_at_asc"
              value={1}
              className="text-xs flex items-center"
            >
              {t('created_at')}
              <SortAscendingOutlined className="ml-1" />
            </Option>
            <Option
              key="created_at_desc"
              value={2}
              className="text-xs flex items-center"
            >
              {t('created_at')}
              <SortDescendingOutlined className="ml-1" />
            </Option>
            <Option
              key="starting_asc"
              value={3}
              className="text-xs flex items-center"
            >
              {t('starting')}
              <SortAscendingOutlined className="ml-1" />
            </Option>
            <Option
              key="starting_desc"
              value={4}
              className="text-xs flex items-center"
            >
              {t('starting')}
              <SortDescendingOutlined className="ml-1" />
            </Option>
            <Option
              key="ending_asc"
              value={5}
              className="text-xs flex items-center"
            >
              {t('ending')}
              <SortAscendingOutlined className="ml-1" />
            </Option>
            <Option
              key="ending_desc"
              value={6}
              className="text-xs flex items-center"
            >
              {t('ending')}
              <SortDescendingOutlined className="ml-1" />
            </Option>
          </Select>
        </div>
        <Input.Search
          className="w-full mb-1 mt-5"
          key="userSubscriptionSearch"
          allowClear
          loading={loading}
          enterButton={t('search')}
          onSearch={(value) => {
            setCurrentPage(0);
            setSearchText(value);
            getData(false, 0, value);
          }}
        />
      </div>
      <br />
      <div>
        <dl className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-3 lg:grid-cols-3">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-lg font-medium text-gray-900 truncate">{t('subscriptions')}</dt>
              <dd className="mt-1 text-4xl font-semibold text-gray-900">{reportData && thousand(reportData.totalDateRange)}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-lg font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {t('total')}: {reportData && thousand(reportData.total)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-lg font-medium text-gray-900 truncate">{t('active_subscriptions')}</dt>
              <dd className="mt-1 text-4xl font-semibold text-gray-900">{reportData && thousand(reportData.activeDateRange)}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-lg font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {t('total')}: {reportData && thousand(reportData.totalActive)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-lg font-medium text-gray-900 truncate">{t('revenue')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{reportData && toCurrency(reportData.revenueDateRange)}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-lg font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {t('total')}: {reportData && toCurrency(reportData.totalRevenue)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-lg font-medium text-gray-900 truncate">{t('users_subscribed')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{reportData && thousand(reportData.usersSubscribedDateRange)}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-lg font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {t('total')}: {reportData && thousand(reportData.totalUsersSubscribed)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-lg font-medium text-gray-900 truncate">{t('sponsored_subscriptions')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{reportData && thousand(reportData.sponsoredDateRange)}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-lg font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {t('total')}: {reportData && thousand(reportData.sponsoredDateRange)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-lg font-medium text-gray-900 truncate">{t('manually_assigned')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{reportData && thousand(reportData.manuallyAssignedDateRange)}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-lg font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {t('total')}: {reportData && thousand(reportData.totalManuallyAssigned)}
                </div>
              </dd>
            </Spin>
          </div>
        </dl>
      </div>
      <br />
      <Table
        className="mt-2"
        bordered
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize: size,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: currentPage + 1,
          total: reportData?.totalDateRange,
          onChange: (page, pageSize) => {
            if (page - 1 !== currentPage) {
              setCurrentPage(page - 1);
              getData(false, page - 1, searchText);
            } else {
              setSize(pageSize);
            }
          }
        }}
        columns={[
          {
            title: t('user'),
            key: 'user',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="flex space-x-2">
                {row.user.avatar && row.user.avatar.length === 0 && (
                  <Avatar
                    style={{ background: config?.contents?.data?.primaryColor }}
                  >
                    {`${row.user.firstName[0]}${row.user.lastName[0]}`}
                  </Avatar>
                )}
                {row.user.avatar && row.user.avatar.length > 0 && (
                  <Avatar
                    style={{ background: config?.contents?.data?.primaryColor }}
                    src={row.user.avatar}
                  />
                )}
                <div className="text-left ml-2">
                  <div><span className="mt-1">{row.user.completeName}</span></div>
                  <div><span className="mt-1">{row.user.email}</span></div>
                  <div><span className="mt-1">{formatPhoneNumber(row.user.phoneNumber)}</span></div>
                </div>
              </div>
            ),
          },
          {
            title: t('location'),
            key: 'location',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{i18n.language === 'en' ? row.subscription?.location?.nameEn : row.subscription?.location?.nameEs}</span>
              </div>
            ),
          },
          {
            title: t('name'),
            key: 'name',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{i18n.language === 'en' ? row.subscription.nameEn : row.subscription.nameEs}</span>
              </div>
            ),
          },
          {
            title: t('is_active'),
            key: 'isActive',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>
                  {row.isActive ?
                    <Tag className="text-center" color="green">{t('yes')}<br />({durationBetweenTwoDates(row.startDate, moment(row.endDate).add(-1, 'minute'))})</Tag> :
                    <Tag color="red">{t('no')}</Tag>}
                </span>
              </div>
            ),
          },
          {
            title: t('originally_purchased_on'),
            key: 'createdAt',
            dataIndex: 'createdAt',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
              </div>
            ),
          },
          {
            title: t('start_date'),
            key: 'startDate',
            dataIndex: 'startDate',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                {/* <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span> */}
              </div>
            ),
          },
          {
            title: t('end_date'),
            key: 'endDate',
            dataIndex: 'endDate',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').add(-1, 'minute').format('ddd')}</span>
                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').add(-1, 'minute').format('MMM D')}</span>
                {/* <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').add(-1, 'minute').format('h:mm A')}</span> */}
              </div>
            ),
          },
          {
            title: t('bought_on_promo'),
            key: 'boughtWithPromo',
            align: 'center',
            dataIndex: 'boughtWithPromo',
            className: 'text-xs',
            render: (row) => (
              <div>{row ? <CheckCircleOutlined className="text-green-600" /> : <CloseCircleOutlined className="text-red-600" />}</div>
            ),
          },
          {
            title: t('was_sponsored'),
            key: 'was_sponsored',
            align: 'center',
            dataIndex: 'wasSponsored',
            className: 'text-xs',
            render: (row) => (
              <div>{row ? <CheckCircleOutlined className="text-green-600" /> : <CloseCircleOutlined className="text-red-600" />}</div>
            ),
          },
          {
            title: t('assigned_by'),
            key: 'onBehalfUser',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <>
                {row.onBehalfUser && (
                  <div className="flex space-x-2">
                    {row.user.avatar && row.user.avatar.length === 0 && (
                      <Avatar
                        style={{ background: config?.contents?.data?.primaryColor }}
                      >
                        {`${row.user.firstName[0]}${row.user.lastName[0]}`}
                      </Avatar>
                    )}
                    {row.user.avatar && row.user.avatar.length > 0 && (
                      <Avatar
                        style={{ background: config?.contents?.data?.primaryColor }}
                        src={row.user.avatar}
                      />
                    )}
                    <span className="mt-1">{row.user.completeName}</span>
                  </div>
                )}
                {!row.onBehalfUser && (
                  <span>-</span>
                )}
              </>
            ),
          },
          {
            title: t('actions'),
            key: 'actions',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="flex space-x-2">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    loadPayments(row.id);
                  }}
                >
                  {t('load_payments')}
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    loadBenefits(row.id);
                  }}
                >
                  {t('load_benefits')}
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setSelectedUserSubscription(row);
                  }}
                >
                  {t('edit')}
                </Button>
              </div>
            ),
          }
        ]}
        dataSource={reportData?.userSubscriptions}
        expandable={{
          expandRowByClick: false,
          expandedRowRender: (record) => (
            <div className="space-y-2">
              {(record?.payments || loadingPayments) && (
                <Collapse
                  size="small"
                  items={[{
                    key: 'payments',
                    label: t('payments'),
                    children: (
                      <Table
                        size="small"
                        bordered
                        pagination="none"
                        loading={loadingPayments}
                        dataSource={record?.payments}
                        columns={[
                          {
                            title: t('purchased_on'),
                            key: 'createdAt',
                            dataIndex: 'createdAt',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <div className="flex flex-col">
                                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                              </div>
                            ),
                          },
                          {
                            title: t('price'),
                            key: 'amount',
                            dataIndex: 'amount',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <div className="flex flex-col">
                                <span>{toCurrency(row)}</span>
                              </div>
                            ),
                          },
                          {
                            title: t('status'),
                            key: 'status',
                            dataIndex: 'status',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <div className="flex flex-col">
                                <span>{row === 1 ? <Tag color="green">{t('successful')}</Tag> : <Tag color="red">{t('failed')}</Tag>}</span>
                              </div>
                            ),
                          }
                        ]}
                      />
                    ) }
                  ]}
                />
              )}
              {(record?.remainingBenefits || loadingRemaining) && (
                <Collapse
                  size="small"
                  items={[{
                    key: 'remaining_benefits',
                    label: t('remaining_benefits'),
                    children: (
                      <Table
                        size="small"
                        bordered
                        pagination="none"
                        loading={loadingRemaining}
                        dataSource={record?.remainingBenefits}
                        columns={[
                          {
                            title: t('benefit'),
                            key: 'benefit',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <span>{i18n.language === 'en' ? row.nameEn : row.nameEs}</span>
                            )
                          },
                          {
                            title: t('recurrence'),
                            key: 'recurrence',
                            dataIndex: 'recurrence',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <span>{t(mapSubscriptionRecurrence(row))}</span>
                            )
                          },
                          {
                            title: t('type'),
                            key: 'type',
                            dataIndex: 'type',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <span>{t(mapSubscriptionBenefitType(row))}</span>
                            )
                          },
                          {
                            title: t('included'),
                            key: 'totalLimit',
                            dataIndex: 'totalLimit',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <span>{row ? thousand(row) : '-'}</span>
                            )
                          },
                          {
                            title: t('remaining'),
                            key: 'limit',
                            dataIndex: 'limit',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <span>{row ? thousand(row) : '-'}</span>
                            )
                          },
                          {
                            title: t('amount'),
                            key: 'amount',
                            dataIndex: 'amount',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <span>{row ? toCurrency(row) : '-'}</span>
                            )
                          },
                          // {
                          //   title: t('applicable_service'),
                          //   key: 'applicableService',
                          //   align: 'center',
                          //   className: 'text-xs',
                          //   render: (row) => (
                          //     <span>{row.applicableService ? (i18n.language === 'en' ? row.applicableService.nameEn : row.applicableService.nameEs) : '-'}</span>
                          //   )
                          // },
                        ]}
                      />
                    ) }
                  ]}
                />
              )}
              {(record?.redemptions || loadingRedemptions) && (
                <Collapse
                  size="small"
                  items={[{
                    key: 'benefit_redemptions',
                    label: t('benefit_redemptions'),
                    children: (
                      <Table
                        size="small"
                        bordered
                        pagination="none"
                        loading={loadingRedemptions}
                        dataSource={record?.redemptions}
                        columns={[
                          {
                            title: t('benefit'),
                            key: 'benefit',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <span>{i18n.language === 'en' ? row.benefit.nameEn : row.benefit.nameEs}</span>
                            )
                          },
                          {
                            title: t('used_on'),
                            key: 'createdAt',
                            dataIndex: 'createdAt',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <div className="flex flex-col">
                                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                              </div>
                            ),
                            sorter: (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
                          }
                        ]}
                      />
                    ) }
                  ]}
                />
              )}
            </div>
          ),
          rowExpandable: (record) => record?.redemptions || record?.remainingBenefits || record?.payments,
        }}
      />
      <Drawer
        title={t('create_user_subscription')}
        placement="right"
        closable={!updatingSubscription}
        onClose={() => {
          setSelectedUserSubscription(null);
        }}
        open={selectedUserSubscription && !selectedUserSubscription?.id}
        width={350}
      >
        <div className="space-y-4">
          <div>
            <strong>{t('user')}</strong>
            {!data?.user && (
              <Dropdown
                trigger="click"
                placement="bottom"
                overlay={
                  <Menu>
                    {_.map(users, (u) => (
                      <Menu.Item
                        key={u.id}
                        onClick={() => {
                          setData({
                            ...data,
                            userId: u.id
                          });
                          setSelectedUser(u);
                          setUsers([]);
                          setUserSearchText('');
                        }}
                      >
                        {u.completeName}: {u.email}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <Input.Search
                  className="w-full"
                  allowClear
                  loading={loadingUsers}
                  disabled={updatingSubscription}
                  value={userSearchText}
                  onChange={(obj) => {
                    setUserSearchText(obj.target.value);
                  }}
                  onSearch={() => searchUsers()}
                  enterButton={t('search')}
                />
              </Dropdown>
            )}
            {selectedUser && (
              <div className="flex mt-1 space-x-2 w-full">
                {selectedUser?.avatar && selectedUser?.avatar.length === 0 && (
                  <Avatar
                    style={{ background: config?.contents?.data?.primaryColor }}
                  >
                    {`${selectedUser?.firstName[0]}${selectedUser?.lastName[0]}`}
                  </Avatar>
                )}
                {selectedUser?.avatar && selectedUser?.avatar.length > 0 && (
                  <Avatar
                    style={{ background: config?.contents?.data?.primaryColor }}
                    src={selectedUser?.avatar}
                  />
                )}
                <span className="mt-1">{selectedUser?.completeName}</span>
                <Button
                  size="small"
                  className="mt-1"
                  danger
                  onClick={() => {
                    setData({
                      ...data,
                      userId: null
                    });
                    setSelectedUser(null);
                  }}
                >
                  {t('remove')}
                </Button>
              </div>
            )}
          </div>
          <div>
            <strong>{t('subscription')}:</strong>
            <Select
              loading={loadingSubscriptions}
              disabled={loadingSubscriptions || updatingSubscription}
              className="w-full"
              value={data?.subscriptionId}
              onChange={(value) => {
                const endDate = getEndDate(value);
                setData({
                  ...data,
                  subscriptionId: value,
                  endDate
                });
              }}
            >
              <Option
                key="null"
                value={null}
                className="text-xs flex items-center"
              >
                {t('select_subscription')}
              </Option>
              {_.map(filteredSubscriptions, (subscription) => (
                <Option
                  key={subscription.id}
                  value={subscription.id}
                  className="text-xs flex items-center"
                >
                  {i18n.language === 'en' ? subscription.nameEn : subscription.nameEs}
                  {subscription.locationId && ` @ ${i18n.language === 'en' ? subscription.location?.nameEn : subscription.location?.nameEs}`}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <strong>{t('override_dates')}</strong>
            <br />
            <Switch
              disabled={loading}
              checked={data?.overrideDates}
              onChange={(checked) => {
                setData({
                  ...data,
                  overrideDates: checked
                });
              }}
            />
          </div>
          <div hidden={!data?.overrideDates}>
            <strong>{t('dates')}:</strong>
            <RangePicker
              allowClear={false}
              disabled={loading}
              format="MMM, DD YYYY"
              className="w-full"
              value={[
                dayjs(data?.startDate),
                dayjs(data?.endDate)
              ]}
              onChange={(date, dateString) => {
                if (dateString[0].length > 0 && dateString[0].length > 0) {
                  setData({
                    ...data,
                    startDate: dateString[0],
                    endDate: dateString[1]
                  });
                }
              }}
            />
          </div>
          <div>
            <strong>{t('was_sponsored')}</strong>
            <br />
            <Switch
              disabled={loading}
              checked={data?.wasSponsored}
              onChange={(checked) => {
                setData({
                  ...data,
                  wasSponsored: checked
                });
              }}
            />
          </div>
          <div hidden={!data?.subscriptionId || _.filter(subscriptions, (s) => s.id === data?.subscriptionId && s.isOnPromo).length === 0}>
            <strong>
              {t('allow_promo')}
              {_.filter(subscriptions, (s) => s.id === data?.subscriptionId && s.isOnPromo && s.dateExtensionType > 0) && (
                <>
                  {` (+${durationBetweenTwoDates(moment(), getDateExtensionEndDate(moment(),
                    _.filter(subscriptions, (s) => s.id === data?.subscriptionId && s.isOnPromo)[0]?.dateExtensionType,
                    _.filter(subscriptions, (s) => s.id === data?.subscriptionId && s.isOnPromo)[0]?.dateExtensionValue))})`}
                </>
              )}
            </strong>
            <br />
            <Switch
              disabled={loading}
              checked={data?.allowPromo}
              onChange={(checked) => {
                setData({
                  ...data,
                  allowPromo: checked
                });
              }}
            />
          </div>
          <div>
            <strong>{t('count_for_limit')}</strong>
            <br />
            <Switch
              disabled={loading}
              checked={data?.countForSubscriptionLimit}
              onChange={(checked) => {
                setData({
                  ...data,
                  countForSubscriptionLimit: checked
                });
              }}
            />
          </div>
          <div>
            <strong>{t('subscription_length')}</strong>: {subscriptionLength?.length > 0 ? subscriptionLength : '23h 59m'}
          </div>
          <div
            className="float-right"
          >
            <Button
              type="primary"
              loading={loading}
              disabled={!data?.userId || !data?.subscriptionId || !data?.startDate || !data?.endDate}
              onClick={() => { createUserSub(); }}
            >
              {t('create')}
            </Button>
          </div>
        </div>
      </Drawer>
      <Drawer
        title={t('update_user_subscription')}
        placement="right"
        closable={!updatingSubscription}
        onClose={() => {
          setSelectedUserSubscription(null);
        }}
        open={selectedUserSubscription && selectedUserSubscription?.id}
        width={350}
      >
        <div className="space-y-4">
          <div>
            <strong>{t('dates')}:</strong>
            <RangePicker
              allowClear={false}
              disabled={loading}
              format="MMM, DD YYYY"
              className="w-full"
              value={[
                dayjs(data?.startDate),
                dayjs(data?.endDate)
              ]}
              onChange={(date, dateString) => {
                if (dateString[0].length > 0 && dateString[0].length > 0) {
                  setData({
                    ...data,
                    startDate: dateString[0],
                    endDate: dateString[1]
                  });
                }
              }}
            />
          </div>
          <div>
            <strong>{t('is_active')}</strong>
            <br />
            <Switch
              disabled={loading}
              checked={data?.isActive}
              onChange={(checked) => {
                setData({
                  ...data,
                  isActive: checked
                });
              }}
            />
          </div>
          <div>
            <strong>{t('was_sponsored')}</strong>
            <br />
            <Switch
              disabled={loading}
              checked={data?.wasSponsored}
              onChange={(checked) => {
                setData({
                  ...data,
                  wasSponsored: checked
                });
              }}
            />
          </div>
          <div>
            <strong>{t('count_for_limit')}</strong>
            <br />
            <Switch
              disabled={loading}
              checked={data?.countForLimit}
              onChange={(checked) => {
                setData({
                  ...data,
                  countForLimit: checked
                });
              }}
            />
          </div>
          <div
            className="float-right"
          >
            <Button
              type="primary"
              loading={loading}
              disabled={!data?.startDate || !data?.endDate}
              onClick={() => { updateUserSub(); }}
            >
              {t('update')}
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default UserSubscriptions;
