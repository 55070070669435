import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Avatar,
  Tooltip,
  DatePicker,
  Button,
  Switch,
  Row,
  Table,
  Select,
  InputNumber,
  Input,
  notification,
  Collapse,
  Col
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  CloseCircleOutlined,
  FormOutlined
} from '@ant-design/icons';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  setSelectedBusinessItemAtom,
  setSelectedMasterItemAtom,
  showEditItemPriceAtom,
  selectedItemPriceAtom,
  otherBusinessItemsAtom,
  showItemUpcsAtom,
  stateAtom,
  profileAtom,
  selectedBusinessItemForLogsAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function EditBusinessItemDrawer() {
  const { RangePicker } = DatePicker;
  const { Panel } = Collapse;
  const { Option } = Select;
  const currentstate = useRecoilValue(stateAtom);
  const setShowItemUpcs = useSetRecoilState(showItemUpcsAtom);
  const selectedMasterItem = useRecoilValue(setSelectedMasterItemAtom);
  const setShowEditItemPrice = useSetRecoilState(showEditItemPriceAtom);
  const setSelectedItemPriceAtom = useSetRecoilState(selectedItemPriceAtom);
  const [selectedBusinessItem, setSelectedBusinessItem] =
    useRecoilState(setSelectedBusinessItemAtom);
  const [otherBusinessItems, setOtherBusinessItems] = useRecoilState(otherBusinessItemsAtom);
  const [loading, setLoading] = useState(false);
  const [itemData, setItemData] = useState(null);
  const [aisles, setAisles] = useState([]);
  const [showAisleEdit, setShowAisleEdit] = useState(false);
  const [sections, setSections] = useState([]);
  const [applyToMultipleStores, setApplyToMultipleStores] = useState(false);
  const [selectedBusinessItemIds, setSelectedBusinessItemIds] = useState([]);
  const selectedBusinessItemForLogs = useSetRecoilState(selectedBusinessItemForLogsAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const isSuperAdmin =
    profile.contents && (profile?.contents?.roles?.includes('SuperAdmin'));
  const { t, i18n } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  function mapSections(data) {
    const mapped = _.map(data, (p, index) => ({
      key: index,
      id: p.id,
      name: p.name,
      nameEng: p.nameEng,
      superSectionName: p.superSectionName,
      superSectionNameEng: p.superSectionNameEng
    }));

    return _.sortBy(mapped, [(o) => o.name]);
  }

  const getSections = function () {
    api
      .get(`businesses/${selectedBusinessItem.businessId}/menusections`)
      .then((response) => {
        if (response.data.success) {
          setSections(mapSections(response.data.data.results));
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const getAisles = function () {
    api
      .get(`businesses/${selectedBusinessItem.businessId}/aisle`)
      .then((response) => {
        if (response.data.success) {
          setAisles(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const updateItemClick = async function () {
    setLoading(true);
    const tempData = JSON.parse(JSON.stringify(itemData));
    if (currentstate.managesMultiplePrices) {
      tempData.prices = null;
      tempData.price = null;
    } else {
      if (!tempData || !tempData?.prices || tempData?.prices?.length <= 0) {
        tempData.prices = [{}];
      }
      if (tempData.prices[0].promoStartDate?.length > 0) {
        tempData.prices[0].promoStartDate = moment(tempData.prices[0].promoStartDate).utc();
        tempData.prices[0].promoEndDate = moment(tempData.prices[0].promoEndDate).utc();
      }
      if (tempData.prices[0].promoStartDate?.length < 17) {
        tempData.prices[0].promoStartDate = `${tempData.prices[0].promoStartDate.replace(' ', 'T')}:00.000`;
        tempData.prices[0].promoEndDate = `${tempData.prices[0].promoEndDate.replace(' ', 'T')}:00.000`;
      }
      const [price] = tempData.prices;
      tempData.price = price;
      tempData.prices = null;
    }
    // console.log(tempData);

    if (tempData.timeConstraint.limitDate?.length < 17) {
      tempData.timeConstraint.limitDate = `${tempData.timeConstraint.limitDate.replace(' ', 'T')}:00.000`;
    }

    const [data] = tempData.datas;
    tempData.data = data;
    // console.log(data);
    tempData.datas = null;
    const tempIds = [selectedBusinessItem.id]
      .concat(JSON.parse(JSON.stringify(selectedBusinessItemIds)));
    for (let i = 0; i < tempIds.length; i++) {
      tempData.id = tempIds[i];
      const response = await api
        .post(
          `businesses/catalog/business/${tempData.id}/update`,
          tempData
        );
      if (!response.data.success) {
        showMessage(response.data.error);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedBusinessItem) {
      const temp = JSON.parse(JSON.stringify(selectedBusinessItem));
      setItemData(temp);
      getAisles();
      getSections();
    } else {
      setOtherBusinessItems([]);
    }
  }, [selectedBusinessItem]);

  return (
    <Drawer
      title={t('edit_business_item')}
      placement="right"
      closable
      onClose={() => setSelectedBusinessItem(null)}
      open={selectedBusinessItem}
      width={400}
    >
      {isSuperAdmin && (
        <div>
          <Button
            loading={loading}
            type="primary"
            className="mt-2 float-right"
            onClick={() => {
              selectedBusinessItemForLogs({
                id: itemData?.id,
                page: 0,
                size: 20
              });
            }}
          >
            {t('logs')}
          </Button>
          <br />
          <br />
        </div>
      )}
      <div className="flex flex-col ml-1 text-md font-semibold tracking-tight mb-2">
        <div>{selectedMasterItem.brand}</div>
        <div>{selectedMasterItem.name}</div>
        <div>{selectedMasterItem.description}</div>
      </div>
      {itemData && (
        <div>
          <Collapse defaultActiveKey={['1']}>
            <Panel header="General" key="1">
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>Upc</strong>
                <Input
                  disabled={loading}
                  placeholder="UPC"
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.upc = obj.target.value;
                    setItemData(temp);
                  }}
                  value={itemData.upc}
                />
                <Button
                  type="primary"
                  className="mt-2"
                  onClick={() => { setShowItemUpcs(true); }}
                >
                  {t('view')} Upcs
                </Button>
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('department')} </strong>
                <Input
                  disabled={loading}
                  placeholder={t('department')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.department = obj.target.value;
                    setItemData(temp);
                  }}
                  value={itemData.department}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('aisle')} </strong>
                {!showAisleEdit && (
                  <>
                    <Select
                      defaultValue={itemData.aisle}
                      style={{ width: '90%' }}
                      disabled={loading}
                      showSearch="true"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        if (option && option.value) {
                          return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }
                        return false;
                      }}
                      onChange={(value) => {
                        const temp = JSON.parse(JSON.stringify(itemData));
                        temp.aisle = value;
                        setItemData(temp);
                      }}
                    >
                      <Option
                        value=""
                        key="empty"
                      />
                      {aisles && _.map(aisles, (aisle, index) => (
                        <Option
                          value={aisle}
                          key={`aisle${index}${aisle}`}
                        >
                          {aisle}
                        </Option>
                      ))}
                    </Select>
                    <FormOutlined
                      style={{ marginLeft: 10 }}
                      onClick={() => { setShowAisleEdit(true); }}
                    />
                  </>
                )}
                {showAisleEdit && (
                  <>
                    <Input
                      style={{ width: '90%' }}
                      disabled={loading}
                      placeholder={t('aisle')}
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(itemData));
                        temp.aisle = obj.target.value;
                        setItemData(temp);
                      }}
                      value={itemData.aisle}
                    />
                    <CloseCircleOutlined
                      style={{ marginLeft: 10 }}
                      onClick={() => { setShowAisleEdit(false); }}
                    />
                  </>
                )}
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('category')}</strong>
                <br />
                <Select
                  defaultValue={itemData.sectionId}
                  style={{ width: '100%' }}
                  disabled={loading}
                  showSearch="true"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                  }}
                  onChange={(value) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.sectionId = value;
                    setItemData(temp);
                  }}
                >
                  {sections && _.map(sections, (section) => (
                    <Option
                      key={section.id}
                      value={section.id}
                    >
                      {i18n.language === 'en' ? section.nameEng : section.name} @ {i18n.language === 'en' ? section.superSectionNameEng : section.superSectionName}
                    </Option>
                  ))}
                </Select>
              </div>
              <Row style={{ marginBottom: '10px' }}>
                <Col>
                  <div>
                    <strong>{t('minimum')}</strong>
                    <br />
                    <InputNumber
                      disabled={loading}
                      min={0.1}
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(itemData));
                        temp.datas[0].minQuantity = obj;
                        setItemData(temp);
                      }}
                      value={itemData.datas[0].minQuantity}
                    />
                  </div>
                </Col>
                <Col
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px'
                  }}
                >
                  <div>
                    <strong>{t('maximum')}</strong>
                    <br />
                    <InputNumber
                      disabled={loading}
                      min={1}
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(itemData));
                        temp.datas[0].maxQuantity = obj;
                        setItemData(temp);
                      }}
                      value={itemData.datas[0].maxQuantity}
                    />
                  </div>
                </Col>
                <Col>
                  <div>
                    <strong>{t('step_amount')}</strong>
                    <br />
                    <InputNumber
                      disabled={loading}
                      min={0.1}
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(itemData));
                        temp.datas[0].stepAmount = obj;
                        setItemData(temp);
                      }}
                      value={itemData.datas[0].stepAmount}
                    />
                  </div>
                </Col>
              </Row>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('random_weight')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.datas[0].hasRandomWeight}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].hasRandomWeight = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('weight_type')}</strong>
                <br />
                <Select
                  defaultValue={itemData.datas[0].weightType}
                  style={{ width: '100%' }}
                  disabled={loading || !itemData.datas[0].hasRandomWeight}
                  onChange={(value) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].weightType = value;
                    setItemData(temp);
                  }}
                >
                  <Option
                    key="lb"
                    value={0}
                  >
                    LB
                  </Option>
                  <Option
                    key="unit"
                    value={4}
                  >
                    Unit/LB
                  </Option>
                </Select>
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('unit_per_package')}</strong>
                <br />
                <InputNumber
                  disabled={loading || !itemData.datas[0].hasRandomWeight}
                  min={0.1}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].unitPriceFactor = obj;
                    setItemData(temp);
                  }}
                  value={itemData.datas[0].unitPriceFactor}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('custom_search_tag')}</strong> <small>{t('custom_search_tag_note')}</small>
                <Input
                  disabled={loading}
                  placeholder={t('custom_search_tag')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.customSearchTags = obj.target.value;
                    setItemData(temp);
                  }}
                  value={itemData.customSearchTags}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('item_type')}</strong>
                <Select
                  defaultValue={itemData?.type}
                  style={{ width: '100%' }}
                  disabled={loading || !itemData?.type}
                  onChange={(value) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.type = value;
                    setItemData(temp);
                  }}
                >
                  <Option
                    key="regular"
                    value={0}
                  >
                    {t('in_store')}
                  </Option>
                  <Option
                    key="external"
                    value={1}
                  >
                    {t('external_supplier')}
                  </Option>
                </Select>
              </div>
            </Panel>
            <Panel header={t('time_constraints')} key="6">
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('limit_date')}</strong>
                <br />
                <DatePicker
                  onChange={(date, dateString) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.timeConstraint.limitDate = dateString;
                    setItemData(temp);
                  }}
                  allowClear
                  value={itemData.timeConstraint.limitDate
                    ? dayjs(itemData.timeConstraint.limitDate) : null}
                />
              </div>
              <Row>
                <Col
                  style={{
                    marginRight: '5px'
                  }}
                >
                  <div>
                    <strong>{t('hour_delay')}</strong>
                    <br />
                    <InputNumber
                      disabled={loading}
                      min={0}
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(itemData));
                        temp.timeConstraint.hourDelay = obj;
                        setItemData(temp);
                      }}
                      value={itemData.timeConstraint.hourDelay}
                    />
                  </div>
                </Col>
                <Col
                  style={{
                    marginLeft: '5px'
                  }}
                >
                  <div>
                    <strong>{t('minute_delay')}</strong>
                    <br />
                    <InputNumber
                      disabled={loading}
                      min={0}
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(itemData));
                        temp.timeConstraint.minuteDelay = obj;
                        setItemData(temp);
                      }}
                      value={itemData.timeConstraint.minuteDelay}
                    />
                  </div>
                </Col>
              </Row>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('available_on_mondays')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.timeConstraint.availableOnMondays}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.timeConstraint.availableOnMondays = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('available_on_tuesdays')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.timeConstraint.availableOnTuesdays}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.timeConstraint.availableOnTuesdays = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('available_on_wednesday')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.timeConstraint.availableOnWednesdays}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.timeConstraint.availableOnWednesdays = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('available_on_thursdays')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.timeConstraint.availableOnThursdays}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.timeConstraint.availableOnThursdays = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('available_on_fridays')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.timeConstraint.availableOnFridays}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.timeConstraint.availableOnFridays = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('available_on_saturdays')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.timeConstraint.availableOnSaturdays}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.timeConstraint.availableOnSaturdays = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('available_on_sundays')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.timeConstraint.availableOnSundays}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.timeConstraint.availableOnSundays = checked;
                    setItemData(temp);
                  }}
                />
              </div>
            </Panel>
            {currentstate && !currentstate.managesMultiplePrices && (
              <>
                <Panel header={t('base_price')} key="2">
                  <Row>
                    <Col
                      style={{
                        marginRight: '5px'
                      }}
                    >
                      <div>
                        <strong>{t('price')}</strong>
                        <br />
                        <InputNumber
                          disabled={loading}
                          min={0}
                          step="0.01"
                          onChange={(obj) => {
                            const temp = JSON.parse(JSON.stringify(itemData));
                            temp.prices[0].basePrice = obj;
                            setItemData(temp);
                          }}
                          value={itemData.prices[0].basePrice}
                        />
                      </div>
                    </Col>
                    <Col
                      style={{
                        marginLeft: '5px'
                      }}
                    >
                      <div>
                        <strong>{t('base_multiple')}</strong>
                        <br />
                        <InputNumber
                          disabled={loading}
                          min={1}
                          onChange={(obj) => {
                            const temp = JSON.parse(JSON.stringify(itemData));
                            temp.prices[0].baseMultiple = obj;
                            setItemData(temp);
                          }}
                          value={itemData.prices[0].baseMultiple}
                        />
                      </div>
                    </Col>
                    <Col
                      style={{
                        marginLeft: '20px'
                      }}
                    >
                      <div>
                        <strong>{t('cost')}</strong>
                        <br />
                        <InputNumber
                          disabled={loading}
                          min={0}
                          step="0.01"
                          onChange={(obj) => {
                            const temp = JSON.parse(JSON.stringify(itemData));
                            temp.prices[0].cost = obj;
                            setItemData(temp);
                          }}
                          value={itemData.prices[0].cost}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div
                    style={{ marginBottom: '10px' }}
                  >
                    <strong>{t('price_method')}</strong>
                    <br />
                    <Select
                      defaultValue={itemData.prices[0].basePriceMethod}
                      style={{ width: '100%' }}
                      disabled={loading}
                      onChange={(value) => {
                        const temp = JSON.parse(JSON.stringify(itemData));
                        temp.prices[0].basePriceMethod = value;
                        setItemData(temp);
                      }}
                    >
                      <Option
                        key="baseunit"
                        value={0}
                      >
                        {t('unit')}
                      </Option>
                      <Option
                        key="basesplit"
                        value={1}
                      >
                        {t('split')}
                      </Option>
                    </Select>
                  </div>
                  <div
                    style={{ marginBottom: '10px' }}
                  >
                    <strong>{t('tax_rule')}</strong>
                    <br />
                    <Select
                      defaultValue={itemData.prices[0].taxRule}
                      style={{ width: '100%' }}
                      disabled={loading}
                      onChange={(value) => {
                        const temp = JSON.parse(JSON.stringify(itemData));
                        temp.prices[0].taxRule = value;
                        setItemData(temp);
                      }}
                    >
                      <Option
                        key="taxnone"
                        value={0}
                      >
                        {t('none')}
                      </Option>
                      <Option
                        key="taxmun"
                        value={1}
                      >
                        {t('municipal')}
                      </Option>
                      <Option
                        key="taxstate"
                        value={2}
                      >
                        {t('state')}
                      </Option>
                      <Option
                        key="taxboth"
                        value={3}
                      >
                        {t('both')}
                      </Option>
                    </Select>
                    <Row>
                      <Col
                        style={{
                          marginRight: '5px'
                        }}
                      >
                        <div>
                          <strong>Mun Tax %</strong>
                          <br />
                          <InputNumber
                            disabled={loading}
                            min={0}
                            step="0.01"
                            onChange={(obj) => {
                              const temp = JSON.parse(JSON.stringify(itemData));
                              temp.munTaxPct = obj;
                              setItemData(temp);
                            }}
                            value={itemData.munTaxPct}
                          />
                        </div>
                      </Col>
                      <Col
                        style={{
                          marginLeft: '5px'
                        }}
                      >
                        <div>
                          <strong>State Tax %</strong>
                          <br />
                          <InputNumber
                            disabled={loading}
                            min={0}
                            step="0.01"
                            onChange={(obj) => {
                              const temp = JSON.parse(JSON.stringify(itemData));
                              temp.stateTaxPct = obj;
                              setItemData(temp);
                            }}
                            value={itemData.stateTaxPct}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Panel>
                <Panel header={t('promo_price')} key="3">
                  <Row>
                    <Col
                      style={{
                        marginRight: '5px'
                      }}
                    >
                      <div>
                        <strong>{t('price')}</strong>
                        <br />
                        <InputNumber
                          disabled={loading}
                          min={0}
                          step="0.01"
                          onChange={(obj) => {
                            const temp = JSON.parse(JSON.stringify(itemData));
                            temp.prices[0].promoPrice = obj;
                            setItemData(temp);
                          }}
                          value={itemData.prices[0].promoPrice}
                        />
                      </div>
                    </Col>
                    <Col
                      style={{
                        marginLeft: '5px'
                      }}
                    >
                      <div>
                        <strong>Multiple</strong>
                        <br />
                        <InputNumber
                          disabled={loading}
                          min={1}
                          onChange={(obj) => {
                            const temp = JSON.parse(JSON.stringify(itemData));
                            temp.prices[0].promoMultiple = obj;
                            setItemData(temp);
                          }}
                          value={itemData.prices[0].promoMultiple}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div
                    style={{ marginBottom: '10px' }}
                  >
                    <strong>{t('price_method')}</strong>
                    <br />
                    <Select
                      defaultValue={itemData.prices[0].promoPriceMethod}
                      style={{ width: '100%' }}
                      disabled={loading}
                      onChange={(value) => {
                        const temp = JSON.parse(JSON.stringify(itemData));
                        temp.prices[0].promoPriceMethod = value;
                        setItemData(temp);
                      }}
                    >
                      <Option
                        key="promounit"
                        value={0}
                      >
                        {t('unit')}
                      </Option>
                      <Option
                        key="promosplit"
                        value={1}
                      >
                        {t('split')}
                      </Option>
                    </Select>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <strong>{t('start_date')} {t('end_date')}</strong>
                    <RangePicker
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                      defaultValue={
                        itemData.prices[0].promoStartDate
                          ? [dayjs(itemData.prices[0].promoStartDate, 'YYYY-MM-DD HH:mm'),
                            dayjs(itemData.prices[0].promoEndDate, 'YYYY-MM-DD HH:mm')
                          ] : null
                      }
                      onChange={(date, dateString) => {
                        const temp = JSON.parse(JSON.stringify(itemData));
                        const start = dateString[0];
                        const end = dateString[1];
                        temp.prices[0].promoStartDate = start;
                        temp.prices[0].promoEndDate = end;
                        setItemData(temp);
                      }}
                    />
                  </div>
                </Panel>
              </>
            )}
            {currentstate && currentstate.managesMultiplePrices && (
              <Panel header="Prices" key="5">
                <Row>
                  <Col
                    style={{
                      marginRight: '5px'
                    }}
                  >
                    <div>
                      <strong>Mun Tax %</strong>
                      <br />
                      <InputNumber
                        disabled={loading}
                        min={0}
                        onChange={(obj) => {
                          const temp = JSON.parse(JSON.stringify(itemData));
                          temp.munTaxPct = obj;
                          setItemData(temp);
                        }}
                        value={itemData.munTaxPct}
                      />
                    </div>
                  </Col>
                  <Col
                    style={{
                      marginLeft: '5px'
                    }}
                  >
                    <div>
                      <strong>State Tax %</strong>
                      <br />
                      <InputNumber
                        disabled={loading}
                        min={0}
                        onChange={(obj) => {
                          const temp = JSON.parse(JSON.stringify(itemData));
                          temp.stateTaxPct = obj;
                          setItemData(temp);
                        }}
                        value={itemData.stateTaxPct}
                      />
                    </div>
                  </Col>
                </Row>
                <Button
                  style={{
                    float: 'right',
                    margin: '10px 0'
                  }}
                  type="primary"
                  disabled={loading}
                  onClick={() => {
                    setShowEditItemPrice(true);
                    setSelectedItemPriceAtom({});
                  }}
                >
                  {t('add_new_price')}
                </Button>
                <Table
                  bordered
                  loading={loading}
                  pagination={false}
                  dataSource={itemData.prices}
                  columns={[
                    {
                      title: t('name'),
                      dataIndex: 'name',
                      align: 'center',
                      className: 'text-xs',
                      render: (text) => (
                        <span>{text}</span>
                      ),
                    },
                    {
                      title: t('price'),
                      dataIndex: 'activePrice',
                      align: 'center',
                      className: 'text-xs',
                      render: (text) => (
                        <span>${text}</span>
                      ),
                    },
                    {
                      title: '',
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => {
                            setShowEditItemPrice(true);
                            setSelectedItemPriceAtom(row);
                          }}
                        >
                          {t('edit')}
                        </Button>
                      ),
                    }
                  ]}
                />
              </Panel>
            )}
            <Panel header="Flags" key="4">
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>Visible</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.isVisible}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.isVisible = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('available')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.isAvailable}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.isAvailable = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('promoted')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.isPromoted}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.isPromoted = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('made_locally')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.datas[0].madeLocally}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].madeLocally = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('custom_department')} 1</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.customBooleanProperty01}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.customBooleanProperty01 = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('custom_department')} 2</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.customBooleanProperty02}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.customBooleanProperty02 = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('custom_department')} 3</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.customBooleanProperty03}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.customBooleanProperty03 = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('custom_department')} 4</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.customBooleanProperty04}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.customBooleanProperty04 = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('custom_department')} 5</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.customBooleanProperty05}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.customBooleanProperty05 = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('custom_department')} 6</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.customBooleanProperty06}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.customBooleanProperty06 = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('natural')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.datas[0].isAllNatural}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].isAllNatural = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('antioxidant')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.datas[0].isAntioxidant}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].isAntioxidant = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('gluten_free')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.datas[0].isGlutenFree}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].isGlutenFree = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('grass_fed')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.datas[0].isGrassFed}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].isGrassFed = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>Keto</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.datas[0].isKeto}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].isKeto = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('lactose_free')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.datas[0].isLactoseFree}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].isLactoseFree = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>Non-Gmo</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.datas[0].isNonGmo}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].isNonGmo = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('nut_free')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.datas[0].isNutFree}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].isNutFree = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('organic')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.datas[0].isOrganic}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].isOrganic = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('sugar_free')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.datas[0].isSugarFree}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].isSugarFree = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('vegan')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.datas[0].isVegan}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].isVegan = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ marginBottom: '10px' }}
              >
                <strong>{t('vegetarian')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.datas[0].isVegetarian}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.datas[0].isVegetarian = checked;
                    setItemData(temp);
                  }}
                />
              </div>
            </Panel>
          </Collapse>
          {currentstate && !currentstate.managesMultiplePrices &&
            otherBusinessItems.length > 0 && (
              <>
                <div
                  style={{ margin: '10px 0' }}
                >
                  <strong>{t('apply_to_multiple')}</strong>
                  <Switch
                    disabled={loading}
                    style={{ float: 'right' }}
                    checked={applyToMultipleStores}
                    onChange={(checked) => {
                      setSelectedBusinessItemIds([]);
                      setApplyToMultipleStores(checked);
                    }}
                  />
                </div>
                <div
                  hidden={!applyToMultipleStores}
                  style={{ marginBottom: '10px' }}
                >
                  <div className="mt-2 mb-1 space-x-2">
                    <strong>{t('stores')}</strong>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        setSelectedBusinessItemIds(_.map(otherBusinessItems, (b) => b.id));
                      }}
                    >
                      {t('select_all')}
                    </Button>
                  </div>
                  <Select
                    mode="multiple"
                    value={selectedBusinessItemIds}
                    style={{ width: '100%' }}
                    disabled={loading}
                    onChange={(value) => {
                      setSelectedBusinessItemIds(value);
                    }}
                  >
                    {otherBusinessItems && _.map(otherBusinessItems, (otherBusinessItem) => (
                      <Option
                        key={otherBusinessItem.id}
                        value={otherBusinessItem.id}
                      >
                        {otherBusinessItem.businessName}
                      </Option>
                    ))}
                  </Select>
                </div>
              </>
          )}
          <Button
            type="primary"
            loading={loading}
            onClick={() => { updateItemClick(); }}
            style={{
              float: 'right',
              marginTop: '10px'
            }}
          >
            {t('update')}
          </Button>
        </div>
      )}
    </Drawer>
  );
}

export default EditBusinessItemDrawer;
