import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Button, Divider, Image, Row, Table, Switch, Typography } from 'antd';
import { useRecoilValue, useRecoilState, useRecoilValueLoadable } from 'recoil';
import { useMount } from 'react-use';
import {
  businessAtom,
  reloadPaymentOptionsAtom,
  selectedPaymentAtom,
  paymentEditAtom,
  paymentCreateAtom,
  profileAtom,
} from '../../../../atoms/Atoms';
import EditPayments from './EditPayments';
import CreatePayment from './CreatePayment';
import CreateDefaultPaymentOptions from './CreateDefaultPaymentOptions';
import api from '../../../../api/api';

function PaymentOptions() {
  const business = useRecoilValue(businessAtom);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(null);
  const [reload, setReload] = useRecoilState(reloadPaymentOptionsAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const [paymentEdit, setPaymentEdit] = useRecoilState(paymentEditAtom);
  const [paymentCreate, setPaymentCreate] = useRecoilState(paymentCreateAtom);
  const isAdmin =
    profile.contents && profile.contents.roles.includes('SuperAdmin');
  const isPanza =
    profile.contents && profile.contents.email.includes('gopanza.com');
  const [selectedPayment, setSelectedPayment] = useRecoilState(selectedPaymentAtom);
  const [paymentOptions, setPaymentOptions] = useState(null);
  const { t } = useTranslation();

  function getPaymentOptions() {
    setLoading(true);
    api
      .get(`admin/paymentOptions/${business.locations[0].id}`)
      .then((response) => {
        setLoading(false);
        setPaymentOptions(response.data.data.results);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  useMount(() => {
    getPaymentOptions();
  });

  useEffect(() => {
    getPaymentOptions();
  }, [reload, business]);

  return (
    paymentOptions && (
      <>
        <div className="ml-2 my-4 font-normal text-sm">
          {t('payment_options_description')}
        </div>
        <Divider />
        <Table
          pagination={false}
          size="small"
          bordered
          rowKey="id"
          loading={loading}
          columns={[
            {
              title: t('payment'),
              dataIndex: 'imageUrl',
              key: 'imageUrl',
              align: 'center',
              render: (media) => (
                <>
                  <Image
                    alt="logo"
                    width={60}
                    src={media}
                    className="cursor-pointer"
                  />
                </>
              ),
            },
            {
              title: t('name'),
              dataIndex: 'name',
              key: 'name',
              align: 'center',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('active'),
              key: 'status',
              align: 'center',
              render: (text, record) => (
                <Switch
                  size="small"
                  loading={loading && record.id === key}
                  defaultChecked={record.isActive}
                  disabled={!isAdmin}
                  onChange={(checked) => {
                    setKey(record.id);
                    setLoading(true);
                    const data = JSON.parse(JSON.stringify(record));
                    data.isActive = checked;
                    api
                      .put(
                        `businesses/updatepaymentoptions/${record.id}`,
                        data
                      )
                      .then((response) => {
                        setLoading(false);
                        setKey(null);
                      })
                      .catch((error) => {
                        setLoading(false);
                        setKey(null);
                      });
                  }}
                />
              ),
            },
            {
              title: t('edit'),
              key: 'edit',
              align: 'center',
              className: 'text-xs',
              render: (text, record) => (
                <Button
                  size="small"
                  type="primary"
                  onClick={() => { setPaymentEdit(true); setSelectedPayment(record); }}
                >
                  {t('edit')}
                </Button>
              ),
            },
          ]}
          dataSource={paymentOptions}
          expandable={{
            expandedRowRender: (record) => (
              <div className="p-4">
                <Row>
                  <Col span={10}>
                    <span>
                      <Switch
                        disabled
                        size="small"
                        loading={loading && record.id === key}
                        defaultChecked={record.allowNonFoodProducts}
                      />
                    </span>
                    <Typography.Text> {t('allow_non_food')} </Typography.Text>
                  </Col>
                  <Col span={8} offset={2}>
                    <span>
                      <Switch
                        size="small"
                        disabled
                        loading={loading && record.id === key}
                        defaultChecked={record.allowTransfers}
                      />
                    </span>
                    <Typography.Text> {t('allow_tranfers')} </Typography.Text>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={10}>
                    <span>
                      <Switch
                        size="small"
                        disabled
                        loading={loading && record.id === key}
                        defaultChecked={record.allowedInDeliService}
                      />
                    </span>
                    <Typography.Text> {t('allow_deli_service')} </Typography.Text>
                  </Col>
                  <Col span={8} offset={2}>
                    <span>
                      <Switch
                        disabled
                        size="small"
                        loading={loading && record.id === key}
                        defaultChecked={record.allowedInRegularService}
                      />
                    </span>
                    <Typography.Text>
                      {' '}
                      {t('allow_regular_service')}{' '}
                    </Typography.Text>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={10}>
                    <span>
                      <Switch
                        disabled
                        size="small"
                        loading={loading && record.id === key}
                        defaultChecked={record.allowedInSelfCheckoutService}
                      />
                    </span>
                    <Typography.Text>
                      {' '}
                      {t('allow_selfcheckout')}{' '}
                    </Typography.Text>
                  </Col>
                  <Col span={8} offset={2}>
                    <span>
                      <Switch
                        disabled
                        size="small"
                        loading={loading && record.id === key}
                        defaultChecked={record.allowsServiceFeePayment}
                      />
                    </span>
                    <Typography.Text>
                      {' '}
                      {t('allow_service_fee')}{' '}
                    </Typography.Text>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={10}>
                    <span>
                      <Switch
                        disabled
                        size="small"
                        loading={loading && record.id === key}
                        defaultChecked={record.hasFreeDelivery}
                      />
                    </span>
                    <Typography.Text> {t('free_delivery')} </Typography.Text>
                  </Col>
                  <Col span={8} offset={2}>
                    <span>
                      <Switch
                        size="small"
                        disabled
                        loading={loading && record.id === key}
                        defaultChecked={record.hasFreePickup}
                      />
                    </span>
                    <Typography.Text> {t('free_pickup')} </Typography.Text>
                  </Col>
                </Row>
                <br />
                <Row className={record.name === 'ATH Móvil' ? '' : 'hidden'}>
                  <Col span={20} className="mb-2">
                    <b className="mr-2">{t('public_token')}:</b>
                    <Typography.Text copyable>
                      {record.publicToken ? record.publicToken : 'n/a'}
                    </Typography.Text>
                  </Col>
                  <Col span={20}>
                    <b className="mr-2">{t('private_token')}:</b>
                    <span>
                      <Typography.Text copyable>
                        {record.privateToken ? record.privateToken : 'n/a'}
                      </Typography.Text>
                    </span>
                  </Col>
                </Row>
              </div>
            ),
            rowExpandable: (record) => record,
          }}
        />
        {paymentEdit ? <EditPayments open={paymentEdit} /> : null}
        {paymentCreate ? <CreatePayment open={paymentCreate} /> : null}
        <CreateDefaultPaymentOptions />
      </>
    )
  );
}
export default PaymentOptions;
