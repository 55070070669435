import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Input,
  Modal,
  Row,
  Col,
  Button,
  Switch,
  Avatar,
  Tooltip,
  notification,
  Divider,
  Select
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  showAddItemAtom,
  setSelectedMasterItemAtom
} from '../../../../atoms/Atoms';
import {
  businessesSelector
} from '../../../../atoms/Selectors';
import api from '../../../../api/api';

function CreateItemModal() {
  const { Option } = Select;
  const businessesSelect = useRecoilValueLoadable(businessesSelector);
  const setSelectedMasterItem = useSetRecoilState(setSelectedMasterItemAtom);
  const [showAddItem, setShowAddItem] = useRecoilState(showAddItemAtom);
  const [loading, setLoading] = useState(false);
  const [itemGtin, setItemGtin] = useState('');
  const [itemBrand, setItemBrand] = useState('');
  const [itemName, setItemName] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [itemPackageSensitive, setItemPackageSensitive] = useState(false);
  const [itemEbt, setItemEbt] = useState(false);
  const [itemIdRequired, setItemIdRequired] = useState(false);
  const [itemTemperatureSensitive, setItemTemperatureSensitive] = useState(false);
  const [itemAlcoholic, setItemAlcoholic] = useState(false);
  const [itemPerishable, setItemPerishable] = useState(false);

  const [applyToMultipleStores, setApplyToMultipleStores] = useState(false);
  const [selectedBusinessIds, setSelectedBusinessIds] = useState([]);

  const { t } = useTranslation();

  const showErrorMessage = function (errorMessage) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {errorMessage}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };
  const closeModel = function () {
    if (!loading) {
      setShowAddItem(false);
    }
  };
  const checkItemValid = function () {
    if (itemGtin.length === 0) {
      showErrorMessage('Must enter a GTIN');
      return false;
    }
    if (itemName.length === 0) {
      showErrorMessage('Must enter a Name');
      return false;
    }
    // if (itemDescription.length === 0) {
    //   showErrorMessage('Must enter a Description');
    //   return false;
    // }
    return true;
  };
  const onChangeItemGtin = function (obj) {
    setItemGtin(obj.target.value);
  };
  const onChangeItemBrand = function (obj) {
    setItemBrand(obj.target.value);
  };
  const onChangeItemName = function (obj) {
    setItemName(obj.target.value);
  };
  const onChangeItemDescription = function (obj) {
    setItemDescription(obj.target.value);
  };

  useEffect(() => {
    if (showAddItem) {
      setItemGtin('');
      setItemBrand('');
      setItemName('');
      setItemDescription('');
      setItemPackageSensitive(false);
      setItemEbt(false);
      setItemIdRequired(false);
      setItemTemperatureSensitive(false);
      setItemAlcoholic(false);
      setItemPerishable(false);
    }
  }, [showAddItem]);

  const createClick = function () {
    const isValid = checkItemValid();
    if (isValid) {
      setLoading(true);
      const data = {
        Gtin: itemGtin,
        Brand: itemBrand,
        Name: itemName,
        Description: itemDescription,
        IsPackagingSensitive: itemPackageSensitive,
        IsTemperatureSensitive: itemTemperatureSensitive,
        IsAlcoholicBeverage: itemAlcoholic,
        IsPerishable: itemPerishable,
        IsEbt: itemEbt,
        IdRequired: itemIdRequired,
        BusinessIds: selectedBusinessIds
      };
      api.post(
        'businesses/catalog/new',
        data
      )
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            setShowAddItem(false);
            setSelectedMasterItem(response.data.data);
          } else {
            showErrorMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  return (
    <>
      {showAddItem && (
        <Modal
          closable="true"
          centered="true"
          open={showAddItem}
          onCancel={() => closeModel()}
          footer={[
            <Button
              key="createButton"
              disabled={loading}
              onClick={() => createClick()}
            >
              {t('create')}
            </Button>,
            <Button
              key="back"
              disabled={loading}
              onClick={() => closeModel()}
            >
              {t('close')}
            </Button>
          ]}
        >
          <PageHeader
            title={t('create_item')}
          />
          <div className="flex space-x-2">
            <div className="space-y-2 w-1/2">
              <Input
                placeholder="GTIN"
                onChange={(obj) => onChangeItemGtin(obj)}
                value={itemGtin}
              />
              <br />
              <Input
                placeholder={t('brand')}
                onChange={(obj) => onChangeItemBrand(obj)}
                value={itemBrand}
              />
              <br />
              <Input
                placeholder={t('name')}
                onChange={(obj) => onChangeItemName(obj)}
                value={itemName}
              />
              <br />
              <Input
                placeholder={t('description')}
                onChange={(obj) => onChangeItemDescription(obj)}
                value={itemDescription}
              />
            </div>
            <div className="w-1/2 space-y-2">
              <div>
                <small>{t('package_sensitive')} </small>
                <Switch
                  className="float-right"
                  checked={itemPackageSensitive}
                  onChange={(checked) => {
                    setItemPackageSensitive(checked);
                  }}
                />
              </div>
              <div>
                <small>EBT </small>
                <Switch
                  className="float-right"
                  checked={itemEbt}
                  onChange={(checked) => {
                    setItemEbt(checked);
                  }}
                />
              </div>
              <div>
                <small>{t('id_required')}</small>
                <Switch
                  className="float-right"
                  checked={itemIdRequired}
                  onChange={(checked) => {
                    setItemIdRequired(checked);
                  }}
                />
              </div>
              <div>
                <small>{t('temp_sensitive')}</small>
                <Switch
                  className="float-right"
                  checked={itemTemperatureSensitive}
                  onChange={(checked) => {
                    setItemTemperatureSensitive(checked);
                  }}
                />
              </div>
              <div>
                <small>{t('alcoholic_beverage')}</small>
                <Switch
                  className="float-right"
                  checked={itemAlcoholic}
                  onChange={(checked) => {
                    setItemAlcoholic(checked);
                  }}
                />
              </div>
              <div>
                <small>{t('perishable')}</small>
                <Switch
                  className="float-right"
                  checked={itemPerishable}
                  onChange={(checked) => {
                    setItemPerishable(checked);
                  }}
                />
              </div>
            </div>
          </div>
          <Divider />
          <div className="mt-4">
            <strong>{t('assign_to_multiple_stores')}</strong>
            <Switch
              className="ml-2"
              disabled={loading}
              checked={applyToMultipleStores}
              onChange={(checked) => {
                setSelectedBusinessIds([]);
                setApplyToMultipleStores(checked);
              }}
            />
          </div>
          <div
            hidden={!applyToMultipleStores}
          >
            <div className="mt-2 mb-1 space-x-2">
              <strong>{t('stores')}</strong>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setSelectedBusinessIds(_.map(businessesSelect.contents.data.data.results, (b) => b.id));
                }}
              >
                {t('select_all')}
              </Button>
            </div>
            <Select
              mode="multiple"
              value={selectedBusinessIds}
              style={{ width: '100%' }}
              disabled={loading}
              onChange={(value) => {
                setSelectedBusinessIds(value);
              }}
            >
              {businessesSelect.state === 'hasValue' && _.map(businessesSelect.contents.data.data.results, (business) => (
                <Option
                  key={business.id}
                  value={business.id}
                >
                  {business.name}
                </Option>
              ))}
            </Select>
          </div>
        </Modal>
      )}
    </>
  );
}

export default CreateItemModal;
