import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, CloseCircleOutlined, CopyOutlined, DollarCircleOutlined, PushpinOutlined } from '@ant-design/icons';
import {
  Button,
  Spin,
  Divider,
  Modal,
  Image,
  Card
} from 'antd';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import _ from 'lodash';
import api from '../../api/api';
import { renderShortDate, thousand } from '../utils/functions';
import { timezoneAtom } from '../../atoms/Atoms';

function UserPurchaseProfile({ userId, businessId, lifetime, start, end }) {
  const { t } = useTranslation();
  const timezone = useRecoilValue(timezoneAtom);
  const [profile, setProfile] = useState(null);
  const [products, setProducts] = useState(null);
  const [loyalty, setLoyalty] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingLoyalty, setLoadingLoyalty] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const topRowGridStyle = { width: '15em', height: '100%', textAlign: 'center' };
  const bottomRowGridStyle = { width: '50%', height: '100%', textAlign: 'center' };

  function loadProfile() {
    setLoadingProducts(true);
    setLoadingProfile(true);
    setLoadingLoyalty(true);
    let bId = '';
    if (businessId !== undefined && businessId !== null) {
      bId = businessId;
    }
    api
      .post(`analytics/v2/user/topproducts/${userId}/${bId}`, {
        Lifetime: lifetime,
        StartDate: start,
        EndDate: end
      })
      .then((response) => {
        setProducts(response.data.data);
        setLoadingProducts(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingProducts(false);
      });

    api
      .get(`users/customerinfo/${userId}`)
      .then((response) => {
        setProfile(response.data.data);
        setLoadingProfile(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingProfile(false);
      });
    api
      .get(`loyalty/history/${userId}`)
      .then((response) => {
        setLoyalty(response.data.data);
        console.log(response.data.data);
        setLoadingLoyalty(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingLoyalty(false);
      });
  }

  useEffect(() => {
    if (modalOpen) {
      loadProfile();
    } else {
      setProfile(null);
      setProducts([]);
    }
  }, [modalOpen]);

  return (
    <>
      <Button
        disabled={loadingProducts || loadingProfile}
        shape="circle"
        size="small"
        loading={loadingProducts || loadingProfile}
        icon={<DollarCircleOutlined />}
        onClick={() => setModalOpen(true)}
      />
      <Modal
        open={modalOpen}
        width="90vw"
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button key="close" onClick={() => setModalOpen(false)}>
            {t('close')}
          </Button>
        ]}
      >
        <div className="w-full space-y-10 mt-8">
          <Spin
            tip={t('loading_profile')}
            spinning={loadingProfile}
          >
            <div className="shadow-md p-4">
              <div>{t('purchase_profile')}</div>
              <Divider className="p-0" />
              <div className="text-xs">
                {t('orders')}:{' '}
                <span className="font-bold">
                  {profile?.orderCount}
                </span>
              </div>
              <div className="text-xs">
                {t('avg_days_between')}:{' '}
                <span className="font-bold">
                  {profile?.averageDaysBetweenOrders}
                </span>
              </div>
              <div className="text-xs">
                {t('avg_purchase')}:{' '}
                <span className="font-bold">
                  ${thousand(profile?.averagePurchase)}
                </span>
              </div>
              <div className="text-xs">
                {t('days_since_last_purchase')}:{' '}
                <span className="font-bold">
                  {profile?.daysSinceLastPurchase}
                </span>
              </div>
              <div className="text-xs">
                {t('purchase_total')}:{' '}
                <span className="font-bold">
                  ${thousand(profile?.purchaseTotal)}
                </span>
              </div>
              <div className="text-xs">
                {t('delivery_count')}:{' '}
                <span className="font-bold">
                  {profile?.deliveryCount}
                </span>
              </div>
              <div className="text-xs">
                {t('pickup_count')}:{' '}
                <span className="font-bold">
                  {profile?.pickupCount}
                </span>
              </div>
            </div>
          </Spin>

          <Spin
            tip={t('loading_products')}
            spinning={loadingProducts}
          >
            <div className="shadow-md p-4">
              <div className="my-4">{t('top_products')}</div>
              <div className="flex overflow-y-auto">
                {_.map(products, (item) => (
                  <Card
                    type="inner"
                    className="rounded-xl mr-4"
                    hoverable
                  >
                    <Card.Grid
                      hoverable={false}
                      style={topRowGridStyle}
                    >
                      <div>
                        <Image
                          alt={`${item?.brand} ${item?.name} ${item?.description}`}
                          width={60}
                          src={item?.imageUrl}
                          className="cursor-pointer"
                        />
                        <p className="text-xs">{`${item?.brand} ${item?.name} ${item?.description}`}</p>
                      </div>
                    </Card.Grid>
                    <Card.Grid
                      hoverable={false}
                      style={bottomRowGridStyle}
                    >
                      <span className="text-xs font-bold">{t('count')}:</span>
                      <span> {thousand(item?.orderCount)}</span>
                    </Card.Grid>
                    <Card.Grid
                      hoverable={false}
                      style={bottomRowGridStyle}
                    >
                      <span className="text-xs font-bold">{t('sale')}:</span>
                      <span> ${thousand(item?.sales)}</span>
                    </Card.Grid>
                  </Card>
                ))}
              </div>
            </div>
          </Spin>

          <Spin
            tip={t('loading_loyalty')}
            spinning={loadingLoyalty}
          >
            <div className="shadow-md p-4">
              <div className="my-4">{t('loyalty')}: {thousand(loyalty?.points)} {t('points')}</div>
              <div className="overflow-y-auto">
                <div>
                  <Line
                    className="mb-7"
                    height={50}
                    options={{
                      plugins: {
                        legend: {
                          display: false
                        }
                      }
                    }}
                    data={{
                      labels: _.map(loyalty?.balanceHistory, (s) => renderShortDate(s.date, timezone)),
                      datasets: [
                        {
                          label: t('loyalty_points'),
                          fill: true,
                          backgroundColor: 'rgba(53, 162, 253, 0.2)',
                          borderWidth: 5,
                          data: _.map(
                            loyalty?.balanceHistory,
                            (x) => ({
                              x: renderShortDate(x.date, timezone),
                              y: thousand(x.points),
                            })
                          ),
                        },
                      ],
                    }}
                  />
                </div>
                <Divider />
                <div className="divide-y divide-gray-200">
                  {_.map(loyalty?.history, (l) => (
                    <div className="flex items-center justify-between space-x-4 p-4">
                      <div>
                        <div className="text-xs font-semibold tracking-tight text-gray-800">
                          {l.confirmationCode?.length > 0 ? l.confirmationCode : l.notes}
                        </div>
                        <div className="text-xs">
                          <span>{t('points')}: </span>
                          <span className="text-gray-800 font-medium">
                            {l.points.toLocaleString()}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="text-xs">
                          {moment(l.createdAt).format('MMM DD YYYY - hh:mm A')}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </Modal>
    </>
  );
}

export default UserPurchaseProfile;
