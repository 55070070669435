import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import {
  Badge,
  Button,
  Table,
  Typography,
  Space,
  Select,
  Popover,
  DatePicker,
  Divider,
  Card,
  Switch,
  notification,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import Avatar from 'antd/lib/avatar/avatar';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-layout';
import {
  CloudDownloadOutlined,
  LoadingOutlined,
  ReloadOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { businessAtom, timezoneAtom, profileAtom, selectedTerminalDataAtom } from '../../../../atoms/Atoms';
import { mapOrderType, mapPaymentType, toCurrency, thousand, renderShortDate } from '../../../utils/functions';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';

function PendingInStoreSalesFinance() {
  const bottomRowGridStyle = { width: '50%', textAlign: 'center' };
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const { Option } = Select;
  const [reload, setReload] = useState(false);
  const { saveAsCsv } = useJsonToCsv();
  const business = useRecoilValue(businessAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isStoreManager =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('StoreAdmin') ||
        profile?.contents?.roles?.includes('Owner')));
  const canViewFinance =
    isAdmin ||
    isStoreManager ||
    (profile.contents &&
      (profile.contents.roles.includes('Finance') ||
        profile.contents.roles.includes('TopAnalytics')));
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [includeOrders, setIncludeOrders] = useState(false);
  const [loadingPaymentTenders, setLoadingPaymentTenders] = useState(null);
  const [selectedPaymentTenders, setSelectedPaymentTenders] = useState(['']);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD')
  });
  const [paymentTenders, setPaymentTenders] = useState([]);
  const setSelectedTerminalData = useSetRecoilState(selectedTerminalDataAtom);

  const width = window.innerWidth;
  const allColumns = [{
    title: t('day'),
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('count'),
    dataIndex: 'count',
    key: 'count',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('total'),
    dataIndex: 'orderTotal',
    key: 'orderTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('products'),
    dataIndex: 'productsTotal',
    key: 'productsTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('municipal_tax'),
    dataIndex: 'municipalTax',
    key: 'municipalTax',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('state_tax'),
    dataIndex: 'stateTax',
    key: 'stateTax',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('tax_total'),
    dataIndex: 'taxTotal',
    key: 'taxTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('discount'),
    dataIndex: 'discountTotal',
    key: 'discountTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('refund'),
    dataIndex: 'returnTotal',
    key: 'returnTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  }];
  const restrictedColumns = [{
    title: t('day'),
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('count'),
    dataIndex: 'count',
    key: 'count',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('total'),
    dataIndex: 'orderTotal',
    key: 'orderTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  }];

  function updateOrder(data) {
    setLoading(true);
    api
      .put('orders', data)
      .then((response) => {
        setLoading(false);
        if (response && response.data.error) {
          console.error('Error Message: ', response.data.error);
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={<p className="text-sm">{response.data.error}</p>}
              />
            ),
          });
        } else if (response.data.success && response.data.message) {
          notification.open({
            message: 'Success!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={<p className="text-sm">{response.data.message}</p>}
              />
            ),
          });
        }
        setReload(!reload);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  const allExpandableColumns = [
    {
      title: t('business'),
      dataIndex: 'businessName',
      key: 'businessName',
      align: 'center',
      className: 'text-xs',
      render: (text) => text,
    },
    {
      title: t('order'),
      dataIndex: 'confirmationCode',
      key: 'code',
      align: 'center',
      className: 'text-xs',
      render: (text) => (
        <Typography.Text copyable>{text}</Typography.Text>
      ),
    },
    {
      title: t('order_total'),
      dataIndex: 'orderTotal',
      key: 'orderTotal',
      align: 'center',
      className: 'text-xs font-medium',
      render: (text) => (
        <span>
          $
          {text
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
      ),
    },
    {
      title: t('payment'),
      dataIndex: 'paymentTender',
      key: 'paymentTender',
      align: 'center',
      className: 'text-xs',
      render: (text) => text,
    },
    {
      title: t('lane'),
      dataIndex: 'posLaneTag',
      key: 'posLaneTag',
      align: 'center',
      className: 'text-xs',
      render: (text) => t(text),
    },
    {
      title: t('date'),
      dataIndex: 'dispatchDateTime',
      key: 'dispatchDateTime',
      align: 'center',
      className: 'text-xs',
      render: (text) => (
        <span>
          {text && moment(`${text}+0000`).tz(timezone).format('MMM DD, YYYY - h:mm:ss a')}
        </span>
      ),
    },
    {
      title: t('clerk'),
      dataIndex: 'dispatcher',
      key: 'dispatcher',
      align: 'right',
      className: 'text-xs',
      render: (text) => <span>{text}</span>,
    },
    {
      title: '',
      align: 'center',
      className: 'text-xs',
      render: (text, row) => (
        <Space>
          <Popover
            title={t('details')}
            placement="left"
            content={
              <div>
                <div className="text-xs">
                  {t('code')}:{' '}
                  <span className="font-bold">
                    {row.confirmationCode}
                  </span>
                </div>
                <div className="text-xs">
                  {t('customer')}:{' '}
                  <span className="font-bold">
                    {row.customer}
                  </span>
                </div>
                {row?.terminalData && row?.terminalData?.length > 0 && (
                  <>
                    <Divider />
                    <div className="text-xs">
                      {t('ivu_control')}:{' '}
                      <span className="font-bold">
                        {row?.terminalData[0].ivuControl}
                      </span>
                    </div>
                    <div className="text-xs">
                      {t('auth_code')}:{' '}
                      <span className="font-bold">
                        {row?.terminalData[0].authorizationCode}
                      </span>
                    </div>
                    <div className="text-xs">
                      {t('terminal_id')}:{' '}
                      <span className="font-bold">
                        {row?.terminalData[0].terminalId}
                      </span>
                    </div>
                    <div className="text-xs">
                      {t('last4')}:{' '}
                      <span className="font-bold">
                        {row?.terminalData[0].panCardNumber === null ? 'N/A' : row?.terminalData[0].panCardNumber.slice(-4)}
                      </span>
                    </div>
                    <div className="text-xs">
                      {t('processed')}:{' '}
                      <span className="font-bold">
                        {moment(`${row.terminalData[0].createdAt}+0000`)
                          .tz(timezone)
                          .format('MMM DD, YYYY - h:mm a')}
                      </span>
                    </div>
                  </>
                )}
                <Divider />
                <div className="text-xs">
                  Subtotal: $
                  <span className="font-bold">
                    {row.productsTotal
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
                <div className="text-xs">
                  {t('tax_total')}: $
                  <span className="font-bold">
                    {row.taxTotal
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
                <div className="text-xs">
                  {t('total')}: $
                  <span className="font-bold">
                    {row.orderTotal
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
                <br />
                <div className="text-xs">
                  {t('returns')}: $
                  <span className="font-bold">
                    {row.returnTotal
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
                <div className="text-xs">
                  {t('discount')}: $
                  <span className="font-bold">
                    {row.discountAmount
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>{' '}
              </div>
            }
          >
            <Button
              shape="circle"
              size="small"
              icon={<SearchOutlined />}
            />
          </Popover>
        </Space>
      ),
    },
    {
      title: t('actions'),
      align: 'center',
      className: 'text-xs',
      render: (text, row) => (
        <div>
          <Button
            type="primary"
            className="mx-1"
            disabled={row?.terminalData?.length === 0}
            size="small"
            onClick={() => setSelectedTerminalData(row?.terminalData)}
          >
            {t('receipt')}
          </Button>
          <Button
            type="primary"
            className="mx-1"
            icon={loading && <LoadingOutlined />}
            danger
            disabled={loading}
            hidden={row?.terminalData?.length === 1}
            size="small"
            onClick={() => {
              if (row?.terminalData?.length === 0) {
                const data = {
                  OrderId: row.id,
                  ConfirmationCode: row.confirmationCode,
                  Status: 8,
                  ApproverId: profile.contents.id,
                  Reason: 7,
                  SilentMode: true,
                };
                updateOrder(data);
              }
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            type="primary"
            className="mx-1"
            hidden={row?.terminalData?.length === 0}
            size="small"
            style={{ backgroundColor: 'green' }}
            onClick={() => {
              if (row?.terminalData?.length === 1) {
                const data = {
                  OrderId: row.id,
                  ConfirmationCode: row.confirmationCode,
                  Status: 6,
                  ApproverId: profile.contents.id,
                  Reason: 7,
                  SilentMode: true,
                };
                updateOrder(data);
              }
            }}
          >
            {t('approve')}
          </Button>
        </div>
      ),
    },
  ];
  const restrictedExpandableColumns = [
    {
      title: t('business'),
      dataIndex: 'businessName',
      key: 'businessName',
      align: 'center',
      className: 'text-xs',
      render: (text) => text,
    },
    {
      title: t('order'),
      dataIndex: 'confirmationCode',
      key: 'code',
      align: 'center',
      className: 'text-xs',
      render: (text) => (
        <Typography.Text copyable>{text}</Typography.Text>
      ),
    },
    {
      title: t('order_total'),
      dataIndex: 'orderTotal',
      key: 'orderTotal',
      align: 'center',
      className: 'text-xs font-medium',
      render: (text) => (
        <span>
          $
          {text
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
      ),
    },
    {
      title: t('payment'),
      dataIndex: 'paymentTender',
      key: 'paymentTender',
      align: 'center',
      className: 'text-xs',
      render: (text) => text,
    },
    {
      title: t('type'),
      dataIndex: 'orderType',
      key: 'orderType',
      align: 'center',
      className: 'text-xs',
      render: (text) => t(mapOrderType(text)),
    },
    {
      title: t('date'),
      dataIndex: 'dispatchDateTime',
      key: 'dispatchDateTime',
      align: 'center',
      className: 'text-xs',
      render: (text) => (
        <span>
          {moment(`${text}+0000`)
            .tz(timezone)
            .format('MMM DD, YYYY - h:mm:ss a')}
        </span>
      ),
    },
    {
      title: t('clerk'),
      dataIndex: 'dispatcher',
      key: 'dispatcher',
      align: 'right',
      className: 'text-xs',
      render: (text) => <span>{text}</span>,
    }
  ];

  const allListFields = {
    date: t('day'),
    count: t('count'),
    orderTotal: t('total'),
    // productsTotal: t('products'),
    municipalTax: t('municipal_tax'),
    stateTax: t('state_tax'),
    taxTotal: t('tax_total'),
    processorFee: t('processor_fee'),
    discountTotal: t('discount'),
    returnTotal: t('refund'),
  };
  const restrictedListFields = {
    date: t('day'),
    count: t('count'),
    orderTotal: t('total'),
  };

  const allTransactionFields = {
    date: t('day'),
    confirmationCode: t('order'),
    orderType: t('order_type'),
    customer: t('customer'),
    paymentType: t('payment_type'),
    orderTotal: t('order_total'),
    productsTotal: t('products_total'),
    municipalTax: t('municipal_tax'),
    stateTax: t('state_tax'),
    taxTotal: t('tax_total'),
    processorFee: t('processor_fee'),
    discountAmount: t('discount'),
    returnTotal: t('refund'),
    paymentTender: t('payment_tender'),
    authorizationCode: t('authorization_code'),
  };
  const restrictedTransactionFields = {
    confirmationCode: t('confirmation_code'),
    orderTotal: t('order_total'),
    paymentType: t('payment_type'),
    paymentTender: t('payment_tender'),
    orderType: t('order_type'),
    date: t('day')
  };

  function mapData(data) {
    const map = _.map(data, (p, index) => ({
      key: index,
      count: p.orderCount,
      datetime: p.date,
      date: moment(p.date).format('dddd MMM Do, YYYY'),
      orderTotal: p.orderTotal,
      productsTotal: p.productsTotal,
      stateTax: p.stateTax,
      municipalTax: p.municipalTax,
      taxTotal: p.taxTotal,
      processorFee: p.processorFee,
      processingFee: p.processingFee,
      storeTotal: p.storeTotal,
      transferTotal: p.transferTotal,
      discountTotal: p.discountTotal,
      returnTotal: p.returnTotal,
      paymentType: p.paymentType,
      authTotal: p.authTotal,
      athMovilDeposit: p.authTotal - p.processorFee,
      dateFormatted: moment(p.date).format('YYYY-MM-DD'),
      referenceOrder: p.referenceOrder,
      orders: _.map(p.orders, (v) => ({
        key: v.id,
        ...v,
      })),
    }));

    const sort = map.slice(0).sort((a, b) =>
      a.dateFormatted.localeCompare(b.dateFormatted));
    return sort;
  }

  function getData() {
    setLoading(true);

    const data = {
      StartDate: dayjs(dateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(dateRange.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      BusinessId: business?.id,
      PaymentTenders: _.filter(selectedPaymentTenders, (p) => p !== ''),
      IncludeOrderSummary: includeOrders,
      SearchType: 1
    };
    api
      .post(
        'analytics/reporting/instore/sales/search',
        data
      )
      .then((response) => {
        setLoading(false);
        setReportData({
          ...response.data.data,
          sales: mapData(response.data.data.sales)
        });
        // console.log(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    getData();
  }, [reload]);

  function getPaymentTenders() {
    setLoadingPaymentTenders(true);
    api
      .get('analytics/reporting/sales/paymenttenders')
      .then((response) => {
        setLoadingPaymentTenders(false);
        const mappedTenders = _.map(response.data.data, (p, index) => ({
          type: p.toUpperCase(),
          value: p
        }));
        setPaymentTenders(mappedTenders);
      })
      .catch((error) => {
        console.error(error);
        setLoadingPaymentTenders(false);
      });
  }

  useMount(() => {
    getPaymentTenders();
    getData();
  });

  useEffect(() => {
    getData();
  }, [dateRange, business, selectedPaymentTenders, includeOrders]);

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        extra={
          [
            <RangePicker
              format="YYYY-MM-DD"
              value={[
                dayjs(dateRange?.start),
                dayjs(dateRange?.end)
              ]}
              presets={[
                {
                  label: t('today'),
                  value: () => [moment(), moment()]
                },
                {
                  label: t('current_week'),
                  value: () => [moment().startOf('week'), moment().endOf('week')]
                },
                {
                  label: t('last_week'),
                  value: () => [moment().add(-1, 'week').startOf('week'), moment().add(-1, 'week').endOf('week')]
                },
                {
                  label: t('current_month'),
                  value: () => [moment().startOf('month'), moment().endOf('month')]
                },
                {
                  label: t('past_month'),
                  value: () => [moment().add(-1, 'months').startOf('month'), moment().add(-1, 'months').endOf('month')]
                },
                {
                  label: t('year_to_date'),
                  value: () => [moment().startOf('year'), moment()]
                },
              ]}
              onChange={(date, dateString) => {
                setDateRange({
                  start: dateString[0],
                  end: dateString[1]
                });
              }}
            />,
            <div>
              <br className={width < 1024 ? null : 'hidden'} />
              <Select
                mode="multiple"
                listHeight={500}
                loading={loadingPaymentTenders}
                disabled={loadingPaymentTenders}
                style={{ width: 150 }}
                onChange={(value) => {
                  if (value[value.length - 1] === '') {
                    setSelectedPaymentTenders(['']);
                  } else {
                    const check = _.filter(value, (p) => p !== '');
                    if (value.length === 0) {
                      setSelectedPaymentTenders(['']);
                    } else if (check.length > 0) {
                      setSelectedPaymentTenders(check);
                    } else {
                      setSelectedPaymentTenders(value);
                    }
                  }
                }}
                value={selectedPaymentTenders}
              >
                <Option key="all" value="">{t('all')}</Option>
                {_.map(paymentTenders, (p) => (
                  <Option
                    key={p.value}
                    value={p.value}
                    className="text-xs flex items-center"
                  >
                    {p.type}
                  </Option>
                ))}
              </Select>
            </div>,
            <div
              className="flex space-x-1 border p-1"
            >
              <br className={width < 1024 ? null : 'hidden'} />
              <p>{t('include_orders')}</p>
              <Switch
                disabled={loading}
                className="float-right"
                checked={includeOrders}
                onChange={(checked) => {
                  setIncludeOrders(checked);
                }}
              />
            </div>,
            <div className="space-x-2">
              <br className={width < 1024 ? null : 'hidden'} />
              <Button
                type="primary"
                size="small"
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  const data = _.map(reportData?.sales, (p) => ({
                    date: p.date,
                    count: p.count,
                    orderTotal: p.orderTotal,
                    // productsTotal: p.productsTotal,
                    municipalTax: p.municipalTax,
                    stateTax: p.stateTax,
                    taxTotal: p.taxTotal,
                    processorFee: p.processorFee,
                    deliveryFee: p.deliveryFee,
                    pickupFee: p.pickupFee,
                    discountTotal: p.discountTotal,
                    returnTotal: p.returnTotal,
                    transferTotal: p.transferTotal,
                  }));
                  saveAsCsv({
                    data,
                    fields: canViewFinance ? allListFields : restrictedListFields,
                    filename: `reportData_pending_${dateRange.start}_${dateRange.end}`,
                  });
                }}
              >
                {t('export_list')}
              </Button>
              <Button
                type="primary"
                size="small"
                icon={<ReloadOutlined />}
                onClick={() => getData()}
              >
                {t('refresh')}
              </Button>
            </div>,
            includeOrders && (
              <Button
                type="primary"
                size="small"
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  let temp = [];
                  reportData?.sales.forEach((e) => {
                    temp = temp.concat(e.orders);
                  });
                  const data = _.map(temp, (p) => ({
                    date: moment(`${p.dispatchDateTime}+0000`)
                      .tz(timezone)
                      .format('MM/DD/YYYY h:mm a'),
                    confirmationCode: p.confirmationCode,
                    customer: p.customer,
                    orderType: mapOrderType(p.orderType),
                    posLaneTag: p.posLaneTag,
                    paymentType: t(mapPaymentType(p.paymentType)),
                    orderTotal: p.orderTotal,
                    productsTotal: p.productsTotal,
                    municipalTax: p.municipalTax,
                    stateTax: p.stateTax,
                    taxTotal: p.taxTotal,
                    // authTotal: p.authTotal,
                    processorFee: p.processorFee,
                    processingFee: p.processingFee,
                    deliveryTotal: p.deliveryTotal,
                    discountAmount: p.discountAmount,
                    returnTotal: p.returnTotal,
                    // storeTotal: p.storeTotal,
                    achFee: p.achFee,
                    salesCommision: p.salesCommision,
                    transferTotal: p.transferTotal,
                  }));
                  saveAsCsv({
                    data,
                    fields: canViewFinance ?
                      allTransactionFields : restrictedTransactionFields,
                    filename: `orders_pending_${dateRange.start}_${dateRange.end}`,
                  });
                }}
              >
                {t('export_all_orders')}
              </Button>
            )
          ]
        }
      />
      <div className="divide-y space-y-4 mb-4">
        <dl className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
          <Card
            type="inner"
            title={t('total')}
            className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
            hoverable
            loading={loading}
          >
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('sales')}:</span>
              <span> {toCurrency(reportData?.cashSalesTotal + reportData?.creditDebitSalesTotal)}</span>
            </Card.Grid>
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('orders')}:</span>
              <span> {thousand(reportData?.cashSalesCount + reportData?.creditDebitSalesCount)}</span>
            </Card.Grid>
          </Card>
          <Card
            type="inner"
            title={t('debit')}
            className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
            hoverable
            loading={loading}
          >
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('sales')}:</span>
              <span> {toCurrency(reportData?.debitSalesTotal)}</span>
            </Card.Grid>
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('orders')}:</span>
              <span> {thousand(reportData?.debitSalesCount)}</span>
            </Card.Grid>
          </Card>
          <Card
            type="inner"
            title={t('credit')}
            className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
            hoverable
            loading={loading}
          >
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('sales')}:</span>
              <span> {toCurrency(reportData?.creditSalesTotal)}</span>
            </Card.Grid>
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('orders')}:</span>
              <span> {thousand(reportData?.creditSalesCount)}</span>
            </Card.Grid>
          </Card>
          <Card
            type="inner"
            title={t('cash')}
            className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
            hoverable
            loading={loading}
          >
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('sales')}:</span>
              <span> {toCurrency(reportData?.cashSalesTotal)}</span>
            </Card.Grid>
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('orders')}:</span>
              <span> {thousand(reportData?.cashSalesCount)}</span>
            </Card.Grid>
          </Card>
        </dl>
      </div>
      <Table
        size="small"
        loading={loading}
        bordered
        scroll={{ x: 1000 }}
        pagination={false}
        columns={canViewFinance ? allColumns : restrictedColumns}
        dataSource={reportData?.sales}
        expandable={!includeOrders ? null : {
          expandRowByClick: true,
          expandedRowRender: (record) => (
            <div className="p-4">
              <Table
                title={() => (
                  <div className="flex items-center justify-between">
                    <div>
                      <Badge
                        className="site-badge-count-109"
                        count={record.orders.length}
                        style={{ backgroundColor: '#52c41a' }}
                      />
                      <span className="font-light uppercase text-xs ml-2">
                        {t('orders')}
                      </span>
                    </div>
                    <Button
                      type="primary"
                      size="small"
                      icon={<CloudDownloadOutlined />}
                      onClick={() => {
                        const data = _.map(record.orders, (p) => ({
                          date: moment(`${p.dispatchDateTime}+0000`)
                            .tz(timezone)
                            .format('MM/DD/YYYY h:mm a'),
                          confirmationCode: p.confirmationCode,
                          customer: p.customer,
                          orderType: t(mapOrderType(p.orderType)),
                          paymentType: t(mapPaymentType(p.paymentType)),
                          orderTotal: p.orderTotal,
                          productsTotal: p.productsTotal,
                          municipalTax: p.municipalTax,
                          stateTax: p.stateTax,
                          taxTotal: p.taxTotal,
                          paymentTender: p.paymentTender,
                          authorizationCode: p?.terminalData[0].authorizationCode,
                          // authTotal: p.authTotal,
                          processorFee: p.processorFee,
                          processingFee: p.processingFee,
                          deliveryTotal: p.deliveryTotal,
                          discountAmount: p.discountAmount,
                          returnTotal: p.returnTotal,
                          // storeTotal: p.storeTotal,
                          achFee: p.achFee,
                          salesCommision: p.salesCommision,
                          transferTotal: p.transferTotal,
                        }));
                        saveAsCsv({
                          data,
                          fields: canViewFinance ?
                            allTransactionFields : restrictedTransactionFields,
                          filename: `orders_pending_${dateRange.start}_${dateRange.end}`,
                        });
                      }}
                    >
                      {t('export_orders')}
                    </Button>
                  </div>
                )}
                bordered
                pagination={false}
                columns={canViewFinance ? allExpandableColumns : restrictedExpandableColumns}
                dataSource={record.orders}
              />
            </div>
          ),
          rowExpandable: (record) => record.orders.length > 0,
        }}
      />
    </div>
  );
}

export default PendingInStoreSalesFinance;
