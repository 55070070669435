import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select,
  InputNumber,
  Upload,
  Drawer,
  Typography,
  Input,
  Switch,
  Tabs
} from 'antd';
import {
  InboxOutlined,
} from '@ant-design/icons';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, } from 'recoil';
import { paymentCreateAtom, businessAtom, selectedPaymentAtom, reloadPaymentOptionsAtom, profileAtom } from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function CreatePayment() {
  const { Option } = Select;
  const { Dragger } = Upload;
  const [createPayment, setCreatePayment] = useRecoilState(paymentCreateAtom);
  const [payment, setPayment] = useRecoilState(selectedPaymentAtom);
  const [reload, setReload] = useRecoilState(reloadPaymentOptionsAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile.contents.roles.includes('SuperAdmin');
  const isPanza =
    profile.contents && (profile.contents.email.includes('gopanza.com') || profile.contents.email === 'stephan.elias.remy@gmail.com');
  const business = useRecoilValue(businessAtom);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageData, setImageData] = useState(null);
  const imageDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };
  const { t } = useTranslation();

  const uploadImage = async function (tempImageData) {
    const file = tempImageData;
    const boundary = Date.now();
    const data = new FormData();
    data.append('file', file);
    setIsSubmitting(true);
    const response = await api
      .post('utility/paymentoptions/uploadfile', data);
    setIsSubmitting(false);
    if (response.data.success) {
      return response.data.data;
    }
    console.error(response.data.error);
    return null;
  };

  function create(temp) {
    setPayment(temp);
    setIsSubmitting(true);
    const payload = JSON.parse(JSON.stringify(temp));
    payload.locationId = business.locations[0].id;
    return api
      .post(
        'businesses/paymentoptions',
        payload
      )
      .then((response) => {
        setIsSubmitting(false);
        if (response && response.data.error) {
          console.error('Error Message: ', response.data.error);
        }
        setCreatePayment(false);
        setReload(!reload);
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.error(error);
      });
  }

  function prepareFlags() {
    if (payment == null) {
      return <div>{t('no_data_loaded')}</div>;
    }

    return (
      <div>
        <div className="p-4">
          <Switch
            disabled={!isAdmin && !isPanza}
            size="small"
            defaultChecked={payment.taxExcluded}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.taxExcluded = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text>
            {' '}
            {t('tax_excluded')}{' '}
          </Typography.Text>
          <br />
          <br />
          <Switch
            disabled={!isAdmin && !isPanza}
            size="small"
            defaultChecked={payment.allowNonFoodProducts}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.allowNonFoodProducts = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text> {t('allow_non_food')} </Typography.Text>
          <br />
          <br />
          <Switch
            size="small"
            disabled={!isAdmin && !isPanza}
            defaultChecked={payment.allowTransfers}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.allowTransfers = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text> {t('allow_alcoholic_items')} </Typography.Text>
          <br />
          <br />
          <Switch
            size="small"
            disabled={!isAdmin && !isPanza}
            defaultChecked={payment.allowTransfers}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.allowAlcoholicItems = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text> {t('allow_transfer')} </Typography.Text>
          <br />
          <br />
          <Switch
            size="small"
            disabled={!isAdmin && !isPanza}
            defaultChecked={payment.allowedInDeliService}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.allowedInDeliService = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text> {t('allow_deli_service')} </Typography.Text>
          <br />
          <br />
          <Switch
            disabled={!isAdmin && !isPanza}
            size="small"
            defaultChecked={payment.allowedInDeliveryService}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.allowedInDeliveryService = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text>
            {' '}
            {t('allow_delivery_service')}{' '}
          </Typography.Text>
          <br />
          <br />
          <Switch
            disabled={!isAdmin && !isPanza}
            size="small"
            defaultChecked={payment.allowedInRegularService}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.allowedInRegularService = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text>
            {' '}
            {t('allow_delivery_service')}{' '}
          </Typography.Text>
          <br />
          <br />
          <Switch
            disabled={!isAdmin && !isPanza}
            size="small"
            defaultChecked={payment.allowedInSelfCheckoutService}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.allowedInSelfCheckoutService = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text>
            {' '}
            {t('allow_selfcheckout')}{' '}
          </Typography.Text>
          <br />
          <br />
          <Switch
            disabled={!isAdmin && !isPanza}
            size="small"
            defaultChecked={payment.allowsServiceFeePayment}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.allowsServiceFeePayment = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text>
            {' '}
            {t('allow_service_fee')}{' '}
          </Typography.Text>
          <br />
          <br />
          <Switch
            disabled={!isAdmin && !isPanza}
            size="small"
            defaultChecked={payment.hasFreeDelivery}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.hasFreeDelivery = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text> {t('free_delivery')} </Typography.Text>
          <br />
          <br />
          <Switch
            size="small"
            disabled={!isAdmin && !isPanza}
            defaultChecked={payment.hasFreePickup}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.hasFreePickup = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text> {t('free_pickup')} </Typography.Text>
          <br />
          <br />
          <Switch
            size="small"
            disabled={!isAdmin && !isPanza}
            defaultChecked={payment.needsBillingAgreement}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.needsBillingAgreement = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text> {t('need_billing_agreement')} </Typography.Text>

          <br />
          <br />
          <Switch
            size="small"
            disabled={!isAdmin && !isPanza}
            defaultChecked={payment.ignoreOrderCap}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.ignoreOrderCap = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text> {t('ignore_order_cap')} </Typography.Text>

          <br />
          <br />
          <Switch
            size="small"
            disabled={!isAdmin && !isPanza}
            defaultChecked={payment.shouldOpenCashDrawer}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.shouldOpenCashDrawer = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text> {t('should_open_cash_drawer')} </Typography.Text>

          <br />
          <br />
          <Switch
            size="small"
            disabled={!isAdmin && !isPanza}
            defaultChecked={payment.offlineOnly}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.offlineOnly = checked;
              setPayment(temp);
            }}
          />
          <Typography.Text> {t('offline_only')} </Typography.Text>
        </div>
      </div>
    );
  }
  function prepareForm() {
    if (payment == null) {
      return <div>{t('no_data_loaded')}</div>;
    }
    return (
      <div>
        <div className="py-2">
          <Typography.Text>
            {' '}
            {t('public_token')}{' '}
          </Typography.Text>
          <Input
            placeholder={t('public_token')}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.publicToken = obj.target.value;
              setPayment(temp);
            }}
            value={payment.publicToken}
          />
        </div>
        <div className="py-2">
          <Typography.Text>
            {' '}
            {t('private_token')}{' '}
          </Typography.Text>
          <Input
            placeholder={t('private_token')}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.privateToken = obj.target.value;
              setPayment(temp);
            }}
            value={payment.privateToken}
          />
        </div>
        <div className="py-2">
          <Typography.Text>
            {' '}
            {t('transfer_token')}{' '}
          </Typography.Text>
          <Input
            placeholder={t('transfer_token')}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(payment));
              temp.transferToken = obj.target.value;
              setPayment(temp);
            }}
            value={payment.transferToken}
          />
        </div>
      </div>
    );
  }

  useEffect(async () => {
    if (imageData) {
      const url = await uploadImage(imageData);
      if (url) {
        setImageData(null);
        const temp = JSON.parse(JSON.stringify(payment));
        temp.imageUrl = url;
        setPayment(temp);
      }
    }
  }, [imageData]);

  return (
    <Drawer
      title={t('create_payment')}
      placement="right"
      closable={false}
      onClose={() => setCreatePayment(false)}
      open={createPayment}
      width={300}
    >
      <div>
        {' '}
      </div>
      <Tabs>
        <Tabs.TabPane key="0" tab={t('settings')}>
          <div className="p-4">
            <div className="py-2">
              <Typography.Text>
                {' '}
                {t('name')}{' '}
              </Typography.Text>
              <Input
                placeholder={t('name')}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(payment));
                  temp.name = obj.target.value;
                  setPayment(temp);
                }}
                value={payment.name}
              />
            </div>
            <div className="py-2">
              <Typography.Text>
                {' '}
                {t('image')}{' '}
              </Typography.Text>
              <Input
                placeholder="Image Url"
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(payment));
                  temp.imageUrl = obj.target.value;
                  setPayment(temp);
                }}
                value={payment.imageUrl}
              />
              <Dragger {...imageDraggerProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p
                  className="ant-upload-text"
                  style={{ margin: 10 }}
                >
                  {t('click_or_drag_content')}
                </p>
              </Dragger>
            </div>
            <div className="py-2">
              <Typography.Text>
                {' '}
                {t('min_charge')}{' '}
              </Typography.Text>
              <InputNumber
                min={0}
                precision={2}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(payment));
                  temp.minCharge = obj;
                  setPayment(temp);
                }}
                value={payment.minCharge}
              />
            </div>
            <div className="py-2">
              <Typography.Text>
                {' '}
                {t('provider')}{' '}
              </Typography.Text>
              <Select
                defaultValue={payment.provider}
                value={payment.provider}
                style={{ width: 120 }}
                disabled={isSubmitting}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(payment));
                  temp.provider = obj;
                  setPayment(temp);
                }}
              >
                <Option value={0}>Stripe</Option>
                <Option value={1}>PayPal</Option>
                <Option value={2}>Evertec</Option>
                <Option value={3}>Accepta</Option>
                <Option value={4}>MCS</Option>
                <Option value={5}>MMM</Option>
                <Option value={6}>Icon</Option>
                <Option value={7}>Store</Option>
                <Option value={8}>Venmo</Option>
                <Option value={9}>SSS</Option>
                <Option value={10}>Humana</Option>
                <Option value={11}>MagicPay</Option>
              </Select>
            </div>
            <div className="py-2">
              <Typography.Text>
                {' '}
                Selector{' '}
              </Typography.Text>
              <Select
                defaultValue={payment.selector}
                value={payment.selector}
                style={{ width: 120 }}
                disabled={isSubmitting}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(payment));
                  temp.selector = obj;
                  setPayment(temp);
                }}
              >
                <Option value={0}>Credit</Option>
                <Option value={1}>EBT</Option>
                <Option value={2}>Debit</Option>
                <Option value={3}>Pin Debit</Option>
                <Option value={4}>Pre-Paid</Option>
                <Option value={5}>Gift Card</Option>
                <Option value={6}>Benefits Card</Option>
                <Option value={7}>ACH</Option>
                <Option value={8}>P2B</Option>
                <Option value={9}>CheckOut</Option>
              </Select>
            </div>
            <div className="py-2">
              <Typography.Text>
                {' '}
                {t('charge_type')}{' '}
              </Typography.Text>
              <Select
                defaultValue={payment.chargeType}
                value={payment.chargeType}
                style={{ width: 120 }}
                disabled={isSubmitting}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(payment));
                  temp.chargeType = obj;
                  setPayment(temp);
                }}
              >
                <Option value={0}>{t('up_front')}</Option>
                <Option value={1}>{t('dispatch')}</Option>
                <Option value={2}>{t('purchase_order')}</Option>
                <Option value={3}>{t('app_to_app')}</Option>
                <Option value={4}>{t('billing_agreement')}</Option>
                <Option value={5}>{t('qr_code')}</Option>
                <Option value={6}>{t('terminal')}</Option>
                <Option value={5}>QR Code</Option>
              </Select>
            </div>
            <div className="py-2">
              <Typography.Text>
                {' '}
                {t('payment_type')}{' '}
              </Typography.Text>
              <Select
                defaultValue={payment.paymentType}
                value={payment.paymentType}
                style={{ width: 120 }}
                disabled={isSubmitting}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(payment));
                  temp.paymentType = obj;
                  setPayment(temp);
                }}
              >
                <Option value={0}>Stripe</Option>
                <Option value={1}>ATH Móvil</Option>
                <Option value={2}>Cash</Option>
                <Option value={3}>Other</Option>
                <Option value={4}>EBT</Option>
                <Option value={5}>Paypal</Option>
                <Option value={6}>Cheque</Option>
                <Option value={7}>ATH</Option>
                <Option value={8}>Vanilla Card</Option>
                <Option value={9}>Gift Card</Option>
                <Option value={10}>MCS</Option>
                <Option value={11}>MMM</Option>
                <Option value={12}>Accepta</Option>
                <Option value={13}>Net 30</Option>
                <Option value={14}>Net 60</Option>
                <Option value={15}>Not Selected</Option>
                <Option value={16}>Credit Card</Option>
                <Option value={17}>Debit Card</Option>
                <Option value={18}>SSS</Option>
                <Option value={19}>Humana</Option>
                <Option value={20}>BrainTree</Option>
                <Option value={21}>In-Store</Option>
                <Option value={22}>Prepaid</Option>
                <Option value={23}>EBT Cash</Option>
                <Option value={24}>Unica</Option>
                <Option value={25}>Fondo</Option>
              </Select>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane key="1" tab="Flags">
          <div>{prepareFlags()}</div>
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab="Tokens">
          <div>{prepareForm()}</div>
        </Tabs.TabPane>
      </Tabs>
      <div className="flex space-x-3 mt-10">
        <button
          className="w-full md:w-1/2 text-xs transition delay-150 duration-300 ease-in-out border border-gray-700 hover:border-red-600 text-gray-500 hover:text-red-400 font-semibold px-6 py-3 rounded-full"
          type="button"
          size="small"
          onClick={(e) => {
            setCreatePayment(false);
          }}
        >
          {t('cancel')}
        </button>
        <button
          className="w-full md:w-1/2 flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out bg-blue-500 hover:bg-blue-600 text-white font-semibold px-6 py-3 rounded-full"
          type="submit"
          size="small"
          onClick={(e) => {
            create(payment);
          }}
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          {' '}
          {isSubmitting && (
            <div>
              <svg
                className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            </div>
          )}
          {t('create')}
        </button>
      </div>
    </Drawer>
  );
}

export default CreatePayment;
