import React, { useState } from 'react';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Layout,
} from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  UserOutlined,
  LockOutlined,
} from '@ant-design/icons';
import {
  useSetRecoilState,
  useRecoilValueLoadable,
} from 'recoil';
import { configSelector } from '../../../atoms/Selectors';
import {
  profileAtom,
  tokenAtom,
  stateAtom
} from '../../../atoms/Atoms';
import envConfig from '../../../envConfig';
import api from '../../../api/api';
import LanguageSwitcher from '../LanguageSwitcher';

function validateAdminRole(roleObject) {
  const isValid =
    roleObject.includes('Admin') ||
    roleObject.includes('DeliveryProvider') ||
    roleObject.includes('Marketing') ||
    roleObject.includes('CustomerService') ||
    roleObject.includes('CustomerSupport') ||
    roleObject.includes('Finance') ||
    roleObject.includes('StoreManager') ||
    roleObject.includes('StoreAdmin') ||
    roleObject.includes('CustomerServiceAdmin') ||
    roleObject.includes('CustomerSupportAdmin') ||
    roleObject.includes('SuperAdmin');
  if (isValid) {
    return true;
  }
  return false;
}

function Home() {
  const config = useRecoilValueLoadable(configSelector);
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);
  const setProfile = useSetRecoilState(profileAtom);
  const setState = useSetRecoilState(stateAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const [err, setError] = useState(null);

  const onFinish = (values) => {
    setError(null);
    setSubmitting(true);
    const json = JSON.stringify({
      username: values.username,
      password: values.password,
    });
    api
      .post('oauth/local/login', json, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setSubmitting(false);
        if (response.status === 200 && response.data.error) {
          setError(response.data.error);
        } else if (response.status === 200) {
          const expiration = moment().add(1000000000, 'seconds');
          setToken(response.data.data.accessToken);
          const allow = validateAdminRole(
            JSON.stringify(response.data.data.state.profile.roles)
          );
          if (allow) {
            localStorage.setItem(
              `${envConfig.REACT_APP_PREFIX}-admin-token`,
              response.data.data.accessToken
            );
            localStorage.setItem(
              `${envConfig.REACT_APP_PREFIX}-admin-currentstate`,
              JSON.stringify(response.data.data.state)
            );
            localStorage.setItem(
              `${envConfig.REACT_APP_PREFIX}-admin-expire`,
              expiration
            );
            localStorage.setItem(
              `${envConfig.REACT_APP_PREFIX}-admin-profile`,
              JSON.stringify(response.data.data.state.profile)
            );
            setProfile(response.data.data.state.profile);
            setState(response.data.data.state);
          } else {
            localStorage.removeItem(
              `${envConfig.REACT_APP_PREFIX}-admin-currentstate`
            );
            localStorage.removeItem(
              `${envConfig.REACT_APP_PREFIX}-admin-token`
            );
            localStorage.removeItem(
              `${envConfig.REACT_APP_PREFIX}-admin-expire`
            );
            localStorage.removeItem(
              `${envConfig.REACT_APP_PREFIX}-admin-profile`
            );
            setToken(null);
            setError('You are not authorized');
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    setSubmitting(false);
    console.error('Failed:', errorInfo);
  };

  return (
    <div>
      <Layout.Header theme="dark" className="h-auto">
        <div className="flex flex-col items-center md:flex-row">
          <div className="mt-6 md:my-2">
            {config.state === 'hasValue' && (
              <img
                alt="Store Logo"
                className="md:ml-3 h-6"
                src={
                  config?.state === 'hasValue' &&
                  config?.contents?.data?.adminHeaderLogoCombo
                }
              />
            )}
          </div>
          <LanguageSwitcher small className="ml-4" />
          <Layout className="flex items-end bg-transparent pt-8">
            <Layout.Content>
              <Form
                className="flex flex-col md:flex-row"
                name={t('login')}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  className="md:mr-2"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: t('email_input'),
                      className: 'text-white',
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t('username')}
                  />
                </Form.Item>
                <Form.Item
                  className="md:mr-2 text-white"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t('password_description'),
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={t('password')}
                  />
                </Form.Item>
                <div className="flex flex-row justify-center">
                  <Form.Item>
                    <Button
                      loading={submitting}
                      type="primary"
                      htmlType="submit"
                      className="login-form-button md:ml-4"
                    >
                      {t('login')}
                    </Button>
                  </Form.Item>
                  <Link to="/register">
                    <Button
                      type="primary"
                      className="login-form-button md:ml-4 ml-12"
                    >
                      {t('register')}
                    </Button>
                  </Link>
                </div>
              </Form>
              {err && (<div className="text-white text-xs py-0" style={{ marginTop: '-45px' }}>{err}</div>)}
            </Layout.Content>
          </Layout>
        </div>
      </Layout.Header>
    </div>
  );
}

export default Home;
