import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, CloseCircleOutlined, CopyOutlined, DollarCircleOutlined, PushpinOutlined } from '@ant-design/icons';
import {
  Descriptions,
  Input,
  Col,
  Row,
  Table,
  Typography,
  Space,
  Button,
  Switch,
  Tabs,
  Tooltip,
  Popover,
  Divider,
  Tag
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { phoneNumber } from '../../../utils/index';
import { monthToString, cardFundingString, formatUserSinceDate } from '../../../utils/functions';
import {
  customerLocationAtom,
  editUserAtom,
  openCustomerLocationMapAtom,
  pageAtom,
  profileAtom,
  referralEmailAtom,
  reloadCustomerCards,
  reloadCustomers,
  rowsAtom,
  timezoneAtom,
  userRolesAtom,
} from '../../../../atoms/Atoms';
import EditUser from '../../../shared-components/drawers/EditUser';
import api from '../../../../api/api';
import { configSelector } from '../../../../atoms/Selectors';
import CustomerLocationModal from '../../../shared-components/modals/CustomerLocationMap';
import ReferralEmail from '../../../shared-components/modals/ReferralEmail';
import CustomersCards from './CustomersCards';
import UserPurchaseProfile from '../../../shared-components/UserPurchaseProfile';

function Customer() {
  const config = useRecoilValueLoadable(configSelector);
  const [count, setCount] = useState(0);
  const timezone = useRecoilValue(timezoneAtom);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [current, setCurrent] = useRecoilState(pageAtom);
  const [rows, setRows] = useRecoilState(rowsAtom);
  const [searchTerm, setSearchTerm] = useState(null);
  const [profile, setProfile] = useState(null);
  const { t } = useTranslation();
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [edit, setEdit] = useRecoilState(editUserAtom);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [reload, setReload] = useRecoilState(reloadCustomers);
  const [roles, setRole] = useRecoilState(userRolesAtom);
  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents &&
    userProfile?.contents?.roles?.includes('SuperAdmin');
  const isCustomerService =
    isAdmin ||
    (userProfile?.contents &&
      (userProfile?.contents?.roles?.includes('CustomerSupportAdmin') ||
        userProfile?.contents?.roles?.includes('CustomerSupport')));
  const isStoreManager =
        isAdmin ||
        isCustomerService ||
        (userProfile?.contents &&
          (userProfile?.contents?.roles?.includes('StoreManager') ||
          userProfile?.contents?.roles?.includes('StoreAdmin')));
  const history = useHistory();
  const [referralEmail, setReferralEmail] = useRecoilState(referralEmailAtom);

  if (!isAdmin && !isCustomerService && !isStoreManager) {
    history.push('/');
    return null;
  }
  const [customerLocation, setCustomerLocation] = useRecoilState(customerLocationAtom);
  const [openLocationMap, setOpenLocationMap] = useRecoilState(openCustomerLocationMapAtom);
  const [reloadCards, setReloadCards] = useRecoilState(reloadCustomerCards);

  function mapLocations(data) {
    if (data) {
      return _.map(data, (p) => ({
        ...p,
        key: p.id,
        latitude: p.geo.lat,
        longitude: p.geo.lon,
        createdAt: moment(`${p.createdAt}+0000`)
          .tz(timezone)
          .format('MMM Do YYYY, h:mm:ss a'),
        createdTime: moment(`${p.createdAt}+0000`)
          .tz(timezone)
          .format('MMM Do YYYY, h:mm:ss a'),
      }));
    }
    return null;
  }

  function mapCards(data) {
    if (data) {
      const mapped = _.map(data, (p) => ({
        ...p,
        key: p.id,
        createdAt: moment(`${p.createdAt}+0000`)
          .tz(timezone)
          .format('MMM Do YYYY, h:mm:ss a'),
        deleted: p.wasDeleted,
        expMonth: t(monthToString(p.expMonth)),
        exp: `${t(monthToString(p.expMonth))}/${p.expYear}`,
        active: !p.wasDeleted && p.passedSecurity ? 'YES' : 'NO',
        allowActivation: !p.allowActivation,
        funding: t(cardFundingString(p.funding)),
      }));

      return _.sortBy(mapped, (s) => s.deleted);
    }
    return null;
  }
  function mapData(data) {
    return _.map(data, (p) => ({
      ...p,
      key: p.id,
      hasOrdered: p.favStoreId !== config.contents.data.encodedZero,
      createdAt: moment(`${p.createdAt}+0000`)
        .tz(timezone)
        .format('MMMM Do YYYY, h:mm:ss a'),
      isDeleted: p.deletedAt === null,
      birthDate: moment(`${p.birthDate}`)
        .tz(timezone)
        .format('YYYY-MM-DD'),
      locations: mapLocations(p.locations),
      cards: mapCards(p.cards)
    }));
  }

  const onSearch = async (value) => {
    if (value === '' || value === null) {
      setUsers(null);
      return;
    }

    setSearchTerm(value);
    setLoading(true);
    setRole(null);
    setReferralEmail(false);

    api
      .post(
        'users/search',
        {
          SearchText: `${value}`,
        }
      )
      .then((response) => {
        const mapped = mapData(response.data.data.results);
        setUsers(mapped);
        setCount(response.data.data.count);
        if (response.data.data.results.length === 0) {
          setReferralEmail(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (openLocationMap === false) {
      setCustomerLocation(null);
    }
  }, [openLocationMap]);

  // useEffect(() => {
  //   if (users?.length === 0) {
  //     setReferralEmail(true);
  //   }
  // }, [users]);

  useEffect(() => {
    if (searchTerm === '' || searchTerm === null) {
      return;
    }

    setLoading(true);
    api
      .post(
        'users/search',
        {
          SearchText: `${searchTerm}`,
        }
      )
      .then((response) => {
        const mapped = mapData(response.data.data.results);
        setUsers(mapped);
        setCount(response.data.data.results.length);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [current, rows, reload]);

  return (
    <div className="m-4 mt-2">
      <PageHeader
        className="text-lg"
        title={t('customers')}
      />
      <br />
      <CustomersCards />
      <div>
        <Input.Search
          placeholder={t('search_customer_placeholder')}
          allowClear
          enterButton={t('search')}
          size="large"
          onSearch={onSearch}
        />
      </div>
      {users && <div className="mt-2 ml-0 text-xs">{t('results')}: {count}</div>}
      <div className="mt-4">
        <Table
          size="small"
          loading={loading}
          bordered
          scroll={{ x: 500 }}
          pagination={{
            current,
            hideOnSinglePage: true,
            showSizeChanger: true,
            size: 'small',
            pageSize: rows,
            total: count,
            pageSizeOptions: [10, 20, 30, 40, 50],
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${t('out_of')} ${total} ${t('items')}`,
            onShowSizeChange: (page, size) => {
              setRows(size);
            },
            onChange: (page) => {
              setCurrent(page);
            },
          }}
          columns={[
            {
              title: t('first_name'),
              dataIndex: 'firstName',
              key: 'firstName',
              align: 'center',
              className: 'text-xs',
              render: (text) => <Typography.Text>{text}</Typography.Text>,
            },
            {
              title: t('last_name'),
              dataIndex: 'lastName',
              key: 'lastName',
              align: 'center',
              className: 'text-xs',
              render: (text) => <Typography.Text>{text}</Typography.Text>,
            },
            {
              title: t('email'),
              dataIndex: 'email',
              key: 'email',
              align: 'center',
              className: 'text-xs',
              render: (text) => <Typography.Text copyable>{text}</Typography.Text>,
            },
            {
              title: t('phone'),
              dataIndex: 'phoneNumber',
              key: 'phoneNumber',
              align: 'center',
              className: 'text-xs',
              render: (value) =>
                <span>
                  <Typography.Text copyable={{ text: value }}>
                    {phoneNumber(value)}
                  </Typography.Text>
                </span>,
            },
            {
              title: t('order_count'),
              dataIndex: 'orderCount',
              key: 'orderCount',
              align: 'center',
              className: 'text-xs',
              render: (text) => <Typography.Text>{text}</Typography.Text>,
            },
            {
              title: t('profile'),
              align: 'center',
              className: 'text-xs',
              render: (text, row) => (
                <UserPurchaseProfile
                  userId={row.id}
                  lifetime
                />
              ),
            },
            {
              title: t('actions'),
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <Space>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      setSelectedUser(row);
                      setEdit(row);
                      setRole(row.roles);
                    }}
                  >
                    {t('edit')}
                  </Button>
                </Space>
              ),
            },
          ]}
          dataSource={users}
          expandable={{
            expandRowByClick: true,
            expandedRowRender: (record) => (
              <div>
                <Tabs>
                  <Tabs.TabPane key="1" tab={t('details')}>
                    <Row className="flex">
                      <Col span={12} className="flex">
                        <Descriptions>
                          <Descriptions.Item label={t('friend_code')} />
                          <br />
                          <Descriptions.Item>
                            <Col span={24}>
                              <Typography.Text copyable>
                                {record.friendCode}
                              </Typography.Text>
                            </Col>
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                      <Col span={12} className="flex">
                        <Descriptions>
                          <Descriptions.Item label={t('allow_push')} />
                          <br />
                          <Descriptions.Item>
                            <Col span={24} className="flex">
                              <Switch
                                size="small"
                                checked={record.allowPush}
                                disabled
                              />
                            </Col>
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                    </Row>
                    <Row className="flex">
                      <Col span={12} className="flex">
                        <Descriptions>
                          <Descriptions.Item label={t('phone')} />
                          <br />
                          <Descriptions.Item>
                            <Col span={24}>
                              <Typography.Text>
                                {phoneNumber(record.phoneNumber)}
                              </Typography.Text>
                            </Col>
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                      <Col span={12} className="flex">
                        <Descriptions>
                          <Descriptions.Item label={t('allow_emails')} />
                          <br />
                          <Descriptions.Item>
                            <Col span={24}>
                              <Switch
                                size="small"
                                checked={record.allowEmails}
                                disabled
                              />
                            </Col>
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                    </Row>
                    <Row className="flex">
                      <Col span={12} className="flex">
                        <Descriptions>
                          <Descriptions.Item label={t('user_since')} />
                          <br />
                          <Descriptions.Item>
                            <Col span={24}>
                              <Typography.Text>
                                {record.createdAt} {formatUserSinceDate(record.createdAt, timezone)}
                              </Typography.Text>
                            </Col>
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                      <Col span={12} className="flex">
                        <Descriptions>
                          <Descriptions.Item label={t('allow_sms')} />
                          <br />
                          <Descriptions.Item>
                            <Col span={24}>
                              <Switch
                                size="small"
                                checked={record.allowSMS}
                                disabled
                              />
                            </Col>
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                    </Row>
                    <Row className="flex">
                      <Col span={12} className="flex">
                        {/* <Descriptions>
                          <Descriptions.Item label={t('birth_date')} />
                          <br />
                          <Descriptions.Item>
                            <Col span={24}>
                              <Typography.Text>
                                {record.birthDate}
                              </Typography.Text>
                            </Col>
                          </Descriptions.Item>
                        </Descriptions> */}
                      </Col>
                      <Col span={12} className="flex">
                        {/* <Descriptions>
                          <Descriptions.Item label={t('allow_push')} />
                          <br />
                          <Descriptions.Item>
                            <Col span={24} className="flex">
                              <Switch
                                size="small"
                                checked={record.allowPush}
                                disabled
                              />
                            </Col>
                          </Descriptions.Item>
                        </Descriptions> */}
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={`${t('locations')} (${record.locations.length})`} key="2">
                    <div className="px-2 bg-gray-100 mb-2">
                      <br />
                      <Table
                        size="small"
                        pagination={false}
                        columns={[
                          {
                            title: t('name'),
                            dataIndex: 'locationName',
                            key: 'locationName',
                            align: 'left',
                            className: 'text-xs',
                            render: (text) => (
                              <Typography.Text>{text}</Typography.Text>
                            ),
                          },
                          {
                            title: t('address'),
                            dataIndex: 'locationAddress',
                            key: 'locationAddress',
                            align: 'left',
                            className: 'text-xs',
                            render: (text) => (
                              <Typography.Text>{text}</Typography.Text>
                            ),
                          },
                          {
                            title: t('city'),
                            dataIndex: 'locationCity',
                            key: 'locationCity',
                            align: 'left',
                            className: 'text-xs',
                            render: (text) => (
                              <Typography.Text>{text}</Typography.Text>
                            ),
                          },
                          {
                            title: t('zipcode'),
                            dataIndex: 'zipcode',
                            key: 'zipcode',
                            align: 'left',
                            className: 'text-xs',
                            width: '70px',
                            render: (text) => (
                              <Typography.Text>{text}</Typography.Text>
                            ),
                          },
                          {
                            title: t('status'),
                            dataIndex: 'isActive',
                            key: 'isActive',
                            align: 'center',
                            className: 'text-xs',
                            render: (text) => {
                              if (text === false) {
                                return (
                                  <Tag
                                    icon={<CloseCircleOutlined />}
                                    color="error"
                                    size="small"
                                    className="mr-0"
                                  >
                                    {t('deleted')}
                                  </Tag>
                                );
                              }
                              return (
                                <>
                                  <Tag
                                    icon={<CheckCircleOutlined />}
                                    color="success"
                                    size="small"
                                    className="mr-0"
                                  >
                                    {t('active')}
                                  </Tag>
                                </>
                              );
                            },
                          },
                          {
                            title: t('created_at'),
                            key: 'createdAt',
                            align: 'right',
                            width: '90px',
                            className: 'text-xs',
                            render: (text) => (
                              <Typography.Text>
                                {' '}
                                <Tooltip title={`${text.createdTime}`}>
                                  {text.createdAt}
                                </Tooltip>
                              </Typography.Text>
                            ),
                          },
                          {
                            title: t('map'),
                            align: 'center',
                            className: 'text-xs',
                            render: (text, row) => (
                              <Space>
                                <Button
                                  size="small"
                                  type="primary"
                                  onClick={() => {
                                    setCustomerLocation(row);
                                    setOpenLocationMap(true);
                                  }}
                                >
                                  <PushpinOutlined />
                                </Button>
                              </Space>
                            ),
                          }
                        ]}
                        dataSource={record && record.locations}
                      />
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={`${t('cards')} (${record.cards.length})`} key="3">
                    <div className="px-2 bg-gray-100 mb-2">
                      <Space />
                      <br />
                      <Table
                        size="small"
                        pagination={false}
                        columns={[
                          {
                            title: t('active'),
                            dataIndex: 'active',
                            key: 'active',
                            align: 'center',
                            className: 'text-xs',
                            render: (text) => (
                              <Typography.Text
                                type={text === 'YES' ? 'success' : 'danger'}
                              >
                                {text}
                              </Typography.Text>
                            ),
                          },
                          {
                            title: t('card_holder'),
                            dataIndex: 'cardholderName',
                            key: 'cardHolderName',
                            align: 'left',
                            className: 'text-xs',
                            render: (text) => (
                              <Typography.Text>{text}</Typography.Text>
                            ),
                          },
                          {
                            title: t('nickname'),
                            dataIndex: 'nickname',
                            key: 'nickname',
                            align: 'left',
                            className: 'text-xs',
                            render: (text) => (
                              <Typography.Text>{text}</Typography.Text>
                            ),
                          },
                          {
                            title: t('type'),
                            dataIndex: 'funding',
                            key: 'funding',
                            align: 'left',
                            className: 'text-xs',
                            render: (text) => (
                              <Typography.Text>{text}</Typography.Text>
                            ),
                          },

                          {
                            title: t('expires'),
                            dataIndex: 'exp',
                            key: 'exp',
                            align: 'left',
                            className: 'text-xs',
                            render: (text) => (
                              <Typography.Text>{text}</Typography.Text>
                            ),
                          },
                          {
                            title: t('passed_security'),
                            dataIndex: 'passedSecurity',
                            key: 'passedSecurity',
                            align: 'center',
                            className: 'text-xs',
                            render: (text) => (
                              <span>
                                <Tooltip title={t('passed_security_content')}>
                                  {' '}
                                  <Switch
                                    size="small"
                                    disabled
                                    checked={text}
                                  />
                                </Tooltip>
                              </span>
                            ),
                          },
                          {
                            title: t('deleted'),
                            dataIndex: 'deleted',
                            key: 'deleted',
                            align: 'center',
                            className: 'text-xs',
                            render: (text) => (
                              <span>
                                <Switch size="small" disabled checked={text} />
                              </span>
                            ),
                          },
                          {
                            title: t('created_at'),
                            dataIndex: 'createdAt',
                            key: 'createdAt',
                            align: 'right',
                            className: 'text-xs',
                            render: (text) => (
                              <Typography.Text>
                                {' '}
                                <Tooltip title={t('date_of_creation')}>
                                  {text}
                                </Tooltip>
                              </Typography.Text>
                            ),
                          },
                        ]}
                        dataSource={record && record.cards}
                      />
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            ),
            rowExpandable: (record) => record,
          }}
        />
        {edit ? <EditUser open={selectedUser} /> : null}
        {openLocationMap ? <CustomerLocationModal /> : null}
        {referralEmail ? <ReferralEmail /> : null}

      </div>
    </div>
  );
}

export default Customer;
