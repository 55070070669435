import {
  Divider,
  Spin,
  Tabs,
  Typography,
  Button,
  Row,
  Col,
  DatePicker
} from 'antd';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  SyncOutlined,
  MinusOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import dayjs from 'dayjs';
import { useRecoilValue, useRecoilState } from 'recoil';
import AdoptionChart from './AdoptionChart';
import SalesChart from './SalesChart';
import { mapPaymentType, thousand } from '../../../utils/functions';
import { timezoneAtom, dashboardSelectedStoreAtom } from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function Trends() {
  const { RangePicker } = DatePicker;
  const [mom, setMom] = useState(null);
  const [currentDataSet, setCurrentDataSet] = useState(null);
  const [wow, setWow] = useState(null);
  const [yoy, setYoy] = useState(null);
  const [loading, setLoading] = useState(false);
  const timezone = useRecoilValue(timezoneAtom);
  const selectedStoreId = useRecoilValue(dashboardSelectedStoreAtom);
  const { t } = useTranslation();
  const width = window.innerWidth;
  const [selectedTab, setSelectedTab] = useState('0');

  const [wowDates, setWowDates] = useState(null);

  const [wowRange, setWowRange] = useState({
    start: moment().startOf('week').format('YYYY-MM-DD'),
    end: moment().endOf('week').format('YYYY-MM-DD'),
  });
  const [momRange, setMomRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD'),
  });
  const [yearRange, setYearRange] = useState({
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end: moment().endOf('year').format('YYYY-MM-DD'),
  });

  function getWow() {
    setLoading(true);
    api
      .post(`analytics/v2/trends/${1}/${selectedStoreId}`, {
        Lifetime: false,
        StartDate: moment(wowRange.start).utc(),
        EndDate: moment(wowRange.end).utc().add(1, 'days')
      })
      .then((response) => {
        setLoading(false);
        setCurrentDataSet(response.data.data);
        setWow(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function getMom() {
    setLoading(true);
    api
      .post(`analytics/v2/trends/${2}/${selectedStoreId}`, {
        Lifetime: false,
        StartDate: moment(momRange.start).utc(),
        EndDate: moment(momRange.end).utc().add(1, 'days')
      })
      .then((response) => {
        setLoading(false);
        setCurrentDataSet(response.data.data);
        setMom(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function getYoy() {
    setLoading(true);
    api
      .post(`analytics/v2/trends/${3}/${selectedStoreId}`, {
        Lifetime: false,
        StartDate: moment(yearRange.start).utc(),
        EndDate: moment(yearRange.end).utc().add(1, 'days')
      })
      .then((response) => {
        setLoading(false);
        setCurrentDataSet(response.data.data);
        setYoy(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function datesStringComparision(date1, date2) {
    if (!date1 || !date2) {
      return '';
    }
    // console.log('date1', date1);
    // console.log('date2', date2);
    const sameYear = moment(`${date1}`).tz(timezone).format('YYYY') === moment(`${date2}`).tz(timezone).format('YYYY');
    const sameMonth = moment(`${date1}`).tz(timezone).format('MMM') === moment(`${date2}`).tz(timezone).format('MMM');
    // console.log('sameYear', sameYear);
    // console.log('sameMonth', sameMonth);
    if (sameYear && sameMonth) {
      return `${moment(`${date1}`).tz(timezone).format('MMM D')}-${moment(`${date2}`).tz(timezone).format('D, YYYY')}`;
    }
    if (sameYear && !sameMonth) {
      return `${moment(`${date1}`).tz(timezone).format('MMM D')} - ${moment(`${date2}`).tz(timezone).format('MMM D, YYYY')}`;
    }
    if (!sameYear && !sameMonth) { return moment(`${date1}`).tz(timezone).format('MMM'); }
    return `${moment(`${date1}`).tz(timezone).format('YYYY')} - ${moment(`${date2}`).tz(timezone).format('YYYY')}`;
  }

  function devicesPercentage(current, last) {
    const per = (current - last) / (last > 0 ? last : 1);
    return Math.abs(per) * 100;
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  function getData() {
    if (selectedTab === '0') {
      getWow();
    }
    if (selectedTab === '1') {
      getMom();
    }
    if (selectedTab === '2') {
      getYoy();
    }
  }

  function trendsCard(title, current, last, isMoney) {
    const diffCheck = current - last;
    return (
      <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <Spin spinning={loading}>
          <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className="flex items-baseline text-2xl font-semibold text-gray-900">
              {isMoney && '$'}{thousand(current)}
              <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} {isMoney && '$'}{thousand(last)}</span>
            </div>
            <div
              className={classNames(
                diffCheck === 0 ? 'text-black' : (diffCheck > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                )
              )}
            >
              {diffCheck === 0 ? (
                <MinusOutlined
                  className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                  aria-hidden="true"
                />
              ) :
                (diffCheck > 0 ? (
                  <ArrowUpOutlined
                    className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowDownOutlined
                    className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                ))}
              <span className="sr-only">
                {diffCheck === 0 ? t('no_growth') :
                  (diffCheck > 0 ? t('increased_by') : t('decreased_by'))}
              </span>
              {devicesPercentage(current, last).toFixed(2)}%
            </div>
          </dd>
        </Spin>
      </div>
    );
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [wowRange, momRange, yearRange]);

  useEffect(() => {
    if (selectedTab === '0') {
      if (!wow) {
        getData();
      } else {
        setCurrentDataSet(wow);
      }
    }
    if (selectedTab === '1') {
      if (!mom) {
        getData();
      } else {
        setCurrentDataSet(mom);
      }
    }
    if (selectedTab === '2') {
      if (!yoy) {
        getData();
      } else {
        setCurrentDataSet(yoy);
      }
    }
  }, [selectedTab]);

  return (
    <div>
      <div className="flex space-x-2 float-right">
        {selectedTab === '0' && (
          <RangePicker
            format="YYYY-MM-DD"
            value={wowDates || [
              dayjs(wowRange?.start, 'YYYY-MM-DD'),
              dayjs(wowRange?.end, 'YYYY-MM-DD')
            ]}
            disabledDate={(current) => {
              if (!wowDates) {
                return false;
              }
              const tooLate = wowDates[0] && dayjs(current).diff(dayjs(wowDates[0]), 'days') >= 7;
              const tooEarly = wowDates[1] && dayjs(wowDates[1]).diff(dayjs(current), 'days') >= 7;
              return !!tooEarly || !!tooLate;
            }}
            onOpenChange={(open) => {
              if (open) {
                setWowDates([null, null]);
              } else {
                setWowDates(null);
              }
            }}
            onCalendarChange={(val) => {
              setWowDates(val);
            }}
            onChange={(date, dateString) => {
              setWowRange({
                start: dateString[0],
                end: dateString[1]
              });
            }}
            changeOnBlur
          />
        )}
        {selectedTab === '1' && (
          <DatePicker
            value={dayjs(momRange?.start)}
            onChange={(date) => {
              setMomRange({
                start: date.startOf('month').format('YYYY-MM-DD'),
                end: date.endOf('month').format('YYYY-MM-DD'),
              });
            }}
            picker="month"
          />
        )}
        {selectedTab === '2' && (
          <DatePicker
            value={dayjs(yearRange?.start)}
            onChange={(date) => {
              setYearRange({
                start: date.startOf('year').format('YYYY-MM-DD'),
                end: date.endOf('year').format('YYYY-MM-DD'),
              });
            }}
            picker="year"
          />
        )}
        <Button
          type="primary"
          loading={loading}
          icon={<SyncOutlined spin={loading} />}
          onClick={() => getData()}
        >
          {t('reload')}
        </Button>
      </div>
      <div>
        <Tabs
          activeKey={selectedTab}
          onChange={(key) => {
            if (!loading) {
              setSelectedTab(key);
            }
          }}
        >
          <Tabs.TabPane tab={t('week_over_week')} key="0" />
          <Tabs.TabPane tab={t('month_over_month')} key="1" />
          <Tabs.TabPane tab={t('year_over_year')} key="2" />
        </Tabs>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">{t('sales')}</h3>
          <Typography.Text type="secondary">
            {selectedTab === '0' && t('trends_compare_content')}
            {selectedTab === '1' && t('trends_compare_mom')}
            {selectedTab === '2' && t('trends_compare_year')}
            {' ('}{datesStringComparision(currentDataSet?.rollingCurrentMonthStartDate,
              currentDataSet?.rollingCurrentMonthEndDate)}{')'} vs {' ('}{datesStringComparision(currentDataSet?.rollingLastMonthStartDate,
                currentDataSet?.rollingLastMonthEndDate)}{')'}
          </Typography.Text>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
            {trendsCard(t('revenue'), currentDataSet?.rollingCurrentMonthSales, currentDataSet?.rollingLastMonthSales, true)}
            {trendsCard(t('orders'), currentDataSet?.rollingCurrentMonthCount, currentDataSet?.rollingLastMonthCount, false)}
            {trendsCard(t('customers'), currentDataSet?.rollingCurrentMonthCustomerCount, currentDataSet?.rollingLastMonthCustomerCount, false)}
          </dl>
        </div>
        <Divider />
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">{t('devices')}</h3>
          <Typography.Text type="secondary">
            {selectedTab === '0' && t('trends_compare_devices')}
            {selectedTab === '1' && t('trends_compare_devices_mom')}
            {selectedTab === '2' && t('trends_compare_devices_year_over_year')}
            {' ('}{datesStringComparision(currentDataSet?.rollingCurrentMonthStartDate,
              currentDataSet?.rollingCurrentMonthEndDate)}{')'} vs {' ('}{datesStringComparision(currentDataSet?.rollingLastMonthStartDate,
                currentDataSet?.rollingLastMonthEndDate)}{')'}
          </Typography.Text>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
            {trendsCard(t('web_orders'), currentDataSet?.currentOrderDeviceAnalytics?.webOrdersCount, currentDataSet?.lastOrderDeviceAnalytics?.webOrdersCount, false)}
            {trendsCard(t('web_average'), currentDataSet?.currentOrderDeviceAnalytics?.webOrderAverage, currentDataSet?.lastOrderDeviceAnalytics?.webOrderAverage, true)}

            {trendsCard(t('mobile_orders'), currentDataSet?.currentOrderDeviceAnalytics?.mobileOrdersCount, currentDataSet?.lastOrderDeviceAnalytics?.mobileOrdersCount, false)}
            {trendsCard(t('mobile_average'), currentDataSet?.currentOrderDeviceAnalytics?.mobileOrderAverage, currentDataSet?.lastOrderDeviceAnalytics?.mobileOrderAverage, true)}

            {trendsCard(t('in_store_orders'), currentDataSet?.currentOrderDeviceAnalytics?.inStoreOrdersCount, currentDataSet?.lastOrderDeviceAnalytics?.inStoreOrdersCount, false)}
            {trendsCard(t('in_store_average'), currentDataSet?.currentOrderDeviceAnalytics?.inStoreOrderAverage, currentDataSet?.lastOrderDeviceAnalytics?.inStoreOrderAverage, true)}

            {trendsCard(t('other_orders'), currentDataSet?.currentOrderDeviceAnalytics?.unknownDeviceOrdersCount, currentDataSet?.lastOrderDeviceAnalytics?.unknownDeviceOrdersCount, false)}
            {trendsCard(t('other_average'), currentDataSet?.currentOrderDeviceAnalytics?.unknownDeviceOrderAverage, currentDataSet?.lastOrderDeviceAnalytics?.unknownDeviceOrderAverage, true)}
          </dl>
        </div>
        <Divider />
        <div style={{ align: 'center' }} className="mb-12">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{t('payments')}</h3>
            <Typography.Text type="secondary">
              {selectedTab === '0' && t('trends_payment_wow_content')}
              {selectedTab === '1' && t('trends_payment_mom_content')}
              {selectedTab === '2' && t('trends_payment_yoy_content')}
              {' ('}{datesStringComparision(currentDataSet?.rollingCurrentMonthStartDate,
                currentDataSet?.rollingCurrentMonthEndDate)}{')'} vs  {' ('}{datesStringComparision(currentDataSet?.rollingLastMonthStartDate,
                  currentDataSet?.rollingLastMonthEndDate)}{')'}
            </Typography.Text>
            <Spin spinning={loading}>
              <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
                {currentDataSet && _.map(_.orderBy(currentDataSet?.payments, ['currentCount'], ['desc']), (item) => (
                  <div key={item.type} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                    <dd className="mt-1 flex items-baseline md:block lg:flex">
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        {item.name}
                      </dt>
                      <div className="bg-blue-100 px-2.5 py-0.5 rounded-full self-center mt-1 ml-2 w-12 mr-0.5 flex-shrink-0">
                        <dt className="font-normal text-xs ml-1 h-5 w-5 text-blue-500">
                          {item.adoptionRate.toFixed(2)}%
                        </dt>
                      </div>
                    </dd>
                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                      <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                        {thousand(item?.currentCount)}
                        <span className="ml-2 text-sm font-medium text-gray-500">
                          from {thousand(item?.previousCount)}
                        </span>
                      </div>
                      <div
                        className={classNames(
                          item?.growth === 0 ? 'txt-black' : (item?.growth > 0 ? 'bg-green-100 text-green-800' : 'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                          )
                        )}
                      >
                        {item?.growth === 0 ? (
                          <MinusOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                            aria-hidden="true"
                          />
                        ) : (item?.growth > 0 ?
                          (
                            <ArrowUpOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center
                              h-5 w-5 text-green-500"
                              aria-hidden="true"
                            />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center
                              h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                        <span className="sr-only">{item?.currentCount === item?.previousCount ? t('no_growth') : (item?.currentCount > item?.previousCount ?
                          t('increased_by') : t('decreased_by'))}
                        </span>
                        {item?.growth}%
                      </div>
                    </dd>
                  </div>
                ))}
              </dl>
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trends;
