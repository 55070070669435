import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import {
  Tag,
  Badge,
  Button,
  Table,
  DatePicker,
  Switch
} from 'antd';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-layout';
import {
  CheckCircleOutlined,
  CloudDownloadOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { businessAtom, timezoneAtom, profileAtom } from '../../../atoms/Atoms';
import { mapOrderType, mapPaymentType, toCurrency } from '../../utils/functions';
import api from '../../../api/api';
import envConfig from '../../../envConfig';

function PosSalesFinance() {
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const { saveAsCsv } = useJsonToCsv();
  const business = useRecoilValue(businessAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isStoreManager =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('StoreAdmin') ||
        profile?.contents?.roles?.includes('Owner')));
  const canViewFinance =
    isAdmin ||
    isStoreManager ||
    (profile.contents &&
      (profile.contents.roles.includes('Finance') ||
        profile.contents.roles.includes('TopAnalytics')));
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [includeOrders, setIncludeOrders] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD')
  });
  const width = window.innerWidth;

  const allColumns = [{
    title: t('station'),
    key: 'lane',
    align: 'left',
    className: 'text-xs',
    render: (text) => <span className={text.isSummary ? 'font-bold' : ''}>{text.lane}</span>,
  },
  {
    title: t('orders'),
    key: 'count',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span className={text.isSummary ? 'font-bold' : ''}>{text.count}</span>,
  },
  {
    title: t('total'),
    key: 'orderTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span className={text.isSummary ? 'font-bold' : ''}>
        {toCurrency(text.orderTotal)}
      </span>
    ),
  },
  {
    title: t('products'),
    key: 'productsTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span className={text.isSummary ? 'font-bold' : ''}>
        {toCurrency(text.productsTotal)}
      </span>
    ),
  },
  {
    title: t('municipal_tax'),
    key: 'municipalTax',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span className={text.isSummary ? 'font-bold' : ''}>
        {toCurrency(text.municipalTax)}
      </span>
    ),
  },
  {
    title: t('state_tax'),
    key: 'stateTax',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span className={text.isSummary ? 'font-bold' : ''}>
        {toCurrency(text.stateTax)}
      </span>
    ),
  },
  {
    title: t('tax_total'),
    key: 'taxTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span className={text.isSummary ? 'font-bold' : ''}>
        {toCurrency(text.taxTotal)}
      </span>
    ),
  },
  {
    title: t('discount'),
    key: 'discountTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span className={text.isSummary ? 'font-bold' : ''}>
        {toCurrency(text.discountTotal)}
      </span>
    ),
  },
  {
    title: t('return'),
    key: 'returnTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span className={text.isSummary ? 'font-bold' : ''}>
        {toCurrency(text.returnTotal)}
      </span>
    ),
  },
  // {
  //   title: 'ACH Fee',
  //   dataIndex: 'achFee',
  //   key: 'achFee',
  //   align: 'right',
  //   className: 'text-xs',
  //   render: (text) => (
  //     <span>
  //       $
  //       {text
  //         .toFixed(2)
  //         .toString()
  //         .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
  //     </span>
  //   ),
  // },
  // {
  //   title: t('commission'),
  //   dataIndex: 'salesCommision',
  //   key: 'salesCommision',
  //   align: 'right',
  //   className: 'text-xs',
  //   render: (text) => (
  //     <span>
  //       $
  //       {text
  //         .toFixed(2)
  //         .toString()
  //         .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
  //     </span>
  //   ),
  // }
  ];
  const restrictedColumns = [{
    title: t('pos_lane'),
    key: 'lane',
    align: 'left',
    className: 'text-xs',
    render: (text) => <span className={text.isSummary ? 'font-bold' : ''}>{text.lane}</span>,
  },
  {
    title: t('count'),
    key: 'count',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span className={text.isSummary ? 'font-bold' : ''}>{text.count}</span>,
  },
  {
    title: t('total'),
    key: 'orderTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span className={text.isSummary ? 'font-bold' : ''}>
        {toCurrency(text.orderTotal)}
      </span>
    ),
  }];

  const allExpandableColumns = [{
    title: t('payment_tender'),
    key: 'paymentTender',
    dataIndex: 'paymentTender',
    align: 'left',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('orders'),
    dataIndex: 'orderCount',
    key: 'orderCount',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('total'),
    dataIndex: 'orderTotal',
    key: 'orderTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        {toCurrency(text)}
      </span>
    ),
  },
  {
    title: t('products'),
    dataIndex: 'productsTotal',
    key: 'productsTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        {toCurrency(text)}
      </span>
    ),
  },
  {
    title: t('municipal_tax'),
    dataIndex: 'municipalTax',
    key: 'municipalTax',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        {toCurrency(text)}
      </span>
    ),
  },
  {
    title: t('state_tax'),
    dataIndex: 'stateTax',
    key: 'stateTax',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        {toCurrency(text)}
      </span>
    ),
  },
  {
    title: t('tax_total'),
    dataIndex: 'taxTotal',
    key: 'taxTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        {toCurrency(text)}
      </span>
    ),
  },
  {
    title: t('discount'),
    dataIndex: 'discountTotal',
    key: 'discountTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        {toCurrency(text)}
      </span>
    ),
  },
  {
    title: t('return'),
    dataIndex: 'returnTotal',
    key: 'returnTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        {toCurrency(text)}
      </span>
    ),
  },
  // {
  //   title: 'ACH Fee',
  //   dataIndex: 'achFee',
  //   key: 'achFee',
  //   align: 'right',
  //   className: 'text-xs',
  //   render: (text) => (
  //     <span>
  //       $
  //       {text
  //         .toFixed(2)
  //         .toString()
  //         .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
  //     </span>
  //   ),
  // },
  // {
  //   title: t('commission'),
  //   dataIndex: 'salesCommision',
  //   key: 'salesCommision',
  //   align: 'right',
  //   className: 'text-xs',
  //   render: (text) => (
  //     <span>
  //       $
  //       {text
  //         .toFixed(2)
  //         .toString()
  //         .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
  //     </span>
  //   ),
  // }
  ];
  const restrictedExpandableColumns = [{
    title: t('payment_tender'),
    key: 'paymentTender',
    dataIndex: 'paymentTender',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('count'),
    dataIndex: 'count',
    key: 'count',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('total'),
    dataIndex: 'orderTotal',
    key: 'orderTotal',
    align: 'right',
    className: 'text-xs',
    render: (text) => (
      <span>
        {toCurrency(text)}
      </span>
    ),
  }];

  const allListFields = {
    lane: t('pos_lane'),
    count: t('count'),
    orderTotal: t('total'),
    municipalTax: t('municipal_tax'),
    stateTax: t('state_tax'),
    taxTotal: t('tax_total'),
    discountTotal: t('discount'),
    returnTotal: t('return'),
  };
  const restrictedListFields = {
    lane: t('pos_lane'),
    count: t('count'),
    orderTotal: t('total'),
  };

  const allTransactionFields = {
    lane: t('pos_lane'),
    paymentTender: t('payment_tender'),
    count: t('count'),
    orderTotal: t('total'),
    municipalTax: t('municipal_tax'),
    stateTax: t('state_tax'),
    taxTotal: t('tax_total'),
    discountTotal: t('discount'),
    returnTotal: t('return'),
  };
  const restrictedTransactionFields = {
    lane: t('pos_lane'),
    paymentTender: t('payment_tender'),
    count: t('count'),
    orderTotal: t('total'),
  };

  function mapData(data) {
    const map = _.map(data, (p, index) => ({
      key: index,
      isSummary: p.isSummary,
      businessName: p.businessName,
      posLaneTag: p.posLaneTag,
      lane: business ? p.posLaneTag : (p.isSummary ? p.businessName : `${p.businessName} @ ${p.posLaneTag}`),
      count: p.orderCount,
      date: moment(p.date).format('MMM Do YYYY'),
      orderTotal: p.orderTotal,
      productsTotal: p.productsTotal,
      stateTax: p.stateTax,
      achFee: p.achFee,
      salesCommision: p.salesCommision,
      municipalTax: p.municipalTax,
      taxTotal: p.taxTotal,
      deliveryFee: p.deliveryFee,
      pickupFee: p.pickupFee,
      processorFee: p.processorFee,
      processingFee: p.processingFee,
      storeTotal: p.storeTotal,
      transferTotal: p.transferTotal,
      discountTotal: p.discountTotal,
      returnTotal: p.returnTotal,
      paymentType: p.paymentType,
      authTotal: p.authTotal,
      athMovilDeposit: p.authTotal - p.processorFee,
      dateFormatted: moment(p.date).format('YYYY-MM-DD'),
      referenceOrder: p.referenceOrder,
      paymentTenders: _.map(p.paymentTenders, (v) => ({
        key: v.id,
        ...v,
      })),
    }));
    return map;
  }

  function getData() {
    setLoading(true);
    const data = {
      StartDate: dayjs(dateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(dateRange.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      BusinessId: business?.id,
      IncludeOrderSummary: includeOrders
    };
    api
      .post(
        'analytics/reporting/sales/bypos/search',
        data
      )
      .then((response) => {
        setLoading(false);
        const summary = {
          isSummary: true,
          businessName: t('total'),
          posLaneTag: '',
          orderCount: 0,
          orderTotal: 0,
          productsTotal: 0,
          stateTax: 0,
          municipalTax: 0,
          taxTotal: 0,
          achFee: 0,
          processorFee: 0,
          processingFee: 0,
          deliveryFee: 0,
          pickupFee: 0,
          storeTotal: 0,
          transferTotal: 0,
          discountTotal: 0,
          returnTotal: 0,
          salesCommision: 0,
          paymentTenders: []
        };
        for (let index = 0; index < response.data.data.length; index++) {
          const temp = response.data.data[index];
          summary.orderCount += temp.orderCount;
          summary.orderTotal += temp.orderTotal;
          summary.productsTotal += temp.productsTotal;
          summary.stateTax += temp.stateTax;
          summary.municipalTax += temp.municipalTax;
          summary.taxTotal += temp.taxTotal;
          summary.achFee += temp.achFee;
          summary.processorFee += temp.processorFee;
          summary.processingFee += temp.processingFee;
          summary.deliveryFee += temp.deliveryFee;
          summary.pickupFee += temp.pickupFee;
          summary.storeTotal += temp.storeTotal;
          summary.transferTotal += temp.transferTotal;
          summary.discountTotal += temp.discountTotal;
          summary.returnTotal += temp.returnTotal;
          summary.salesCommision += temp.salesCommision;
        }
        setReportData(mapData([...response.data.data, summary]));
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useMount(() => {
    getData();
  });

  useEffect(() => {
    getData();
  }, [dateRange, business, includeOrders]);

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        title={t('sales_by_station')}
        tags={
          <Tag
            color="#2db7f5"
            icon={<CheckCircleOutlined />}
          >
            {business ? business.name : t('global')}
          </Tag>
        }
        extra={
          [
            <RangePicker
              format="YYYY-MM-DD"
              value={[
                dayjs(dateRange?.start),
                dayjs(dateRange?.end)
              ]}
              presets={[
                {
                  label: t('today'),
                  value: () => [moment(), moment()]
                },
                {
                  label: t('yesterday'),
                  value: () => [moment().add(-1, 'day'), moment().add(-1, 'day')]
                },
                {
                  label: t('current_week'),
                  value: () => [moment().startOf('week'), moment().endOf('week')]
                },
                {
                  label: t('last_week'),
                  value: () => [moment().add(-1, 'week').startOf('week'), moment().add(-1, 'week').endOf('week')]
                },
                {
                  label: t('current_month'),
                  value: () => [moment().startOf('month'), moment().endOf('month')]
                },
                {
                  label: t('past_month'),
                  value: () => [moment().add(-1, 'months').startOf('month'), moment().add(-1, 'months').endOf('month')]
                },
                {
                  label: t('year_to_date'),
                  value: () => [moment().startOf('year'), moment()]
                },
              ]}
              onChange={(date, dateString) => {
                setDateRange({
                  start: dateString[0],
                  end: dateString[1]
                });
              }}
            />,
            <div
              className="flex space-x-1 border p-1"
            >
              <br className={width < 1024 ? null : 'hidden'} />
              <p>{t('include_payment_tenders')}</p>
              <Switch
                disabled={loading}
                className="float-right"
                checked={includeOrders}
                onChange={(checked) => {
                  setIncludeOrders(checked);
                }}
              />
            </div>,
            <div className="space-x-2">
              <br className={width < 1024 ? null : 'hidden'} />
              <Button
                type="primary"
                size="small"
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  const data = _.map(reportData, (p) => ({
                    businessName: p.businessName,
                    posLaneTag: p.posLaneTag,
                    lane: business ? p.posLaneTag : `${p.businessName} @ ${p.posLaneTag}`,
                    count: p.count,
                    orderTotal: p.orderTotal,
                    // productsTotal: p.productsTotal,
                    municipalTax: p.municipalTax,
                    stateTax: p.stateTax,
                    taxTotal: p.taxTotal,
                    processorFee: p.processorFee,
                    deliveryFee: p.deliveryFee,
                    pickupFee: p.pickupFee,
                    discountTotal: p.discountTotal,
                    returnTotal: p.returnTotal,
                    transferTotal: p.transferTotal,
                  }));
                  saveAsCsv({
                    data,
                    fields: canViewFinance ? allListFields : restrictedListFields,
                    filename: `POS_Sales_${dateRange.start}_${dateRange.end}`,
                  });
                }}
              >
                {t('export_list')}
              </Button>
              <Button
                type="primary"
                size="small"
                icon={<ReloadOutlined />}
                onClick={() => getData()}
              >
                {t('refresh')}
              </Button>
            </div>,
            includeOrders && (
              <Button
                type="primary"
                size="small"
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  let temp = [];
                  reportData.forEach((e) => {
                    temp = temp.concat(e.paymentTenders);
                  });
                  const data = _.map(temp, (p) => ({
                    businessName: p.businessName,
                    posLaneTag: p.posLaneTag,
                    lane: business ? p.posLaneTag : `${p.businessName} @ ${p.posLaneTag}`,
                    count: p.orderCount,
                    paymentTender: p.paymentTender,
                    orderTotal: p.orderTotal,
                    productsTotal: p.productsTotal,
                    stateTax: p.stateTax,
                    achFee: p.achFee,
                    salesCommision: p.salesCommision,
                    municipalTax: p.municipalTax,
                    taxTotal: p.taxTotal,
                    deliveryFee: p.deliveryFee,
                    pickupFee: p.pickupFee,
                    processorFee: p.processorFee,
                    processingFee: p.processingFee,
                    storeTotal: p.storeTotal,
                    transferTotal: p.transferTotal,
                    discountTotal: p.discountTotal,
                    returnTotal: p.returnTotal,
                    paymentType: p.paymentType,
                    authTotal: p.authTotal,
                    athMovilDeposit: p.authTotal - p.processorFee,
                  }));
                  saveAsCsv({
                    data,
                    fields: canViewFinance ?
                      allTransactionFields : restrictedTransactionFields,
                    filename: `POS_Payment_Type_Sales_${dateRange.start}_${dateRange.end}`,
                  });
                }}
              >
                {t('export_all_payment_tenders')}
              </Button>
            )
          ]
        }
      />
      <Table
        size="small"
        loading={loading}
        bordered
        scroll={{ x: 1000 }}
        pagination={false}
        columns={canViewFinance ? allColumns : restrictedColumns}
        dataSource={reportData}
        expandable={!includeOrders ? null : {
          expandRowByClick: true,
          expandedRowRender: (record) => (
            <div className="p-4">
              <Table
                title={() => (
                  <div className="flex items-center justify-between">
                    <div>
                      <Badge
                        className="site-badge-count-109"
                        count={record.paymentTenders.length}
                        style={{ backgroundColor: '#52c41a' }}
                      />
                      <span className="font-light uppercase text-xs ml-2">
                        {t('payment_types')}
                      </span>
                    </div>
                    <Button
                      type="primary"
                      size="small"
                      icon={<CloudDownloadOutlined />}
                      onClick={() => {
                        const data = _.map(record.paymentTenders, (p) => ({
                          businessName: p.businessName,
                          posLaneTag: p.posLaneTag,
                          lane: business ? p.posLaneTag : `${p.businessName} @ ${p.posLaneTag}`,
                          orderTotal: p.orderTotal,
                          paymentTender: p.paymentTender,
                          count: p.orderCount,
                          productsTotal: p.productsTotal,
                          stateTax: p.stateTax,
                          achFee: p.achFee,
                          salesCommision: p.salesCommision,
                          municipalTax: p.municipalTax,
                          taxTotal: p.taxTotal,
                          deliveryFee: p.deliveryFee,
                          pickupFee: p.pickupFee,
                          processorFee: p.processorFee,
                          processingFee: p.processingFee,
                          storeTotal: p.storeTotal,
                          transferTotal: p.transferTotal,
                          discountTotal: p.discountTotal,
                          returnTotal: p.returnTotal,
                          paymentType: p.paymentType,
                          authTotal: p.authTotal,
                          athMovilDeposit: p.authTotal - p.processorFee,
                        }));
                        saveAsCsv({
                          data,
                          fields: canViewFinance ?
                            allTransactionFields : restrictedTransactionFields,
                          filename: `POS_${record.businessName}_${record.posLaneTag}_Payment_Types_Sales_${dateRange.start}_${dateRange.end}`,
                        });
                      }}
                    >
                      {t('export_payment_tenders')}
                    </Button>
                  </div>
                )}
                bordered
                pagination={false}
                columns={canViewFinance ? allExpandableColumns : restrictedExpandableColumns}
                dataSource={record.paymentTenders}
              />
            </div>
          ),
          rowExpandable: (record) => record.paymentTenders.length > 0,
        }}
      />
    </div>
  );
}

export default PosSalesFinance;
