const envConfig = {
  REACT_APP_API_TOKEN: process.env.REACT_APP_API_TOKEN ?? 'daddcd4202e044ef921b4e4fb43a8de7',
  REACT_APP_API_URL: process.env.REACT_APP_API_URL ?? 'https://antoninos-api-dev.azurewebsites.net/api/',
  REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID ?? 'daddcd4202e044ef921b4e4fb43a8de7',
  REACT_APP_HUB_URL: process.env.REACT_APP_HUB_URL ?? 'https://antoninos-api-dev.azurewebsites.net/',
  REACT_APP_GEO_API_KEY: process.env.REACT_APP_GEO_API_KEY ?? 'AIzaSyAKJ8Kgii2C4zquZmG0nqJ_lu8lSJ_VyII',
  REACT_APP_PREFIX: process.env.REACT_APP_PREFIX ?? 'grocefy-ant',

  REACT_APP_SERVER_CONFIG: process.env.REACT_APP_SERVER_CONFIG ?? 'Endpoint=https://antoninos-api-dev.azconfig.io;Id=GjJH-l0-s0:JpTiJBa8wP453v3benQ0;Secret=iPMKmsh61J8FDqR8/Ge82PuAowzulL6Dfr1Bg7T/Prc=',
  REACT_APP_APP_CONFIG: process.env.REACT_APP_APP_CONFIG ?? 'Endpoint=https://antoninos-app-dev.azconfig.io;Id=rTTT-l0-s0:7kmQALGquKPv0y1Qua0x;Secret=RH2H+CW5e7LXXEiFTD2VokKLXkwBZPh9gLq2iiVuF1c=',
  REACT_APP_MANAGER_CONFIG: process.env.REACT_APP_MANAGER_CONFIG ?? 'Endpoint=https://ant-manager-config.azconfig.io;Id=rGfr;Secret=nqqI4Ly1o/NdmfeBFiCZD43UyHxes67xgiqAewrqjBA=',

  REACT_APP_IMAGES_URL: process.env.REACT_APP_IMAGES_URL ?? '#{REACT_APP_IMAGES_URL}#',
  REACT_APP_IMAGES_KEY: process.env.REACT_APP_IMAGES_KEY ?? '#{REACT_APP_IMAGES_KEY}#',
  REACT_APP_UTILITY_KEY: process.env.REACT_APP_UTILITY_KEY ?? 'utility-b60bbed139054e2289dfa5d29e4c63aab495f236d3af48168378c41dc671d643',
  REACT_APP_PAYMENTS_KEY: process.env.REACT_APP_PAYMENTS_KEY ?? '9f09ee1311b041afad4a09b53bce41022e952f1576be4933b63a3af9e1860745bf6642961a6e45aba8f05363ca875473c6a4b854e1ab40e19c79b033b3f5a17e',
  REACT_APP_BOOKING_API_URL: process.env.REACT_APP_BOOKING_API_URL ?? '#{REACT_APP_BOOKING_API_URL}#',
  REACT_APP_BOOKING_API_KEY: process.env.REACT_APP_BOOKING_API_KEY ?? '#{REACT_APP_BOOKING_API_KEY}#',
  REACT_APP_TIMEZONE_OFFSET: process.env.REACT_APP_TIMEZONE_OFFSET ?? '-4',
  REACT_APP_BOOKING_CANCELLATION_FEE: process.env.REACT_APP_BOOKING_CANCELLATION_FEE ?? '0',
  BookingEventUrl: process.env.REACT_APP_BOOKING_API_EVENT_URL ?? '#{REACT_APP_BOOKING_API_EVENT_URL}#',
  SpecificProductUrl: process.env.REACT_APP_SPECIFIC_PRODUCT_URL ?? '#{REACT_APP_SPECIFIC_PRODUCT_URL}#',
  WebsiteUrl: process.env.REACT_APP_WEBSITE_URL ?? '#{REACT_APP_WEBSITE_URL}#'

};

export default envConfig;
