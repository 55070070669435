import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useMount } from 'react-use';
import moment from 'moment';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Text from 'antd/lib/typography/Text';
import {
  Avatar,
  Tooltip,
  notification,
  Spin,
  Typography,
  Row,
  Col
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { Line, Bar } from 'react-chartjs-2';
import { businessAtom, salesInsightsDateRangeAtom } from '../../../../../atoms/Atoms';
import api from '../../../../../api/api';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';
import { thousand } from '../../../../utils/functions';

function Sales() {
  const { t } = useTranslation();
  const [salesInsightsDateRange, setSalesInsightsDateRange]
    = useRecoilState(salesInsightsDateRangeAtom);
  const [loading, setLoading] = useState(false);
  const [sales, setSales] = useState([]);
  const [ordersByDay, setOrdersByDay] = useState(null);
  const [reload, setReload] = useState(false);
  const [lifetimeSales, setLifetimeSales] = useState(null);
  const store = useRecoilValue(businessAtom);
  const [showDataLabels, setShowDatalabels] = useState(false);
  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  useMount(() => {
    setSalesInsightsDateRange({
      ...salesInsightsDateRange,
      lifetime: false
    });
  });

  function mapData(data) {
    const mapped = _.map(data, (p) => ({
      x: p.day,
      y: p.orderSales,
    }));
    return _.sortBy(mapped, (s) => s.x, 'asc');
  }

  function getData() {
    if (salesInsightsDateRange != null) {
      setLoading(true);
      api.post(`analytics/insights/sales/v2/${store.id}`, salesInsightsDateRange)
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            const tempByDay = mapData(response.data.data.ordersByDay);
            setOrdersByDay(tempByDay);
            setSales(response.data.data.sales[0]);
          } else {
            showMessage(response.data.message);
          }
        }).catch((err) => {
          setLoading(false);
          showMessage(err.message);
        });
    }
  }

  useEffect(() => {
    const temp = moment();
    const startDate = moment(`${temp.format('YYYY')}-${temp.format('M')}-1`, 'YYYY/MM/DD');
    const endDate = moment(`${temp.format('YYYY')}-${temp.format('M')}-1`, 'YYYY/MM/DD').add(1, 'months');
    setSalesInsightsDateRange({
      lifetime: false,
      startDate,
      endDate
    });
  }, []);

  useEffect(() => {
    if (salesInsightsDateRange) {
      getData();
    }
  }, [salesInsightsDateRange]);

  useEffect(() => {
    getData();
  }, [reload, store]);

  const labels = _.map(ordersByDay, (s) => s.x);

  const graphData = {
    labels,
    datasets: [
      {
        label: t('daily_total'),
        data: _.map(ordersByDay, (s) => s.y),
        backgroundColor: 'rgba(53, 162, 235, 0.2)',
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      }
    },
    responsive: true,
    plugins: {
      datalabels: {
        anchor: 'end',
        color: 'white',
        backgroundColor: '#a78bfa',
        borderRadius: '5',
        labels: {
          value: {}
        }
      },
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Daily Sales',
      },
    },
  };

  return (
    <div>
      <br />
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900 mt-2">{t('sales')}</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('orders')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{sales?.orderCount}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('sales')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${`${thousand(sales?.orderSales)}`}</dd>
            </Spin>
          </div>
          <div className={sales && sales.orderCount > 0 ? 'px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6' : 'hidden'}>
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('average_ticket')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${sales && (sales?.orderSales / sales?.orderCount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
            </Spin>
          </div>
          <div className={sales && ordersByDay && ordersByDay.length > 0 && sales?.orderCount > 0 ? 'px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6' : 'hidden'}>
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('daily_orders')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{sales && (sales?.orderCount / ordersByDay?.length).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
            </Spin>
          </div>
        </dl>
      </div>
      <br />
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900 mt-2">{t('customers')}</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('customers')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{sales?.totalCustomers}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('first_time_customers')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{sales?.firstTimeCustomers}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('recurring_customers')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{sales?.recurringCustomers}</dd>
            </Spin>
          </div>
        </dl>
      </div>
      <br />
      <div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Line
              height={250}
              data={graphData}
              plugins={!showDataLabels && ChartDataLabels}
              options={options}
            />
          </Col>
        </Row>
      </div>
      <br />
    </div>
  );
}

export default Sales;
