import React from 'react';
import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Table
} from 'antd';
import {
  setSelectedMasterItemAtom,
  showItemGtinsAtom,
} from '../../../../atoms/Atoms';

function ItemGtinsDrawer() {
  const selectedMasterItem = useRecoilValue(setSelectedMasterItemAtom);
  const [showItemGtins, setShowItemGtins] = useRecoilState(showItemGtinsAtom);
  const { t } = useTranslation();

  return (
    <Drawer
      title="Gtins"
      placement="right"
      closable
      onClose={() => setShowItemGtins(null)}
      open={showItemGtins}
      width={400}
    >
      <>
        {selectedMasterItem && (
          <Table
            size="small"
            bordered
            pagination={false}
            dataSource={selectedMasterItem.gtins}
            columns={[
              {
                title: 'GTIN',
                align: 'center',
                className: 'text-xs',
                render: (text) => (
                  <span>{text}</span>
                ),
              }
            ]}
          />
        )}
      </>
    </Drawer>
  );
}
export default ItemGtinsDrawer;
