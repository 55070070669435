import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Input,
  Table,
  Image,
  Select
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { useMount } from 'react-use';
import { FilterOutlined, LinkOutlined } from '@ant-design/icons';
import {
  editBookingEventAtom,
  selectedBookingEventAtom,
  reloadBookingEventAtom,
  selectedEventForTimeSlotsAtom
} from '../../../../atoms/Atoms';
import EditEvents from './shared-components/EditEvents';
import bookingApi from '../../../../api/bookingApi';
import {
  eventTypes,
  getEventTypeName,
  getEventSubscriberVisibilityName
} from '../../../utils/functions';
import EditEventTimeSlots from './shared-components/EditEventTimeSlots';
import ManageEventBooking from './shared-components/ManageEventBooking';
import envConfig from '../../../../envConfig';

function Events() {
  const { t } = useTranslation();
  const setSelectedEvent = useSetRecoilState(selectedBookingEventAtom);
  const setEditEvent = useSetRecoilState(editBookingEventAtom);
  const setSelectedEventForTimeSlots = useSetRecoilState(selectedEventForTimeSlotsAtom);
  const [reload, setReload] = useRecoilState(reloadBookingEventAtom);
  const [loading, setLoading] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState(1);
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const width = window.innerWidth;
  const { Option } = Select;
  const isActiveFilters = [
    { name: t('all'), type: 0 },
    { name: t('active'), type: 1 },
    { name: t('inactive'), type: 2 }
  ];

  const handleEventTypeSelect = (category) => {
    setSelectedEventType(category);
  };

  useEffect(() => {
    if (events) {
      let tempEvents = [...allEvents];
      if (selectedEventType !== '') {
        tempEvents = _.filter(tempEvents, (o) => o.type === selectedEventType);
      }
      if (isActiveFilter === 1) {
        tempEvents = _.filter(tempEvents, (o) => o.isActive);
      }
      if (isActiveFilter === 2) {
        tempEvents = _.filter(tempEvents, (o) => !o.isActive);
      }
      if (searchQuery.trim().length > 0) {
        tempEvents = _.filter(tempEvents,
          (o) => `${o.name} ${o.description} ${o.location.name} ${o.location.description} ${o.host.firstName} ${o.host.lastName} ${o.host.email}`.toLocaleLowerCase().includes(searchQuery.trim().toLocaleLowerCase()));
      }
      setEvents(tempEvents);
    }
  }, [selectedEventType, searchQuery, isActiveFilter, allEvents]);

  function getEvents() {
    setLoading(true);
    bookingApi
      .get('events/all')
      .then((response) => {
        setLoading(false);
        setEvents(response.data.data);
        setAllEvents(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  useMount(() => {
    getEvents();
  });

  useEffect(() => {
    if (reload) {
      getEvents();
      setReload(false);
    }
  }, [reload]);

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('Events')}
        extra={[
          <Button
            id="reload"
            type="primary"
            key="reload"
            size="small"
            onClick={() => setReload(true)}
          >
            {t('reload')}
          </Button>,
          <Button
            type="primary"
            key="create"
            size="small"
            onClick={() => {
              setSelectedEvent({});
              setEditEvent(true);
            }}
          >
            {t('create_new')}
          </Button>
        ]}
      />
      <div className="flex my-4 flex space-between">
        <div className="mr-2">
          <FilterOutlined className="mr-2" />
          <strong className="text-xs">{t('type')}</strong>
          <br />
          <Select
            style={{ width: '140px' }}
            value={selectedEventType}
            onChange={(value) => handleEventTypeSelect(value)}
          >
            <Option
              key=""
              value=""
              className="text-xs flex items-center"
            >
              {t('any')}
            </Option>
            {_.map(eventTypes, (eventType) => (
              <Option
                key={eventType.type}
                value={eventType.type}
                className="text-xs flex items-center"
              >
                {t(eventType.name)}
              </Option>
            ))}
          </Select>
        </div>
        <div className="mr-2">
          <FilterOutlined className="mr-2" />
          <strong className="text-xs">{t('is_active')}</strong>
          <br />
          <Select
            style={{ width: '140px' }}
            value={isActiveFilter}
            onChange={(value) => setIsActiveFilter(value)}
          >
            {_.map(isActiveFilters, (filter) => (
              <Option
                key={filter.type}
                value={filter.type}
                className="text-xs flex items-center"
              >
                {t(filter.name)}
              </Option>
            ))}
          </Select>
        </div>
        <div className="mr-2">
          <FilterOutlined className="mr-2" />
          <strong className="text-xs">{t('search')}</strong>
          <br />
          <Input
            className="w-full"
            value={searchQuery}
            onChange={(obj) => setSearchQuery(obj.target.value)}
          />
        </div>
      </div>
      <Table
        pagination={false}
        size="small"
        bordered
        rowKey="id"
        scroll={{ x: 500 }}
        loading={loading}
        columns={[
          {
            title: t('image'),
            key: 'image',
            align: 'center',
            dataIndex: 'imageUrl',
            className: 'text-xs hidden',
            render: (row) => (
              <>
                {row && (
                  <Image
                    alt="logo"
                    width={60}
                    src={row}
                    className="cursor-pointer"
                  />
                )}
              </>
            ),
          },
          {
            title: t('internal_name'),
            key: 'name',
            align: 'left',
            dataIndex: 'name',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
          },
          // {
          //   title: t('description'),
          //   key: 'description',
          //   align: 'center',
          //   dataIndex: 'description',
          //   className: 'text-sm',
          //   width: '10%',
          //   render: (row) => (
          //     <span>{row}</span>
          //   ),
          // },
          {
            title: t('location'),
            key: 'location',
            align: 'center',
            dataIndex: 'location',
            // sorter: (a, b) => a.name - b.name,
            className: 'text-xs',
            render: (row) => (
              <span>{row.name}</span>
            ),
          },
          {
            title: t('host'),
            key: 'host',
            align: 'left',
            dataIndex: 'host',
            className: 'text-xs',
            render: (row) => (
              <span>{`${row.firstName} ${row.lastName}`}</span>
            ),
          },
          {
            title: t('type'),
            key: 'type',
            align: 'center',
            dataIndex: 'type',
            className: 'text-xs',
            render: (row) => (
              <span>{t(getEventTypeName(row))}</span>
            ),
          },
          {
            title: t('subscriber_visibility'),
            key: 'subscriberVisibility',
            align: 'center',
            dataIndex: 'subscriberVisibility',
            className: 'text-xs hidden',
            render: (row) => (
              <span>{t(getEventSubscriberVisibilityName(row))}</span>
            ),
          },
          {
            title: t('price'),
            key: 'price',
            align: 'center',
            dataIndex: 'price',
            className: 'text-xs',
            render: (row) => (
              <span>${row.toFixed(2)}</span>
            ),
          },
          {
            title: 'DL',
            key: '',
            align: 'center',
            className: 'text-xs',
            render: (text, record) => (
              <>
                <Button
                  size="small"
                  type="primary"
                  disabled={record.type !== 2}
                  onClick={() => {
                    // console.log(record);
                    navigator.clipboard.writeText(`${envConfig.BookingEventUrl}/${record.id}`);
                  }}
                >
                  <LinkOutlined />
                </Button>
              </>
            ),
          },
          {
            title: '',
            key: 'edit',
            align: 'center',
            className: 'text-xs',
            render: (text, record) => (
              <>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setSelectedEvent(record);
                    setEditEvent(true);
                  }}
                >
                  {t('edit')}
                </Button>
                <br className={width < 1024 ? null : 'hidden'} />
                <Button
                  size="small"
                  type="primary"
                  className="ml-2"
                  onClick={() => {
                    setSelectedEventForTimeSlots(record);
                  }}
                >
                  {t('time_slots')}
                </Button>
              </>
            ),
          },
        ]}
        dataSource={events}
      />
      <EditEvents />
      <EditEventTimeSlots />
      <ManageEventBooking />
    </>
  );
}
export default Events;
