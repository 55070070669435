import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import 'moment-timezone';
import _ from 'lodash';
import {
  Tag,
  Tabs
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  CheckCircleOutlined,
} from '@ant-design/icons';
import { businessAtom, timezoneAtom, profileAtom } from '../../../atoms/Atoms';
import CompletedInStoreSalesFinance from './shared-components/CompletedInStoreSalesFinance';
import PendingInStoreSalesFinance from './shared-components/PendingInStoreSalesFinance';

function InStoreSalesFinance() {
  const { t } = useTranslation();
  const business = useRecoilValue(businessAtom);

  return (
    <div>
      <PageHeader
        className="mb-0 px-0"
        title={t('instore_sales')}
        tags={
          <Tag
            color="#2db7f5"
            icon={<CheckCircleOutlined />}
          >
            {business ? business.name : t('global')}
          </Tag>
        }
      />
      <Tabs className="-mt-4" defaultActiveKey="1">
        <Tabs.TabPane tab={t('completed')} key="1">
          <CompletedInStoreSalesFinance />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('active')} key="2">
          <PendingInStoreSalesFinance />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default InStoreSalesFinance;
